/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import userService from "./userService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/authenticate";

httpService.setJwt(getJWT());


export async function authenticate(login, password) {
  try {
    const promise = await httpService.post(apiEndpoint + "/verify", {
      params: { login: login, password: password },
    });
    const { data: response } = promise;
    localStorage.removeItem("jwtExpired");
    localStorage.setItem("JWT", response.token);
    return userService.getCurrentID()
  } catch (error) {
    return null;
  }
}

export async function changePass(data) {
  try {
    const promise = await httpService.post(apiEndpoint + "/changePass", { params: data});
    const { status: response } = promise;
    return response;
  } catch (error) {
    return error.response.status;
  }
}

export async function checkJWT() {
  try {
    const promise = await httpService.get(apiEndpoint + "/checkJWT/" + userService.getCurrentID());
    const { data: response } = promise;
    if (response.jwtOK)
      return true
    else
      return false
  } catch (error) {
    return false;
  }
}

export function logout() {
  localStorage.removeItem("jwtExpired");
  localStorage.removeItem("JWT");
  window.location.reload();
}

export function getJWT() {
  return localStorage.getItem("JWT");
}

export default {
  authenticate,
  logout,
  getJWT,
  checkJWT,
  changePass
};
