/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/object";

export async function getAll() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAll");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPackage(params) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPackage", {
      params,
    });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getDetails(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getDetails/"+id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function edit(details,clientChange) {
  try {
    const promise = await httpService.post(apiEndpoint + "/edit", { params : {details,clientChange}});
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function create(details,clientChange) {
  try {
    const promise = await httpService.post(apiEndpoint + "/create", { params : {details,clientChange}});
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export default {
  getAll,
  getPackage,
  getDetails,
  edit,
  create
};
