import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react"
import newsService from "../../services/newsService";
import AddNews from "./AddNews";
import { Image } from 'primereact/image';
import { Divider } from 'primereact/divider';
import moment from "moment";
import { ToggleButton } from "primereact/togglebutton";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import EditNews from "./EditNews";
import { ProgressSpinner } from "primereact/progressspinner";
import emptyImg from '../../assets/empty-image.jpg'


export default function NewsPanel(props) {

    const [news, setNews] = useState([]);
    const [creatorVisible, setCreatorVisible] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [editVisible, setEditVisible] = useState(false);
    const [selected, setSelected] = useState({});
    const [loading, setLoading] = useState(true);

    const toast = useRef(null);

    useEffect(() => {
        let fetchData = async () => {
            let response = await newsService.getTotalRecords();
            setTotalRecords(response);
        }
        fetchData();
    }, [])

    useEffect(() => {
        let fetchData = async () => {
            setLoading(true);
            let response = await newsService.getPackage(first);
            setNews(n => [...n, ...response]);
            setLoading(false);
        }
        fetchData();
    }, [first])


    const refreshNews = async () => {
        let newsResponse = await newsService.getPackage(0);
        setNews(newsResponse);
    }

    const toggleVisible = async (visible, akID) => {
        let response = await newsService.toggleVisible(visible, akID);
        if (response) {
            refreshNews();
        } else {
            toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Nie udało się ukryć artykułu.', closable: true });
        }
    }

    const confirmDelete = (event, akID) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Czy napewno chcesz usunąć artykuł?",
            icon: 'pi pi-fw pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Tak',
            accept: () => deleteArticle(akID),
            rejectLabel: 'Nie',
            reject: null
        })
    }

    const deleteArticle = async (akID) => {
        let response = await newsService.deleteArticle(akID);
        if (response) {
            refreshNews();
        } else {
            toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Nie udało się usunąć artykułu.', closable: true });
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div>
                <div className="grid justify-content-center p-5 ml-4 mr-4 mt-4" style={{ backgroundColor: 'white', borderRadius: '15px 15px 0 0', border: '1px solid hsl(350, 68%, 44%)' }}>
                    <Button
                        label="Dodaj"
                        onClick={() => setCreatorVisible(true)}
                        icon="pi pi-fw pi-plus-circle"
                        style={{ width: '150px', height: '80px', borderRadius: '10px', fontSize: '25px' }}
                    />
                </div>
                <div className="grid justify-content-center mb-4 ml-4 mr-4 mt-1 p-4" style={{ backgroundColor: 'white', borderRadius: '0 0 15px 15px', border: '1px solid hsl(350, 68%, 44%)' }}>
                    {
                        loading ?
                            <ProgressSpinner />
                            :
                            <>
                                {news.length > 0 ?
                                    news.map((article, idx) =>
                                        <div key={idx} className="grid col-12 mb-4" style={{ border: '1px solid grey', borderRadius: '15px', backgroundColor: '#FFF4F6' }}>
                                            <div className="" style={{ height: '200px', margin: '5px', width: '200px' }}>
                                                <Image
                                                    src={article.mainImgBase64 || emptyImg}
                                                    alt={article.mainImgTitle}
                                                    width="200px"
                                                />
                                            </div>
                                            <div className="col-7" style={{ borderRight: '1px solid #C8C8C8' }}>
                                                <div className="font-bold text-3xl">
                                                    {article.akNaglowek}
                                                </div>
                                                <Divider />
                                                <div className="vertical-ellipsis" dangerouslySetInnerHTML={{ __html: article.akTresc }} style={{ maxHeight: '110px' }} />
                                            </div>
                                            <div className="grid col-3 flex-column justify-content-between pl-3">
                                                <div>
                                                    <div>
                                                        <label className="font-bold">Autor :</label> {article.uusImie + ' ' + article.uusNazw}
                                                    </div>
                                                    <div>
                                                        <label className="font-bold">Data utworzenia :</label> {moment(article.akDataUt).format('DD/MM/YYYY HH:mm')}
                                                    </div>
                                                    {
                                                        article.musImie ?
                                                            <>
                                                                <div>
                                                                    <label className="font-bold">Modyfikował :</label> {article.musImie + ' ' + article.musNazw}
                                                                </div>
                                                                <div>
                                                                    <label className="font-bold">Data modyfikacji :</label> {moment(article.akDataMod).format('DD/MM/YYYY HH:mm')}
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    <div>
                                                        <label className="font-bold">Widoczny :</label> {article.akWidoczny ? 'Tak' : 'Nie'}
                                                    </div>
                                                    {
                                                        article.akWidocznyOd ? 
                                                        <div>
                                                            <label className="font-bold">Data publikacji :</label> {moment(article.akWidocznyOd).format('DD/MM/YYYY')}
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="grid justify-content-evenly col-12">
                                                    <ToggleButton
                                                        onIcon="pi pi-fw pi-eye"
                                                        offIcon="pi pi-fw pi-eye-slash"
                                                        checked={article.akWidoczny}
                                                        onChange={(e) => toggleVisible(e.target.value, article.akID)}
                                                        onLabel=""
                                                        offLabel=""
                                                        style={{ width: '30%' }}
                                                    />
                                                    <Button
                                                        icon="pi pi-fw pi-pencil"
                                                        onClick={() => {  setSelected(article.akID); setEditVisible(true); }}
                                                        style={{ width: '30%' }}
                                                    />
                                                    <Button
                                                        icon="pi pi-fw pi-trash"
                                                        onClick={(e) => confirmDelete(e, article.akID)}
                                                        style={{ width: '30%' }}
                                                    />
                                                    <ConfirmPopup />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    <div className="col-10 text-center text-2xl m-5">
                                        Brak aktualności do wyświetlenia...
                                    </div>
                                }
                                {
                                    news.length < totalRecords ?
                                        <Button
                                            label="Więcej"
                                            icon="pi pi-fw pi-search-plus"
                                            onClick={() => setFirst(first + 10)}
                                        />
                                        :
                                        null
                                }
                            </>
                    }
                </div>
            </div>

            <Dialog
                visible={creatorVisible}
                onHide={() => setCreatorVisible(false)}
                header="Kreator artykułu"
            >
                <AddNews success={() => {
                    toast.current.show({ severity: 'success', summary: 'Sukces', detail: 'Pomyślnie utworzono artykuł!', closable: true });
                    setCreatorVisible(false);
                    refreshNews();
                }} />
            </Dialog>

            <Dialog
                visible={editVisible}
                onHide={() => setEditVisible(false)}
                header="Edytor artykułu"
            >
                <EditNews
                    article={selected}
                    success={() => {
                        toast.current.show({ severity: 'success', summary: 'Sukces', detail: 'Pomyślnie zapisano zmiany!', closable: true });
                        setEditVisible(false);
                        refreshNews();
                    }}
                />
            </Dialog>
        </>
    )
}