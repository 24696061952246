import { useEffect } from "react";
import authService from "../services/authService";
import userService from "../services/userService";
import News from "./news/News";


export default function MainPage() {

    useEffect(() => {
        if (userService.getCurrentID())
            authService.checkJWT()
    }, []);

    return (
        <div>
            <News />
        </div>
    )
}