/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import moment from "moment";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";

import { Toast } from "primereact/toast";
import userService from "../../services/userService";
import { Button } from "primereact/button";
import dictionaryService from "../../services/dictionaryService";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import vehicleService from "../../services/vehicleService";
import { yearsArray } from "../../common/tools";

export default function VehicleDetails(props) {

    const [details, setDetails] = useState({});
    const [creator, setCreator] = useState();
    const [modifier, setModifier] = useState();
    const [changes, setChanges] = useState(false);
    const [status, setStatus] = useState([]);
    const [kinds, setKinds] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);


    const toast = useRef();

    useEffect(() => {
        const fetchData = async () => {
            let response = await vehicleService.getDetails(props.id);
            setDetails(response);
            let creatorRes = await userService.getByID(response.pojUtworzyl);
            setCreator(creatorRes);
            if (response.pojModyfik) {
                response = await userService.getByID(response.pojModyfik);
                setModifier(response);
            }
        };

        const loadDictionary = async () => {
            let response = await dictionaryService.getVehicleStatus();
            setStatus(response);
            response = await dictionaryService.getVehicleKinds();
            setKinds(response);
            response = await dictionaryService.getVehicleBrands();
            setBrands(response);
            response = await dictionaryService.getVehicleModels();
            setModels(response);
        }

        loadDictionary();
        fetchData();
    }, [])

    const saveChanges = async () => {
        let response = await vehicleService.edit(details);
        if (response) {
            props.success()
            props.close();
        } else {
            toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Nie udało się zapisać zmian.', life: 5000, closable: true });
        }
    }

    return (
        <>
            <Toast ref={toast} />

            <div className="grid col-12 m-0 p-0 justify-content-center">
                <div className="col-3 text-right text-red">
                    Nr rejestracyjny:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojRejestracja || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter="alphanum"
                                value={details.pojRejestracja}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojRejestracja = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Marka:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {brands.length && details.pojMarkaID ? brands.find(b => b.value === details.pojMarkaID).label : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.pojMarkaID}
                                options={brands}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojMarkaID = e.value;
                                    details.pojModelID = null;
                                    setDetails({ ...details });
                                }}
                                filter
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Model:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {models.length && details.pojModelID ? models.find(m => m.value === details.pojModelID).label : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.pojModelID}
                                options={details.pojMarkaID ? models.filter(m => m.brand === details.pojMarkaID) : models}
                                onChange={(e) => {
                                    setChanges(true);
                                    if (details.pojMarkaID === null) {
                                        details.pojMarkaID = models.find(m => m.value === e.value).brand
                                    }
                                    details.pojModelID = e.value;
                                    setDetails({ ...details });
                                }}
                                filter
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Typ:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojTyp || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[A-z0-9.,-]/}
                                value={details.pojTyp ? details.pojTyp : ""}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojTyp = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Rok produkcji:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojRokProd || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.pojRokProd}
                                options={yearsArray()}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojRokProd = e.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Pierwsza rej.:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojPierRej ? moment(details.pojPierRej).format('DD/MM/yy') : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Calendar
                                value={details.pojPierRej ? moment(details.pojPierRej).toDate() : moment().toDate()}
                                dateFormat="dd/mm/yy"
                                locale="pl"
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojPierRej = moment(e.value).format();
                                    setDetails({ ...details });
                                }}
                                monthNavigator
                                yearNavigator
                                yearRange={"1900:2030"}
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Rodzaj:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {kinds.length && details.pojRodzajID ? kinds.find(k => k.value === details.pojRodzajID).label : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.pojRodzajID}
                                options={kinds}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojRodzajID = e.value;
                                    setDetails({ ...details });
                                }}
                                filter
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Status:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {status.length && details.pojStatusID ? status.find(s => s.value === details.pojStatusID).label : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.pojStatusID}
                                options={status}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojStatusID = e.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Nr VIN:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojVIN || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[A-HJ-NPR-Z0-9]/}
                                value={details.pojVIN}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojVIN = e.target.value;
                                    setDetails({ ...details });
                                }}
                                maxLength="17"
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Niestandardowy VIN:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojVINNiestandardowy || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter="alphanum"
                                value={details.pojVINNiestandardowy}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojVINNiestandardowy = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Pojemność:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojPojemnosc || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <div className="p-inputgroup">
                                <InputText
                                    keyfilter="num"
                                    value={details.pojPojemnosc}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.pojPojemnosc = e.target.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                                <span className="p-inputgroup-addon">cm<sup>3</sup></span>
                            </div>
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Moc:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojMoc || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <div className="p-inputgroup">
                                <InputText
                                    keyfilter="num"
                                    value={details.pojMoc}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.pojMoc = e.target.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                                <span className="p-inputgroup-addon">KW</span>
                            </div>
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Ładowność:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojLadownosc || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <div className="p-inputgroup">
                                <InputText
                                    keyfilter="num"
                                    value={details.pojLadownosc}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.pojLadownosc = e.target.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                                <span className="p-inputgroup-addon">kg</span>
                            </div>
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    DMC:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojDMC || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <div className="p-inputgroup">
                                <InputText
                                    keyfilter="num"
                                    value={details.pojDMC}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.pojDMC = e.target.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                                <span className="p-inputgroup-addon">kg</span>
                            </div>
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Ilość miejsc:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojIloMiejsc || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter="num"
                                value={details.pojIloMiejsc}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojIloMiejsc = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Data badania:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                        {details.pojDataBadania ? moment(details.pojDataBadania).format('DD/MM/yy') : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Calendar
                                value={details.pojDataBadania ? moment(details.pojDataBadania).toDate() : moment().toDate()}
                                dateFormat="dd/mm/yy"
                                locale="pl"
                                onChange={(e) => {
                                    setChanges(true);
                                    details.pojDataBadania = e.value;
                                    setDetails({ ...details });
                                }}
                                monthNavigator
                                yearNavigator
                                yearRange={"1900:2030"}
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Przebieg:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.pojPrzebieg || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <div className="p-inputgroup">
                                <InputText
                                    keyfilter="num"
                                    value={details.pojPrzebieg}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.pojPrzebieg = e.target.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                                <span className="p-inputgroup-addon">km</span>
                            </div>
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-6"></div>
                

                <div className="col-3 text-right text-red">
                    Utworzył:
                </div>
                <div className="col-3 h-5rem">
                    {creator ? creator.usNazw + " " + creator.usImie : ""}
                    <br />
                    {moment(details.pojDataUt).format("DD/MM/yy HH:mm")}
                </div>
                {modifier ?
                    <>
                        <div className="col-3 text-right text-red">
                            Modyfikował:
                        </div>
                        <div className="col-3">
                            {modifier.usNazw + " " + modifier.usImie}
                            <br />
                            {moment(details.pojDataMod).format("DD/MM/yy HH:mm")}
                        </div>
                    </>
                    :
                    <div className="col-6 h-5rem">

                    </div>
                }
            </div>

            {
                changes ?
                    <div className="grid col-12 mt-3 justify-content-center">
                        <Button
                            className="odf-shine"
                            label="Zapisz"
                            icon="pi pi-fw pi-save"
                            onClick={() => saveChanges()}
                        />
                    </div>
                    :
                    null
            }
        </>
    )
}