/* eslint-disable no-unused-expressions */
import "./styles/main.scss";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import logo from "./assets/logo32.png";

import { Route, Routes, useNavigate } from "react-router-dom";
import MainPage from "./components/MainPage";
import { Menubar } from "primereact/menubar";
import NotFound from "./components/NotFound";
import { Footer } from "./components/Footer";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import LoginPanel from "./components/LoginPanel";
import userService from "./services/userService";
import authService from "./services/authService";
import { useEffect, useRef, useState } from "react";
import Policies from "./components/Policies";
import { addLocale, locale } from "primereact/api";
import Clients from "./components/Clients";
import Vehicles from "./components/Vehicles";
import InsuranceObjects from "./components/InsuranceObjects";
import Sales from "./components/Sales";
import NewsPanel from "./components/news/NewsPanel";
import NewsDetails from "./components/news/NewsDetails";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import ApkPanel from "./components/ApkPanel";
import NewPolicySurvey from "./components/NewPolicySurvey";

function App() {
  const [user, setUser] = useState({ usImie: "?" });
  const [changePassData, setChangePassData] = useState({ oldPass: "", newPass: "", newPassRep: "" });

  const [changePassVisible, setChangePassVisible] = useState(false);
  const [oldPassInvalid, setOldPassInvalid] = useState(false);
  const [newPassInvalid, setNewPassInvalid] = useState(false);
  const [passRepInvalid, setPassRepInvalid] = useState(false);

  const toast = useRef(null);

  const passFeedbackHeader = <div className="font-bold mb-3">Wybierz hasło</div>;

  const passFeedbackFooter = (
    <>
      <Divider />
      <p className="mt-2">Sugestia:</p>
      <ul className="pl-2 ml-2 mt-0 line-height-3">
        <li>Przynajmniej jedna mała litera</li>
        <li>Przynajmniej jedna wielka litera</li>
        <li>Przynajmniej jedna liczba</li>
        <li>Minimum 8 znaków</li>
      </ul>
    </>
  )

  let navigate = useNavigate();

  locale("pl");

  addLocale("pl", {
    firstDayOfWeek: 1,
    dayNames: [
      "Niedziela",
      "Poniedziałek",
      "Wtorek",
      "Środa",
      "Czwartek",
      "Piątek",
      "Sobota"
    ],
    dayNamesShort: ["niedz", "pon", "wt", "śr", "czw", "pt", "so"],
    dayNamesMin: ["N", "P", "W", "Ś", "C", "P", "S"],
    monthNames: [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień",
    ],
    monthNamesShort: [
      "sty",
      "lu",
      "mar",
      "kw",
      "maj",
      "czer",
      "lip",
      "sier",
      "wrz",
      "paź",
      "lis",
      "gru",
    ],
    today: "Dziś",
    clear: "Wyczyść",
  });

  const menuModel = [
    {
      label: "Strona główna",
      icon: "pi pi-fw pi-home",
      command: () => navigate("/"),
    },
    {
      label: "Klienci",
      icon: "pi pi-fw pi-users",
      command: () => navigate("/clients"),
    },
    {
      label: "APK",
      icon: "pi pi-fw pi-chart-bar",
      command: () => navigate("/apk"),
    },
    {
      label: "Polisy",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "Dodaj nową",
          icon: "pi pi-fw pi-plus",
          command: () => navigate("/newpolicy"),
        },
        {
          label: "Moje polisy",
          icon: "pi pi-fw pi-list",
          command: () => navigate("/mypolicies"),
        },
        {
          label: "Sprzedaż dzienna",
          icon: "pi pi-fw pi-money-bill",
          command: () => navigate("/sales"),
        }
      ],
    },
    {
      label: "Pojazdy",
      icon: "pi pi-fw pi-car",
      command: () => navigate("/vehicles"),
    },
    {
      label: "Obiekty",
      icon: "pi pi-fw pi-folder",
      command: () => navigate("/objects"),
    },
    user.usAktMan ?
      {
        label: "Aktualności",
        icon: "pi pi-fw pi-compass",
        command: () => navigate("/newspanel"),
      } : <></>,
    // user.usTestPerm ?
    //   {
    //     label: "Test",
    //     icon: "pi pi-fw pi-cog",
    //     command: () => navigate("/test"),
    //   } :
    //   <>
    //   </>
  ];

  // const contextModel = [
  //   {
  //     label: "Mój profil",
  //     icon: "pi pi-fw pi-user",
  //     command: () => console.log("Me"),
  //   },
  //   {
  //     label: "Wyloguj",
  //     icon: "pi pi-fw pi-sign-out",
  //     command: () => console.log("Logout"),
  //   },
  // ];

  const loadUser = async () => {
    let response = await userService.getCurrentUser();
    if (response) setUser(response);
  };

  const changePass = async () => {
    if (changePassData.oldPass === "") {
      toast.current.show({ severity: 'warn', summary: 'Błąd formularza', detail: 'Aktualne hasło jest wymagane!', life: 3000 });
      setOldPassInvalid(true);
      return;
    } else
      setOldPassInvalid(false);

    if (changePassData.newPass === "") {
      toast.current.show({ severity: 'warn', summary: 'Błąd formularza', detail: 'Nowe hasło jest wymagane!', life: 3000 });
      setNewPassInvalid(true);
      return;
    } else
      setNewPassInvalid(false);

    if (changePassData.newPass !== changePassData.newPassRep) {
      toast.current.show({ severity: 'warn', summary: 'Błąd formularza', detail: 'Hasła nie są takie same!', life: 3000 });
      setPassRepInvalid(true);
      return;
    } else
      setPassRepInvalid(false);

    let response = await authService.changePass({ ...changePassData, usID: user.usID });
    switch (response) {
      case 200:
        toast.current.show({ severity: 'success', summary: 'Pomyślnie zmieniono hasło', detail: 'Za chwilę nastąpi wylogowanie!', life: 3000 })
        setTimeout(() => authService.logout(), 3000);
        break;
      case 401:
        toast.current.show({ severity: 'error', summary: 'Brak autoryzacji', detail: 'Aktualnie hasło jest błędne!', life: 3000 })
        setOldPassInvalid(true);
        break;
      default:
        toast.current.show({ severity: 'error', summary: `Błąd ${response}`, detail: 'Nieznany przyczyna błędu!', life: 3000 })
        break;
    }
  };

  useEffect(() => {
    if (!user.usID) {
      loadUser();
    }
  });

  return (
    <div className="app">
      {user.usID ? (
        <>
          <Toast ref={toast} />
          <Menubar
            model={menuModel}
            start={<img src={logo} alt="ODF logo" className="mr-2" />}
            end={
              <div>
                <Avatar
                  className="menubar-avatar"
                  label={user.usImie.charAt(0) + user.usNazw.charAt(0)}
                />
                <Button
                  className="odf-border odf-button w-3rem"
                  label="Zmień hasło"
                  tooltip="Zmień hasło"
                  tooltipOptions={{ showDelay: 1000, position: 'bottom' }}
                  icon="pi pi-fw pi-key"
                  onClick={() =>
                    setChangePassVisible(true)
                  }
                />
                <Button
                  className="odf-border odf-button w-3rem"
                  label="Wyloguj"
                  tooltip="Wyloguj"
                  tooltipOptions={{ showDelay: 1000, position: 'bottom' }}
                  icon="pi pi-fw pi-sign-out"
                  onClick={() => {
                    authService.logout();
                  }}
                />
              </div>
            }
          />
          <div className="main-wrapper">
            <div className="router-content">
              <Routes>
                <Route path="/" element={<MainPage user={user} />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/mypolicies" element={<Policies user={user} />} />
                <Route path="/apk" element={<ApkPanel user={user} />} />
                <Route path="/newpolicy" element={<NewPolicySurvey user={user} />} />
                <Route path="/sales" element={<Sales user={user} />} />
                <Route path="/vehicles" element={<Vehicles />} />
                <Route path="/objects" element={<InsuranceObjects />} />
                {user.usAktMan ? <Route path="/newspanel" element={<NewsPanel />} /> : null}
                <Route path="/newsdetails" element={<NewsDetails />} />
                {/* {user.usTestPerm ? <Route path="/test" element={<AddPolicyTest user={user} />} /> : null} */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <Footer />
          </div>

          <Dialog
            visible={changePassVisible}
            className="col-10 sm:col-6 md:col-4 xl:col-3"
            header="Zmiana hasła"
            closable
            onHide={() => {
              setChangePassData({ oldPass: "", newPass: "", newPassRep: "" });
              setOldPassInvalid(false);
              setNewPassInvalid(false);
              setPassRepInvalid(false);
              setChangePassVisible(false);
            }}
          >
            <div className="flex col-12 flex-column align-items-center">
              <span className="p-float-label m-3">
                <Password inputId="oldPass" className={oldPassInvalid ? "p-invalid" : ""} value={changePassData.oldPass} onChange={(e) => setChangePassData({ ...changePassData, oldPass: e.target.value })} toggleMask feedback={false} promptLabel="Wpisz hasło" weakLabel="Słabe" mediumLabel="Średnie" strongLabel="Silne" />
                <label htmlFor="oldPass">Aktualne hasło</label>
              </span>
              <span className="p-float-label m-3">
                <Password inputId="newPass" className={passRepInvalid || newPassInvalid ? "p-invalid" : ""} value={changePassData.newPass} onChange={(e) => setChangePassData({ ...changePassData, newPass: e.target.value })} toggleMask header={passFeedbackHeader} footer={passFeedbackFooter} promptLabel="Wpisz hasło" weakLabel="Słabe" mediumLabel="Średnie" strongLabel="Silne" />
                <label htmlFor="newPass">Nowe hasło</label>
              </span>
              <span className="p-float-label m-3">
                <Password inputId="newPassRep" className={passRepInvalid ? "p-invalid" : ""} value={changePassData.newPassRep} onChange={(e) => setChangePassData({ ...changePassData, newPassRep: e.target.value })} toggleMask header={passFeedbackHeader} footer={passFeedbackFooter} promptLabel="Wpisz hasło" weakLabel="Słabe" mediumLabel="Średnie" strongLabel="Silne" />
                <label htmlFor="newPassRep">Powtórz hasło</label>
              </span>
              <Button
                label="Zatwierdź"
                icon="pi pi-fw pi-check-circle"
                onClick={changePass}
              />
            </div>
          </Dialog>
        </>
      ) : (
        <LoginPanel />
      )}
    </div>
  );
}

export default App;
