import { useEffect, useRef, useState } from "react"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//import Editor from '@ckeditor/ckeditor5-editor-custom';
import newsService from "../../services/newsService";
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";
import { Calendar } from "primereact/calendar";
import { Image } from "primereact/image";
import { FileUpload } from 'primereact/fileupload';
import { Button } from "primereact/button";
import moment from "moment/moment";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";


export default function EditNews(props) {

    const [article, setArticle] = useState({ akWidoczny: true, akWidocznyOd: moment().format(), akTresc: "", akNaglowek: "", zmiany: false });
    const [images, setImages] = useState([]);
    const [mainImage, setMainImage] = useState({ loading: true });

    const toast = useRef(null);

    useEffect(() => {
        const fetchData = async (akID) => {
            let response = await newsService.getDetails(akID);
            if (response) {
                setArticle({ ...response.article, zmiany: false });
                setMainImage({ ...response.mainImage, loading: false });
            }
        }

        fetchData(props.article);
    }, [props]);

    useEffect(() => {
    }, [article])

    const changeHandler = async (event, editor) => {
        if (typeof article.akID !== 'undefined') {
            const data = await editor.getData();
            setArticle({ ...article, akTresc: data, zmiany: true });
        }
    }

    function uploadPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const uploadAdapter = (loader) => {
        return {
            upload: () => {
                return loader.file.then(async (file) => {
                    const data = new FormData();
                    data.append("file", file);
                    data.append("mainImg", false);
                    data.append("apiEndpoint", newsService.apiEndpoint);
                    let response = await newsService.uploadImage(data);
                    setImages([...images, response.id]);
                    return response;
                }
                )
            }
        };
    }

    const customBase64Uploader = async (event) => {
        setMainImage({ loading: true })
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob());
        reader.readAsDataURL(blob);
        reader.onloadend = async function () {
            const base64data = reader.result;
            const data = new FormData();
            data.append("file", file);
            data.append("mainImg", true);
            data.append("apiEndpoint", newsService.apiEndpoint);
            let response = await newsService.uploadImage(data);
            setMainImage({ ...mainImage, name: file.name, type: file.type, size: file.size, base64: base64data, newID: response.id, loading: false });
            setArticle({ ...article, zmiany: true });
        }
    }

    const headerTemplate = (options) => {
        const { className, chooseButton } = options;

        return (
            <div className={className} style={{ backgroundColor: 'transparent', alignItems: 'center' }}>
                {chooseButton}
            </div>
        );
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{ height: '60px' }}>
                <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="my-3">Przeciągnij i upuść</span>
            </div>
        )
    }

    const editArticle = async () => {
        let response = await newsService.editArticle(article, { akzID: mainImage.akzID, newID: mainImage.newID, del: mainImage.del });
        if (response.ok) {
            props.success();
        } else {
            toast.current.show({ severity: 'warn', summary: 'Błąd', detail: 'Nie udało się zapisać zmian!', closable: true });
        }
    }

    return (
        <div className="grid justify-content-center pt-5" style={{ maxWidth: '100vw' }}>
            <Toast ref={toast} />
            <div className="col-3">
                {
                    mainImage.base64 ?
                        <Image
                            alt={mainImage.name}
                            width="200"
                            height="100"
                            preview
                            src={mainImage.base64}
                        />
                        :
                        <div className="fu-component">
                            {
                                mainImage.loading &&
                                <div className="spinner-overlay"><ProgressSpinner /></div>
                            }
                            <FileUpload
                                className="p-fileupload"
                                accept="image/*"
                                name="demo[]"
                                chooseLabel="Wybierz"
                                auto
                                customUpload
                                uploadHandler={customBase64Uploader}
                                headerTemplate={headerTemplate}
                                emptyTemplate={emptyTemplate}
                                chooseOptions={{ icon: 'pi pi-fw pi-images', className: 'custom-choose-btn p-button-rounded f-w' }}
                            />
                        </div>
                }
            </div>
            {
                mainImage.base64 &&
                <Button
                    icon='pi pi-fw pi-trash'
                    className="col-1"
                    style={{ height: '106px', marginTop: '8px' }}
                    onClick={() => { setMainImage({ akzID: mainImage.akzID ?? mainImage.newID, del: true, loading: false }); setArticle({ ...article, zmiany: true }); }}
                />
            }
            <div className={mainImage ? "col-8" : "col-9"}>
                <div className="grid col-12">
                    <span className="p-float-label f-w">
                        <InputText
                            id="title"
                            className="f-w"
                            value={article.akNaglowek}
                            onChange={(e) => setArticle({ ...article, akNaglowek: e.target.value, zmiany: true })}
                        />
                        <label htmlFor="title">Tytuł:</label>
                    </span>
                </div>
                <div className="grid col-12 mt-3 justify-content-between">
                    <div className="grid col">
                        <div className="mr-3">
                            <ToggleButton
                                onLabel="Widoczny"
                                onIcon="pi pi-fw pi-eye"
                                offLabel="Ukryty"
                                offIcon="pi pi-fw pi-eye-slash"
                                checked={article.akWidoczny}
                                onChange={(e) => setArticle({ ...article, akWidoczny: e.value, akWidocznyOd: e.value ? moment().format() : null, zmiany: true })}
                            />
                        </div>
                        <div>
                            <span className="p-float-label">
                                <Calendar
                                    disabled={article.akWidoczny}
                                    id="visibleCal"
                                    dateFormat="dd/mm/yy"
                                    showIcon
                                    value={article.akWidocznyOd ? moment(article.akWidocznyOd).toDate() : null}
                                    onChange={(e) => setArticle({ ...article, akWidocznyOd: moment(e.value).format(), zmiany: true })}
                                />
                                <label htmlFor="visibleCal">Widoczny od:</label>
                            </span>
                        </div>
                    </div>
                    {
                        article.zmiany ?
                            <div className="col-2">
                                <Button
                                    label="Zapisz"
                                    icon="pi pi-fw pi-save"
                                    className="f-w h-3rem"
                                    onClick={editArticle}
                                />
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <div className="col-12">
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        language: 'pl',
                        extraPlugins: [uploadPlugin],
                    }}
                    data={article.akTresc}
                    onChange={changeHandler}
                />
            </div>
            <div className="grid col-10 font-italic justify-content-center">
                <div className="m-2">
                    <label className="font-bold">Autor :</label> {article.uusImie + ' ' + article.uusNazw}
                </div>
                <div className="m-2">
                    <label className="font-bold">Data utworzenia :</label> {moment(article.akDataUt).format('DD/MM/YYYY HH:mm')}
                </div>
                {
                    article.musImie ?
                        <>
                            <div className="m-2">
                                <label className="font-bold">Modyfikował :</label> {article.musImie + ' ' + article.musNazw}
                            </div>
                            <div className="m-2">
                                <label className="font-bold">Data modyfikacji :</label> {moment(article.akDataMod).format('DD/MM/YYYY HH:mm')}
                            </div>
                        </>
                        :
                        null
                }
            </div>
        </div>
    )
}