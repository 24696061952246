/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/office";

export async function getByID(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getByID", {params: {id}});
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getList() {
    try {
      const promise = await httpService.get(apiEndpoint + "/getList");
      const { data: response } = promise;
      return response;
    } catch (error) {
      return null;
    }
  }

  export async function getCurrent() {
    try {
      const promise = await httpService.get(apiEndpoint + "/getCurrent");
      const { data: response } = promise;
      return response;
    } catch (error) {
      return null;
    }
  }

export default {
    getByID,
    getList,
    getCurrent
};
