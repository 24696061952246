import { useEffect, useRef, useState } from "react"
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import ApkCreator from "./adders/ApkCreator";
import apkService from "../services/apkService";
import moment from "moment";
import { ContextMenu } from "primereact/contextmenu";
import Policies from "./Policies";


export default function ApkPanel(props) {

    const [apkList, setApkList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            apkNumer: '',
            polNumerTxt: '',
            apkDoradca: '',
            apkDataUt: [],
            apkMiejsce: '',
            apkKlient: '',
            apkPESREG: '',
            apkKontakt: '',
        }
    });
    const [apkDetails, setApkDetails] = useState({ apkID: null, apkNumer: '' });

    const [linkVisible,setLinkVisible] = useState(false);
    const [addApkVisible, setAddApkVisible] = useState(false);
    const [selection,setSelection] = useState(null);

    const toast = useRef(null);
    const cm = useRef(null);

    useEffect(() => {
        
    }, [])

    useEffect(() => {
        loadLazyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams])

    const loadLazyData = async () => {
        setLoading(true);
        let response = await apkService.getPackage(JSON.stringify(
            {
                ...lazyParams,
                filters:
                {
                    ...lazyParams.filters,
                    apkDataUt: [
                        lazyParams.filters.apkDataUt[0] && moment(lazyParams.filters.apkDataUt[0]).format(),
                        lazyParams.filters.apkDataUt[1] && moment(lazyParams.filters.apkDataUt[1]).format()
                    ]
                }
            }
        ));
        if (response) {
            setTotalRecords(response.totalRecords);
            setApkList(response.forms)
        }
        setLoading(false);
    }

    const onPage = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onSort = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const filterElement = (field) => {
        return (
            <>
                <InputText
                    id={field + "Filter"}
                    style={{ width: "80%", borderRadius: 0 }}
                    value={lazyParams.filters[field]}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.target.value;
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                />
                <Button
                    icon="pi pi-fw pi-filter-slash"
                    style={{ width: "20%", borderRadius: 0 }}
                    onClick={() => {
                        lazyParams.filters[field] = '';
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters[field] === '' ? true : false}
                />
            </>
        )
    }

    const filterCalendar = (field) => {
        return (
            <>
                <Calendar
                    id={field + "Filter"}
                    className="filter-element"
                    value={lazyParams.filters[field]}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.value ?? [];
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                    monthNavigator
                    yearNavigator
                    yearRange={"1900:2030"}
                    dateFormat="dd/mm/yy"
                    showIcon={false}
                    selectionMode='range'
                />
                <Button
                    style={{ width: "20%", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = [];
                        setLazyParams({ ...lazyParams });
                    }
                    }
                    disabled={lazyParams.filters[field]?.length === 0 ? true : false}
                />
            </>
        )
    }

    return (
        <>
            <Toast ref={toast} />

            <div className='grid justify-content-center'>
                <div className='flex col text-center ml-6 mr-6 mt-3 table-header justify-content-center'>
                    <div className='ml-6 mr-6'>APK</div>
                    <div className='pr-0'>
                        <Button
                            label='Dodaj'
                            icon='pi pi-fw pi-plus'
                            onClick={() => setAddApkVisible(true)}
                        />
                    </div>
                </div>
                <ContextMenu
                    model={[{label: 'Podepnij polisę', icon: 'pi pi-fw pi-link', command: () => setLinkVisible(true)}]}
                    ref={cm}
                />
                <DataTable
                    className='ml-6 mr-6 mb-3 f-w'
                    value={apkList}
                    loading={loading}
                    lazy
                    paginator
                    rows={lazyParams.rows}
                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                    dataKey="kliID"
                    filterDisplay='row'
                    responsiveLayout='scroll'
                    first={lazyParams.first}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onSort={onSort}
                    emptyMessage="Brak wyników"
                    onRowDoubleClick={(e) => props.selectionMode ? props.onChoice(e.data.apkID, e.data.apkNumer) : setApkDetails({ apkNumer: e.data.apkNumer, apkID: e.data.apkID })}
                    onContextMenu={(e) => cm.current.show(e.originalEvent)}
                    contextMenuSelection={selection}
                    onContextMenuSelectionChange={(e) => setSelection(e.value)}
                >
                    <Column field="apkNumer" header="Numer" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} />
                    <Column field="polNumerTxt" header="Polisa" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.polNumerTxt || "Brak"} />
                    <Column field="apkKlient" header="Klient" sortable filter={true} showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.apkKlient || "Brak"} />
                    <Column field="apkPESREG" header="PESEL/REGON" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.apkPESREG || "Brak"} />
                    <Column field="apkKontakt" header="Tel./Mail" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.apkKontakt || "Brak"} />
                    <Column field="apkDoradca" header="Doradca" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.apkDoradca || "Brak"} />
                    <Column field="apkMiejsce" header="Miejsce" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.apkMiejsce || "Brak"} />
                    <Column field="apkDataUt" header="Data" sortable filter showFilterMenu={false} filterElement={(e) => filterCalendar(e.field)} showClearButton={false} body={(rowData) => rowData.apkDataUt ? moment(rowData.apkDataUt).format('L') : "Brak"} />
                </DataTable>
            </div>

            <Dialog
                visible={apkDetails.apkID !== null}
                header={"Formularz " + apkDetails.apkNumer}
                onHide={() => setApkDetails({ apkNumer: '', apkID: null })}
                dismissableMask
                maximizable
                className='col-11'
            >
                <ApkCreator
                    id={apkDetails.apkID}
                    user={props.user}
                />
            </Dialog>

            <Dialog
                visible={addApkVisible}
                header="Nowy formularz"
                onHide={() => setAddApkVisible(false)}
                dismissableMask
                maximizable
                className='col-11'
            >
                <ApkCreator
                    id={null}
                    onSaved={(apkID, apkNumer) => {
                        if (props.selectionMode)
                            props.onChoice(apkID, apkNumer);
                        else {
                            setAddApkVisible(false);
                            loadLazyData();
                            toast.current.show({ severity: 'success', summary: "Sukces", detail: 'Zapisano formularz!', closable: true });
                        }
                    }}
                    user={props.user}
                />
            </Dialog>
            <Dialog
                visible={linkVisible}
                header="Wybierz polisę"
                onHide={() => setLinkVisible(false)}
                dismissableMask
                maximizable
                className='col-11'
            >
                <Policies
                    user={props.user}
                    searchMode={true}
                    onChoice={async (polID, polNumerTxt) => {
                        let response = await apkService.linkPolicy(selection.apkID,polID)
                        if(response){
                            setLinkVisible(false);
                            toast.current.show({ severity: 'success', summary: "Sukces", detail: 'Podpięto polisę!', closable: true });
                            loadLazyData()
                        }else {
                            toast.current.show({ severity: 'error', summary: "Błąd", detail: 'Nie udało się podpiąć polisy!', closable: true });
                        }
                    }}
                />
            </Dialog>
        </>
    )
}