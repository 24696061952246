/* eslint-disable react-hooks/exhaustive-deps */

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from "primereact/column";
import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ClientDetails from './details/ClientDetails';
import clientService from '../services/clientService';
import { Dropdown } from 'primereact/dropdown';
import dictionaryService from '../services/dictionaryService';
import AddClient from './adders/AddClient';


export default function Clients(props) {
    const [clients, setClients] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            kliINN: "",
            kliTelefon: "",
            kliPESEL: "",
            kliNIP: "",
            kliREGON: "",
            kliStatusID: "",
            kliTypID: ""
        }
    });
    const [clientDetails, setClientDetails] = useState({ kliID: null, kliINN: "" });
    const [status, setStatus] = useState([]);
    const [types, setTypes] = useState([]);
    const [addClientVisible, setAddClientVisible] = useState(false);

    const toast = useRef();

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        const loadDictionary = async () => {
            let response = await dictionaryService.getClientStatus();
            setStatus(response);
            response = await dictionaryService.getClientTypes();
            setTypes(response);
        }

        loadDictionary();
    }, [])

    const loadLazyData = async () => {
        setLoading(true);

        let response = await clientService.getPackage(JSON.stringify({ ...lazyParams, restricted: true }));
        if (response) {
            setTotalRecords(response.totalRecords);
            setClients(response.clients)
        }
        setLoading(false);
    }


    const onPage = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onSort = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const filterElement = (field) => {
        return (
            <>
                <InputText
                    id={field + "Filter"}
                    style={field === 'kliINN' ? { width: "92%", borderRadius: 0 } : { width: "80%", borderRadius: 0 }}
                    value={lazyParams.filters[field]}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.target.value;
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                />
                <Button
                    style={field === 'kliINN' ? { width: "8%", borderRadius: 0 } : { width: "20%", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters[field] === "" ? true : false}
                />
            </>
        )
    }

    const filterDropdown = (field) => {
        return (
            <>
                <Dropdown
                    id={field + "Filter"}
                    style={{ width: "80%", borderRadius: 0, display: "inline-flex" }}
                    value={lazyParams.filters[field]}
                    options={field === "kliStatusID" ? status : types}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.value;
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                />
                <Button
                    style={{ width: "20%", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters[field] === "" ? true : false}
                />
            </>
        )
    }


    return (
        <>
            <Toast ref={toast} />

            <div className='grid justify-content-center'>
                <div className='flex col text-center ml-6 mr-6 mt-3 table-header justify-content-center'>
                    <div className='ml-6 mr-6'>Klienci</div>
                    <div className='pr-0'>
                        <Button
                            label='Dodaj'
                            icon='pi pi-fw pi-plus'
                            onClick={() => setAddClientVisible(true)}
                        />
                    </div>
                </div>
                <DataTable
                    className='ml-6 mr-6 mb-3 f-w'
                    value={clients}
                    loading={loading}
                    lazy
                    paginator
                    rows={lazyParams.rows}
                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                    dataKey="kliID"
                    filterDisplay='row'
                    responsiveLayout='scroll'
                    first={lazyParams.first}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onSort={onSort}
                    emptyMessage="Brak wyników"
                    onRowDoubleClick={(e) => props.searchMode ? props.onChoice(e.data.kliID, e.data.kliINN,e.data.kliTelefon, e.data.kliEMail, e.data.kliPESEL ?? e.data.kliREGON ) : setClientDetails({ kliID: e.data.kliID, kliINN: e.data.kliINN })}
                >
                    <Column style={{ width: "28%" }} field="kliINN" header="Nazwa" sortable filter={true} showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.kliINN || "Brak"} />
                    <Column style={{ width: "12%" }} field="kliTelefon" header="Nr telefonu" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.kliTelefon || "Brak"} />
                    <Column style={{ width: "12%" }} field="kliPESEL" header="PESEL" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.kliPESEL || "Brak"} />
                    <Column style={{ width: "12%" }} field="kliNIP" header="NIP" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.kliNIP || "Brak"} />
                    <Column style={{ width: "12%" }} field="kliREGON" header="REGON" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.kliREGON || "Brak"} />
                    <Column style={{ width: "12%" }} field="kliStatusID" header="Status" sortable filter showFilterMenu={false} filterElement={(e) => filterDropdown(e.field)} showClearButton={false} body={(rowData) => status.length && rowData.kliStatusID ? status.find(s => s.value === rowData.kliStatusID).label : "Brak"} />
                    <Column style={{ width: "12%" }} field="kliTypID" header="Typ" sortable filter showFilterMenu={false} filterElement={(e) => filterDropdown(e.field)} showClearButton={false} body={(rowData) => types.length && rowData.kliTypID ? types.find(t => t.value === rowData.kliTypID).label : "Brak"} />
                </DataTable>
            </div>

            <Dialog
                visible={clientDetails.kliID !== null}
                header={clientDetails.kliINN}
                onHide={() => setClientDetails({ kliID: null, kliINN: "" })}
                dismissableMask
                maximizable
                className='col-8'
            >
                <ClientDetails
                    id={clientDetails.kliID}
                    close={() => setClientDetails({ kliID: null, kliINN: "" })}
                    success={() => { toast.current.show({ severity: 'success', summary: 'Pomyślnie zapisano zmiany!', closable: true }); loadLazyData(); }
                    }
                />
            </Dialog>

            <Dialog
                visible={addClientVisible}
                header="Nowy klient"
                onHide={() => setAddClientVisible(false)}
                dismissableMask
                maximizable
                className='col-10'
            >
                <AddClient close={(kliID, kliINN) => {
                    if (props.searchMode) {
                        props.onChoice(kliID, kliINN);
                    } else {
                        toast.current.show({ severity: 'success', summary: "Sukces", detail: 'Pomyślnie dodano klienta!', closable: true });
                        setAddClientVisible(false);
                    }
                }} />
            </Dialog>
        </>
    )
}