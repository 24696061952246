/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import dictionaryService from "../../services/dictionaryService";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import objectsService from "../../services/objectsService";
import { SplitButton } from "primereact/splitbutton";
import { Dialog } from "primereact/dialog";
import ClientDetails from "../details/ClientDetails";
import Clients from "../Clients";


export default function AddInsObj(props) {

    const [details, setDetails] = useState({ ouTypID: "", kliID: "", kliINN: "", ouNazwa: "", ouMiasto: "", ouAdrTyp: "", ouPoczta: "", ouKodPocz: "", ouNrDomu: "", ouNrLok: "", ouOpis: "", ouAdres: "" });
    const [types, setTypes] = useState([]);
    const [streetTypes, setStreetTypes] = useState([]);
    const [clientDetailsVisible, setClientDetailsVisible] = useState(false);
    const [clientListVisible, setClientListVisible] = useState(false);


    const toast = useRef();

    useEffect(() => {
        const loadDictionary = async () => {
            let response = await dictionaryService.getObjectTypes();
            setTypes(response);
            response = await dictionaryService.getStreetTypes();
            response.find(r => r.label === " ").label = "-";
            setStreetTypes(response);
        }

        loadDictionary();
    }, [clientDetailsVisible])

    const saveObject = async () => {
        if (details.kliID && details.ouTypID && details.ouAdrTyp && details.ouMiasto && details.ouNrDomu && details.ouKodPocz) {
            let response = await objectsService.create(details);
            if (response) {
                props.close(response.ouID, response.ouNazwa);
            } else {
                toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Nie udało się dodać obiektu!', life: 5000, closable: true });
            }
        } else {
            let str = 'Brak wymaganych danych: ';
            let c = 0;
            if (!details.kliID) {
                str = str.concat("klient");
                c++;
            }
            if (!details.ouTypID) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat("typ obiektu");
                c++;
            }
            if (!details.ouAdrTyp) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('typ adresu');
                c++;
            }
            if (!details.ouMiasto) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('miasto');
                c++;
            }
            if (!details.ouNrDomu) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('nr domu');
                c++;
            }
            if (!details.ouKodPocz) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('kod pocztowy');
                c++;
            }
            toast.current.show({ severity: 'warn', summary: "Błąd", detail: str, closable: true })
        }
    }

    return (
        <>
            <Toast ref={toast} />

            <div className="grid col-12 m-0 p-0 justify-content-center">
                <div className="col-3 text-right text-red">
                    Klient:
                </div>
                <div className="col-3 min-h-3rem">
                    {details.kliID ?
                        <SplitButton
                            label={details.kliINN ? details.kliINN.substring(0, 20) + "..." : ""}
                            onClick={() => setClientDetailsVisible(true)}
                            icon="pi pi-fw pi-search mr-1"
                            className="f-w"
                            buttonClassName="pl-1 pr-6"
                            model={[
                                {
                                    label: "Usuń",
                                    icon: "pi pi-times",
                                    command: () => {
                                        details.kliID = null;
                                        details.kliINN = null;
                                        setDetails({ ...details });
                                    }
                                }
                            ]}
                        />
                        :
                        <Button
                            icon="pi pi-fw pi-link mr-1"
                            label="Podepnij"
                            className="f-w"
                            onClick={() => setClientListVisible(true)}
                        />
                    }
                </div>
                <div className="col-3 text-right text-red">
                    Typ:
                </div>
                <div className="col-3 min-h-3rem">
                    <Dropdown
                        value={details.ouTypID}
                        options={types}
                        onChange={(e) => {
                            details.ouTypID = e.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Nazwa:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter={/[^^[\]!@#$%*()"':=+{};<>?\\/|]/}
                        value={details.ouNazwa}
                        onChange={(e) => {
                            details.ouNazwa = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Miasto:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter={/[^^[\]!@#$%*()0-9&"',:=+{};<>?\\/|]/}
                        value={details.ouMiasto}
                        onChange={(e) => {
                            details.ouMiasto = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Typ adresu:
                </div>
                <div className="col-3 min-h-3rem">
                    <Dropdown
                        value={details.ouAdrTyp}
                        options={streetTypes}
                        onChange={(e) => {
                            details.ouAdrTyp = e.value;
                            setDetails({ ...details });
                        }}
                        className="f-w h-full"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Ulica:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter={/[^^[\]!@#$%*()"':=+{};<>?\\/|]/}
                        value={details.ouAdres}
                        onChange={(e) => {
                            details.ouAdres = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Nr domu:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter={/[A-z0-9]/}
                        value={details.ouNrDomu}
                        onChange={(e) => {
                            details.ouNrDomu = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Nr lokalu:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter={/[^^[\]!@#$%*()&"',:=+{};<>?\\/|]/}
                        value={details.ouNrLok}
                        onChange={(e) => {
                            details.ouNrLok = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Kod pocztowy:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputMask
                        mask="99-999"
                        value={details.ouKodPocz}
                        onChange={(e) => {
                            details.ouKodPocz = e.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Poczta:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter={/[^^[\]!@#$%*()0-9&"',:=+{};<>?\\/|]/}
                        value={details.ouPoczta}
                        onChange={(e) => {
                            details.ouPoczta = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Opis:
                </div>
                <div className="col-9 min-h-3rem">
                    <InputTextarea
                        keyfilter={/[^^[\]!@#$%*()"':=+{};<>?\\/|]/}
                        value={details.ouOpis}
                        onChange={(e) => {
                            details.ouOpis = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
            </div>

            <div className="grid col-12 mt-3 justify-content-center">
                <Button
                    className="odf-shine"
                    label="Zapisz"
                    icon="pi pi-fw pi-save"
                    onClick={() => saveObject()}
                />
            </div>

            <Dialog
                visible={clientDetailsVisible}
                header={details.kliINN ? details.kliINN.substring(0, 20) + "..." : ""}
                onHide={() => setClientDetailsVisible(false)}
                dismissableMask
                maximizable
                className='col-8'
            >
                <ClientDetails
                    id={details.kliID}
                    close={() => setClientDetailsVisible(false)}
                    success={() => { toast.current.show({ severity: 'success', summary: 'Pomyślnie zapisano zmiany!', closable: true }); }
                    }
                />
            </Dialog>

            <Dialog
                visible={clientListVisible}
                header={"Podepnij obiekt"}
                onHide={() => setClientListVisible(false)}
                dismissableMask
                maximizable
                className='col-8'
            >
                <Clients
                    searchMode={true}
                    onChoice={(kliID, kliINN) => {
                        setClientListVisible(false);
                        details.kliID = kliID;
                        details.kliINN = kliINN;
                        setDetails({ ...details });
                    }}
                />
            </Dialog>
        </>
    )
}