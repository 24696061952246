import moment from "moment";

export function yearsArray(start) {
    let arr = [];

    for(var i=start || 1900; i<=moment().year(); i++)
        arr.unshift(i);

    return arr;
}

export function formatAddress(rowData){
    return <>
        {rowData.ouAdres+" "+rowData.ouNrDomu+(rowData.ouNrLok ? "/"+rowData.ouNrLok : "")}
        <br/>
        {(rowData.ouMiasto ? rowData.ouMiasto+" ," : "")+rowData.ouKodPocz+" "+(rowData.ouPoczta || " ")}
    </>
}