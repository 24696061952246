/* eslint-disable react-hooks/exhaustive-deps */

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from "primereact/column";
import { useEffect, useRef, useState } from 'react';
import vehicleService from '../services/vehicleService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import dictionaryService from '../services/dictionaryService';
import VehicleDetails from './details/VehicleDetails';
import AddVehicle from './adders/AddVehicle';


export default function Vehicles(props) {
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            pojRejestracja: "",
            pojMarkaID: "",
            pojModelID: "",
            pojRokProd: "",
            pojVIN: "",
            pojRodzajID: "",
            pojStatusID: ""
        }
    });
    const [vehicleDetails, setVehicleDetails] = useState({ pojID: null, pojRejestracja: "" });
    const [status, setStatus] = useState([]);
    const [kinds, setKinds] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);

    const [addVehicleVisible, setAddVehicleVisible] = useState(false);

    const toast = useRef();

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        const loadDictionary = async () => {
            let response = await dictionaryService.getVehicleStatus();
            setStatus(response);
            response = await dictionaryService.getVehicleKinds();
            setKinds(response);
            response = await dictionaryService.getVehicleBrands();
            setBrands(response);
            response = await dictionaryService.getVehicleModels();
            setModels(response);
        }

        loadDictionary();

    }, [])

    const loadLazyData = async () => {
        setLoading(true);

        let response = await vehicleService.getPackage(JSON.stringify({ ...lazyParams, restricted: true }));

        if (response) {
            setTotalRecords(response.totalRecords);
            setVehicles(response.vehicles)
        }
        setLoading(false);
    }

    const onPage = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onSort = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const filterElement = (field) => {
        return (
            <>
                <InputText
                    keyfilter="alphanum"
                    id={field + "Filter"}
                    style={{ width: "80%", borderRadius: 0 }}
                    value={lazyParams.filters[field]}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.target.value;
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                />
                <Button
                    style={{ width: "20%", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters[field] === "" ? true : false}
                />
            </>
        )
    }

    const filterDropdown = (field) => {
        return (
            <>
                <Dropdown
                    id={field + "Filter"}
                    style={{ width: "80%", borderRadius: 0, display: "inline-flex" }}
                    value={lazyParams.filters[field]}
                    options={getDropdownOptions(field)}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.value;
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        if (field === "pojModelID" && lazyParams.filters.pojMarkaID === "")
                            lazyParams.filters.pojMarkaID = models.find(m => m.value === e.value).brand;
                        else if (field === "pojMarkaID")
                            lazyParams.filters.pojModelID = null;
                        setLazyParams({ ...lazyParams });
                    }}
                    filter
                    filterBy='label'
                />
                <Button
                    style={{ width: "30px", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters[field] === "" ? true : false}
                />
            </>
        )
    }

    const getDropdownOptions = (field) => {
        switch (field) {
            case "pojMarkaID":
                return brands;
            case "pojModelID":
                return lazyParams.filters.pojMarkaID ? models.filter(m => m.brand === lazyParams.filters.pojMarkaID) : models;
            case "pojRodzajID":
                return kinds;
            case "pojStatusID":
                return status;
            default:
                return []
        }
    }


    return (
        <>
            <Toast ref={toast} />

            <div className='grid justify-content-center'>
                <div className='flex col text-center ml-6 mr-6 mt-3 table-header justify-content-center'>
                    <div className='ml-6 mr-6'>Pojazdy</div>
                    <div className='pr-0'>
                        <Button
                            label='Dodaj'
                            icon='pi pi-fw pi-plus'
                            onClick={() => setAddVehicleVisible(true)}
                        />
                    </div>
                </div>
                <DataTable
                    className='ml-6 mr-6 mb-3 f-w'
                    value={vehicles}
                    loading={loading}
                    lazy
                    paginator
                    rows={lazyParams.rows}
                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                    dataKey="pojID"
                    filterDisplay='row'
                    responsiveLayout='scroll'
                    first={lazyParams.first}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onSort={onSort}
                    emptyMessage="Brak wyników"
                    onRowDoubleClick={(e) => props.searchMode ? props.onChoice(e.data.pojID, e.data.pojRejestracja) : setVehicleDetails({ pojID: e.data.pojID, pojRejestracja: e.data.pojRejestracja })}
                >
                    <Column style={{ width: "12%" }} field="pojRejestracja" header="Nr rej." sortable filter={true} showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.pojRejestracja || "Brak"} />
                    <Column style={{ width: "15%" }} field="pojMarkaID" header="Marka" sortable filter showFilterMenu={false} filterElement={(e) => filterDropdown(e.field)} body={(rowData) => brands.length && rowData.pojMarkaID ? brands.find(s => s.value === rowData.pojMarkaID).label : "Brak"} showClearButton={false} />
                    <Column style={{ width: "15%" }} field="pojModelID" header="Model" sortable filter showFilterMenu={false} filterElement={(e) => filterDropdown(e.field)} body={(rowData) => models.length && rowData.pojModelID ? models.find(s => s.value === rowData.pojModelID).label : "Brak"} showClearButton={false} />
                    <Column style={{ width: "12%" }} field="pojRokProd" header="Rok prod." sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.pojRokProd || "Brak"} />
                    <Column style={{ width: "18%" }} field="pojVIN" header="VIN" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} body={(rowData) => rowData.pojVIN || "Brak"} />
                    <Column style={{ width: "15%" }} field="pojRodzajID" header="Rodzaj" sortable filter showFilterMenu={false} filterElement={(e) => filterDropdown(e.field)} showClearButton={false} body={(rowData) => kinds.length && rowData.pojRodzajID ? kinds.find(s => s.value === rowData.pojRodzajID).label : "Brak"} />
                    <Column style={{ width: "15%" }} field="pojStatusID" header="Status" sortable filter showFilterMenu={false} filterElement={(e) => filterDropdown(e.field)} showClearButton={false} body={(rowData) => status.length && rowData.pojStatusID ? status.find(s => s.value === rowData.pojStatusID).label : "Brak"} />
                </DataTable>
            </div>

            <Dialog
                visible={vehicleDetails.pojID !== null}
                header={"Pojazd " + vehicleDetails.pojRejestracja}
                onHide={() => setVehicleDetails({ pojID: null, pojRejestracja: "" })}
                dismissableMask
                maximizable
                className='col-8'
            >
                <VehicleDetails
                    id={vehicleDetails.pojID}
                    close={() => setVehicleDetails({ pojID: null, pojRejestracja: "" })}
                    success={() => { toast.current.show({ severity: 'success', summary: 'Pomyślnie zapisano zmiany!', closable: true }); loadLazyData() }
                    }
                />
            </Dialog>

            <Dialog
                visible={addVehicleVisible}
                header="Nowy pojazd"
                onHide={() => setAddVehicleVisible(false)}
                dismissableMask
                maximizable
                className='col-10'
            >
                <AddVehicle close={(pojID, pojRejestracja) => {
                    if (props.searchMode) {
                        props.onChoice(pojID, pojRejestracja);
                    } else {
                        toast.current.show({ severity: 'success', summary: "Sukces", detail: 'Pomyślnie dodano pojazd!', closable: true });
                    }
                    setAddVehicleVisible(false);
                }} />
            </Dialog>
        </>
    )
}