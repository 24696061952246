import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useRef, useState } from "react"
import SelectButton from "../../controls/SelectButton";
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Policies from '../Policies';
import { SplitButton } from 'primereact/splitbutton';
import logo from "../../assets/apk-odf.png";
import AddPolicy from "./AddPolicy";
import apkService from "../../services/apkService";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import Clients from "../Clients";
import { ScrollTop } from 'primereact/scrolltop';

export default function ApkCreator(props) {

    const [apkForm, setApkForm] = useState({
        polID: null,
        apkNumer: "",
        apkDoradca: props.user.usImie + ' ' + props.user.usNazw,
        apkDoradcaID: props.user.usID,
        apkData: moment().format(),
        apkMiejsce: props.user.usMiasto,
        klient_kliID: null,
        apkKlient: null,
        apkPESREG: null,
        apkKontakt: null,
        apkPojazd: {},
        apkMajatek: {},
        apkPodroze: {},
        apkZycieZdrowie: {},
        apkFirma: {},
        apkRolne: {},
        apkGrupowe: {},
        apkPakietyMedyczne: {},
        apkUwagi: null,
        apkUbzZainteresowanie: {},
        apkUbzPojazdu: { inne: "", uwagi: "" },
        apkUbzMajatku: { inne: "", uwagi: "" },
        apkUbzPodrozy: { inne: "", uwagi: "" },
        apkUbzFirmy: { dodatki: "", dodatki2: "", zawod: "", uwagi: "" },
        apkUbzRolne: { inne: "", uwagi: "" },
        apkUbzZycie: { inne: "", uwagi: "" },
        apkUbzInne: { sytuacje: "", szczegolne: "" },
        apkUwagi2: ""
    });
    const [dialogPage, setDialogPage] = useState(0);
    const [withPolicy, setWithPolicy] = useState(0);
    const [policySearch, setPolicySearch] = useState(false);
    const [policyCreate, setPolicyCreate] = useState(false);
    const [clientSearch, setClientSearch] = useState(false);

    const toast = useRef(null);

    const fetchData = async () => {
        let response = await apkService.getDetails(props.id);
        setApkForm(
            {
                ...response,
                apkPojazd: JSON.parse(response.apkPojazd),
                apkMajatek: JSON.parse(response.apkMajatek),
                apkPodroze: JSON.parse(response.apkPodroze),
                apkZycieZdrowie: JSON.parse(response.apkZycieZdrowie),
                apkFirma: JSON.parse(response.apkFirma),
                apkRolne: JSON.parse(response.apkRolne),
                apkGrupowe: JSON.parse(response.apkGrupowe),
                apkPakietyMedyczne: JSON.parse(response.apkPakietyMedyczne),
                apkUbzZainteresowanie: JSON.parse(response.apkUbzZainteresowanie),
                apkUbzPojazdu: JSON.parse(response.apkUbzPojazdu),
                apkUbzMajatku: JSON.parse(response.apkUbzMajatku),
                apkUbzPodrozy: JSON.parse(response.apkUbzPodrozy),
                apkUbzFirmy: JSON.parse(response.apkUbzFirmy),
                apkUbzRolne: JSON.parse(response.apkUbzRolne),
                apkUbzZycie: JSON.parse(response.apkUbzZycie),
                apkUbzInne: JSON.parse(response.apkUbzInne)
            }
        );
    }

    useEffect(() => {
        if (props.id) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const save = async () => {
        let response = await apkService.createForm(apkForm);
        if (response) {
            props.onSaved(response.apkID,response.apkNumer)
        } else
            toast.current.show({ severity: 'error', summary: "Błąd", detail: 'Nie udało się zapisać formularza!', closable: true })
    }

    const cancel = () => {
        setDialogPage(0);
        setWithPolicy(0);
        setApkForm({ ...apkForm, polID: null, polNumerTxt: "" });
        setPolicyCreate(false);
        setPolicySearch(false);
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={dialogPage}
                header="Zapis formularza"
                onHide={() => setDialogPage(1)}
                contentClassName="f-w"
            >
                {dialogPage === 1 ?
                    <div className="col-12 grid justify-content-center">
                        <SelectButton
                            locked={props.id !== null}
                            value={withPolicy}
                            btnStyle={{ height: '50px', margin: '20px 20px 50px 0', width: '100%', fontSize: '15px' }}
                            options={[{ label: 'Przypisz do polisy', value: 1 }, { label: 'Zapisz bez polisy', value: 0 }]}
                            onChange={(e) => setWithPolicy(e.value)}
                        />
                        <div className="grid">
                            <Button
                                className="mr-3"
                                label="Anuluj"
                                onClick={cancel}
                                icon="pi pi-fw pi-ban"
                            />
                            {withPolicy ?
                                <Button
                                    label="Dalej"
                                    icon="pi pi-fw pi-arrow-right"
                                    onClick={() => setDialogPage(2)}
                                />
                                :
                                <Button
                                    label="Zapisz"
                                    icon="pi pi-fw pi-save"
                                    onClick={save}
                                />
                            }
                        </div>
                    </div>
                    :
                    <div className="grid col-12 p-0 m-0 justify-content-center">
                        {apkForm.polID ?
                            <div className="col-12 flex justify-content-center mb-4">
                                <SplitButton
                                    label={apkForm.polNumerTxt}
                                    className=""
                                    icon="pi pi-fw pi-search"
                                    onClick={() => null}
                                    model={[
                                        {
                                            label: 'Odznacz',
                                            icon: 'pi pi-fw pi-replay',
                                            command: () => setApkForm({ ...apkForm, polID: null, polNumerTxt: "" })
                                        }
                                    ]}
                                />
                            </div>
                            :
                            <>
                                <div className="col-6 flex justify-content-end mb-4">
                                    <Button
                                        label="Wyszukaj istniejącą"
                                        onClick={() => setPolicySearch(true)}
                                        icon="pi pi-fw pi-search"
                                    />
                                </div>
                                <div className="col-6 flex justify-content-start mb-4">
                                    <Button
                                        label="Stwórz nową"
                                        onClick={() => setPolicyCreate(true)}
                                        icon="pi pi-fw pi-plus"
                                    />
                                </div>
                            </>
                        }
                        <div className="col-6 flex justify-content-end">
                            <Button
                                label="Anuluj"
                                onClick={cancel}
                                icon="pi pi-fw pi-ban"
                            />

                        </div>
                        <div className="col-6 flex justify-content-start">
                            {
                                apkForm.polID ?
                                    <Button
                                        label="Zapisz"
                                        icon="pi pi-fw pi-save"
                                        onClick={save}
                                    />
                                    :
                                    <Button
                                        label="Wróć"
                                        icon="pi pi-fw pi-arrow-left"
                                        onClick={() => setDialogPage(1)}
                                    />
                            }
                        </div>
                    </div>
                }
            </Dialog >
            <Dialog
                header="Wybierz polisę"
                visible={policySearch}
                onHide={() => setPolicySearch(false)}
            >
                <Policies
                    user={props.user}
                    searchMode={true}
                    onChoice={(polID, polNumerTxt) => {
                        setApkForm({ ...apkForm, polID, polNumerTxt });
                        setPolicySearch(false);
                    }}
                />
            </Dialog>
            <Dialog
                header="Stwórz polisę"
                visible={policyCreate}
                onHide={() => setPolicyCreate(false)}
            >
                <AddPolicy
                    user={props.user}
                    withReturn={true}
                    onReturn={(policy) => {
                        setApkForm({ ...apkForm, polID: policy.polID, polNumerTxt: policy.polNumerTxt });
                        setPolicyCreate(false);
                    }}
                />
            </Dialog>
            <Dialog
                header="Wybierz klienta"
                visible={clientSearch}

                onHide={() => setClientSearch(false)}
            >
                <Clients
                    user={props.user}
                    searchMode={true}
                    onChoice={(kliID, kliINN, kliTel, kliEmail, kliPESREG) => {
                        setApkForm({ ...apkForm, klient_kliID: kliID, apkKlient: kliINN, apkKontakt: (kliTel || '') + (kliTel != null && kliEmail != null ? '/' : '') + (kliEmail || ''), apkPESREG: kliPESREG });
                        setClientSearch(false);
                    }}
                />
            </Dialog>


            <div>
                {
                    props.id ?
                        null
                        :
                        <div className="grid justify-content-end">
                            <Button className="fixed mr-4"
                                label='Zapisz'
                                icon='pi pi-fw pi-save'
                                onClick={() => setDialogPage(1)}
                            />
                        </div>
                }
                <div className="grid col justify-content-center py-3 bg-white " style={{ 'overflow': 'auto', height: '75vh' }}>
                    <div className="my-3">
                        <img src={logo} alt="ODF logo" />
                    </div>
                    <div className="col-12 my-3 text-center text-xl">
                        Nr APK ______
                    </div>
                    <div className="grid col-10 my-3 p-0">
                        <div className="col-4 border-x-1 border-top-1">
                            Doradca: {apkForm.apkDoradca}
                        </div>
                        <div className="col-4 border-top-1">
                            Data i godzina: {moment().format('DD/MM/YYYY HH:mm')}
                        </div>
                        <div className="col-4 border-x-1 border-top-1">
                            Miejsce: {apkForm.apkMiejsce}
                        </div>
                        <div className="flex m-0 col-4 border-x-1 border-y-1">
                            <span className="m-1">
                                Klient:
                            </span>
                            <InputText
                                className="p-inputtext-sm flex-grow-1"
                                value={apkForm.apkKlient}
                                onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkKlient: e.target.value })}
                            />
                            <Button
                                icon={apkForm.klient_kliID ? "pi pi-fw pi-trash" : "pi pi-fw pi-search"}
                                onClick={() =>
                                    apkForm.klient_kliID ?
                                        setApkForm({ ...apkForm, klient_kliID: null, apkKlient: '', apkKontakt: '', apkPESREG: '' })
                                        :
                                        setClientSearch(true)
                                }
                            />
                        </div>
                        <div className="grid m-0 col-4 border-y-1">
                            <span className="m-1">
                                PESEL/REGON:
                            </span>
                            <InputText
                                className="p-inputtext-sm flex-grow-1"
                                keyfilter={'num'}
                                minLength={9}
                                maxLength={11}
                                value={apkForm.apkPESREG}
                                onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkPESREG: e.target.value })}
                            />
                        </div>
                        <div className="grid m-0 col-4 border-x-1 border-y-1">
                            <span className="m-1">
                                Tel./Mail:
                            </span>
                            <InputText
                                className="p-inputtext-sm flex-grow-1"
                                value={apkForm.apkKontakt}
                                onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkKontakt: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-0 border-1">
                        <div className="col-4 font-bold">
                            Rodzaj ubezpieczenia
                        </div>
                        <div className="col-2 font-bold border-x-1">
                            Decyzja klienta
                        </div>
                        <div className="col-6 font-bold">
                            Wybrany zakres ubezpieczenia
                        </div>
                        <div className="col-4 font-bold border-top-2">
                            Pojazd
                        </div>
                        <div className="col-2 border-x-1 border-top-2">
                            <SelectButton
                                locked={props.id !== null}
                                value={apkForm.apkPojazd.decyzja}
                                onChange={(e) => setApkForm({ ...apkForm, apkPojazd: { decyzja: e.value } })}
                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                            />
                        </div>
                        <div className="col-6 border-top-2 p-0">
                            <div className="grid m-0 border-bottom-1">
                                <div className="col-4 p-1">
                                    <Checkbox id='poc' disabled={props.id !== null || !apkForm.apkPojazd.decyzja} checked={apkForm.apkPojazd.oc} onChange={(e) => setApkForm({ ...apkForm, apkPojazd: { ...apkForm.apkPojazd, oc: e.checked } })} />
                                    <label htmlFor='poc'> OC</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='pac' disabled={props.id !== null || !apkForm.apkPojazd.decyzja} checked={apkForm.apkPojazd.ac} onChange={(e) => setApkForm({ ...apkForm, apkPojazd: { ...apkForm.apkPojazd, ac: e.checked } })} />
                                    <label htmlFor='pac'> AC</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='pa' disabled={props.id !== null || !apkForm.apkPojazd.decyzja} checked={apkForm.apkPojazd.assistance} onChange={(e) => setApkForm({ ...apkForm, apkPojazd: { ...apkForm.apkPojazd, assistance: e.checked } })} />
                                    <label htmlFor="pa"> ASSISTANCE</label>
                                </div>
                            </div>
                            <div className="grid m-0 border-bottom-1">
                                <div className="col-4 p-1">
                                    <Checkbox id='pnww' disabled={props.id !== null || !apkForm.apkPojazd.decyzja} checked={apkForm.apkPojazd.nww} onChange={(e) => setApkForm({ ...apkForm, apkPojazd: { ...apkForm.apkPojazd, nww: e.checked } })} />
                                    <label htmlFor="pnww"> NWW</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='pos' disabled={props.id !== null || !apkForm.apkPojazd.decyzja} checked={apkForm.apkPojazd.oponySzyby} onChange={(e) => setApkForm({ ...apkForm, apkPojazd: { ...apkForm.apkPojazd, oponySzyby: e.checked } })} />
                                    <label htmlFor="pos"> OPONY/SZYBY</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='pgap' disabled={props.id !== null || !apkForm.apkPojazd.decyzja} checked={apkForm.apkPojazd.gap} onChange={(e) => setApkForm({ ...apkForm, apkPojazd: { ...apkForm.apkPojazd, gap: e.checked } })} />
                                    <label htmlFor="pgap"> GAP</label>
                                </div>
                            </div>
                            <div className="grid m-0">
                                <div className="col-4 p-1">
                                    <Checkbox id='pzk' disabled={props.id !== null || !apkForm.apkPojazd.decyzja} checked={apkForm.apkPojazd.zk} onChange={(e) => setApkForm({ ...apkForm, apkPojazd: { ...apkForm.apkPojazd, zk: e.checked } })} />
                                    <label htmlFor="pzk"> ZK</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='poz' disabled={props.id !== null || !apkForm.apkPojazd.decyzja} checked={apkForm.apkPojazd.ochronaZnizki} onChange={(e) => setApkForm({ ...apkForm, apkPojazd: { ...apkForm.apkPojazd, ochronaZnizki: e.checked } })} />
                                    <label htmlFor="poz"> OCHRONA ZNIŻKI</label>
                                </div>
                                <div className="col-4 p-1">

                                </div>
                            </div>
                        </div>
                        <div className="col-4 font-bold border-top-2">
                            Majątek/dom/mieszkanie
                        </div>
                        <div className="col-2 border-x-1 border-top-2">
                            <SelectButton
                                locked={props.id !== null}
                                value={apkForm.apkMajatek.decyzja}
                                onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { decyzja: e.value } })}
                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                            />
                        </div>
                        <div className="col-6 border-top-2 p-0">
                            <div className="grid m-0 border-bottom-1">
                                <div className="col-4 p-1">
                                    <Checkbox id='mo' disabled={props.id !== null || !apkForm.apkMajatek.decyzja} checked={apkForm.apkMajatek.ogien} onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { ...apkForm.apkMajatek, ogien: e.checked } })} />
                                    <label htmlFor='mo'> OGIEŃ</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='mar' disabled={props.id !== null || !apkForm.apkMajatek.decyzja} checked={apkForm.apkMajatek.allRisk} onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { ...apkForm.apkMajatek, allRisk: e.checked } })} />
                                    <label htmlFor='mar'> ALL RISK</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='mc' disabled={props.id !== null || !apkForm.apkMajatek.decyzja} checked={apkForm.apkMajatek.cesja} onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { ...apkForm.apkMajatek, cesja: e.checked } })} />
                                    <label htmlFor='mc'> CESJA</label>
                                </div>
                            </div>
                            <div className="grid m-0 border-bottom-1">
                                <div className="col-4 p-1">
                                    <Checkbox id='mzl' disabled={props.id !== null || !apkForm.apkMajatek.decyzja} checked={apkForm.apkMajatek.zdarzeniaLosowe} onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { ...apkForm.apkMajatek, zdarzeniaLosowe: e.checked } })} />
                                    <label htmlFor="mzl"> ZDARZENIA LOSOWE</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='mo' disabled={props.id !== null || !apkForm.apkMajatek.decyzja} checked={apkForm.apkMajatek.oc} onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { ...apkForm.apkMajatek, oc: e.checked } })} />
                                    <label htmlFor="mo"> OC</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='mes' disabled={props.id !== null || !apkForm.apkMajatek.decyzja} checked={apkForm.apkMajatek.elementySzklane} onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { ...apkForm.apkMajatek, elementySzklane: e.checked } })} />
                                    <label htmlFor="mes"> ELEMENTY SZKLANE</label>
                                </div>
                            </div>
                            <div className="grid m-0">
                                <div className="col-4 p-1">
                                    <Checkbox id='mk' disabled={props.id !== null || !apkForm.apkMajatek.decyzja} checked={apkForm.apkMajatek.kradziez} onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { ...apkForm.apkMajatek, kradziez: e.checked } })} />
                                    <label htmlFor="mk"> KRADZIEŻ</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='mdg' disabled={props.id !== null || !apkForm.apkMajatek.decyzja} checked={apkForm.apkMajatek.dzialalnoscGospodarcza} onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { ...apkForm.apkMajatek, dzialalnoscGospodarcza: e.checked } })} />
                                    <label htmlFor="mdg"> DZIAŁALNOŚĆ GOSPODARCZA</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='mgr' disabled={props.id !== null || !apkForm.apkMajatek.decyzja} checked={apkForm.apkMajatek.gospodarstwoRolne} onChange={(e) => setApkForm({ ...apkForm, apkMajatek: { ...apkForm.apkMajatek, gospodarstwoRolne: e.checked } })} />
                                    <label htmlFor="mgr"> GOSPODARSTWO ROLNE</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 font-bold border-top-2">
                            Podróże
                        </div>
                        <div className="col-2 border-x-1 border-top-2">
                            <SelectButton
                                locked={props.id !== null}
                                value={apkForm.apkPodroze.decyzja}
                                onChange={(e) => setApkForm({ ...apkForm, apkPodroze: { decyzja: e.value } })}
                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                            />
                        </div>
                        <div className="col-6 border-top-2 p-0">
                            <div className="grid m-0 border-bottom-1">
                                <div className="col-4 p-1">
                                    <Checkbox id='pes' disabled={props.id !== null || !apkForm.apkPodroze.decyzja} checked={apkForm.apkPodroze.euSwiat} onChange={(e) => setApkForm({ ...apkForm, apkPodroze: { ...apkForm.apkPodroze, euSwiat: e.checked } })} />
                                    <label htmlFor="pes"> EU/ŚWIAT</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='pb' disabled={props.id !== null || !apkForm.apkPodroze.decyzja} checked={apkForm.apkPodroze.bagaz} onChange={(e) => setApkForm({ ...apkForm, apkPodroze: { ...apkForm.apkPodroze, bagaz: e.checked } })} />
                                    <label htmlFor="pb"> BAGAŻ</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='pcn' disabled={props.id !== null || !apkForm.apkPodroze.decyzja} checked={apkForm.apkPodroze.ocNnw} onChange={(e) => setApkForm({ ...apkForm, apkPodroze: { ...apkForm.apkPodroze, ocNnw: e.checked } })} />
                                    <label htmlFor="pcn"> OC/NNW</label>
                                </div>
                            </div>
                            <div className="grid m-0">
                                <div className="col-4 p-1">
                                    <Checkbox id='ps' disabled={props.id !== null || !apkForm.apkPodroze.decyzja} checked={apkForm.apkPodroze.sport} onChange={(e) => setApkForm({ ...apkForm, apkPodroze: { ...apkForm.apkPodroze, sport: e.checked } })} />
                                    <label htmlFor="ps"> SPORT</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='ppt' disabled={props.id !== null || !apkForm.apkPodroze.decyzja} checked={apkForm.apkPodroze.pracaTurystyka} onChange={(e) => setApkForm({ ...apkForm, apkPodroze: { ...apkForm.apkPodroze, pracaTurystyka: e.checked } })} />
                                    <label htmlFor="ppt"> PRACA/TURYSTYKA</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='pc' disabled={props.id !== null || !apkForm.apkPodroze.decyzja} checked={apkForm.apkPodroze.choroby} onChange={(e) => setApkForm({ ...apkForm, apkPodroze: { ...apkForm.apkPodroze, choroby: e.checked } })} />
                                    <label htmlFor="pc"> CHOROBY</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 font-bold border-top-2">
                            Życie/zdrowie
                        </div>
                        <div className="col-2 border-x-1 border-top-2">
                            <SelectButton
                                locked={props.id !== null}
                                value={apkForm.apkZycieZdrowie.decyzja}
                                onChange={(e) => setApkForm({ ...apkForm, apkZycieZdrowie: { decyzja: e.value } })}
                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                            />
                        </div>
                        <div className="col-6 border-top-2 p-0">
                            <div className="grid m-0 border-bottom-1">
                                <div className="col-4 p-1">
                                    <Checkbox id='zzoch' disabled={props.id !== null || !apkForm.apkZycieZdrowie.decyzja} checked={apkForm.apkZycieZdrowie.ochrona} onChange={(e) => setApkForm({ ...apkForm, apkZycieZdrowie: { ...apkForm.apkZycieZdrowie, ochrona: e.checked } })} />
                                    <label htmlFor="zzoch"> OCHRONA</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='zzzd' disabled={props.id !== null || !apkForm.apkZycieZdrowie.decyzja} checked={apkForm.apkZycieZdrowie.zdrowie} onChange={(e) => setApkForm({ ...apkForm, apkZycieZdrowie: { ...apkForm.apkZycieZdrowie, zdrowie: e.checked } })} />
                                    <label htmlFor="zzzd"> ZDROWIE</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='zzsz' disabled={props.id !== null || !apkForm.apkZycieZdrowie.decyzja} checked={apkForm.apkZycieZdrowie.szpital} onChange={(e) => setApkForm({ ...apkForm, apkZycieZdrowie: { ...apkForm.apkZycieZdrowie, szpital: e.checked } })} />
                                    <label htmlFor="zzsz"> SZPITAL</label>
                                </div>
                            </div>
                            <div className="grid m-0">
                                <div className="col-4 p-1">
                                    <Checkbox id='zzr' disabled={props.id !== null || !apkForm.apkZycieZdrowie.decyzja} checked={apkForm.apkZycieZdrowie.rodzina} onChange={(e) => setApkForm({ ...apkForm, apkZycieZdrowie: { ...apkForm.apkZycieZdrowie, rodzina: e.checked } })} />
                                    <label htmlFor="zzr"> RODZINA</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='zzpz' disabled={props.id !== null || !apkForm.apkZycieZdrowie.decyzja} checked={apkForm.apkZycieZdrowie.powazneZachorowania} onChange={(e) => setApkForm({ ...apkForm, apkZycieZdrowie: { ...apkForm.apkZycieZdrowie, powazneZachorowania: e.checked } })} />
                                    <label htmlFor="zzpz"> POWAŻNE ZACHOROWANIA</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='zzosz' disabled={props.id !== null || !apkForm.apkZycieZdrowie.decyzja} checked={apkForm.apkZycieZdrowie.oszczedzanie} onChange={(e) => setApkForm({ ...apkForm, apkZycieZdrowie: { ...apkForm.apkZycieZdrowie, oszczedzanie: e.checked } })} />
                                    <label htmlFor="zzosz"> OSZCZĘDZANIE</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 font-bold border-top-2">
                            Firma
                        </div>
                        <div className="col-2 border-x-1 border-top-2">
                            <SelectButton
                                locked={props.id !== null}
                                value={apkForm.apkFirma.decyzja}
                                onChange={(e) => setApkForm({ ...apkForm, apkFirma: { decyzja: e.value } })}
                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                            />
                        </div>
                        <div className="col-6 border-top-2 p-0">
                            <div className="grid m-0">
                                <div className="col-4 p-1">
                                    <Checkbox id='fog' disabled={props.id !== null || !apkForm.apkFirma.decyzja} checked={apkForm.apkFirma.ogien} onChange={(e) => setApkForm({ ...apkForm, apkFirma: { ...apkForm.apkFirma, ogien: e.checked } })} />
                                    <label htmlFor="fog"> OGIEŃ</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='fkr' disabled={props.id !== null || !apkForm.apkFirma.decyzja} checked={apkForm.apkFirma.kradziez} onChange={(e) => setApkForm({ ...apkForm, apkFirma: { ...apkForm.apkFirma, kradziez: e.checked } })} />
                                    <label htmlFor="fkr"> KRADZIEŻ</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='far' disabled={props.id !== null || !apkForm.apkFirma.decyzja} checked={apkForm.apkFirma.allRisk} onChange={(e) => setApkForm({ ...apkForm, apkFirma: { ...apkForm.apkFirma, allRisk: e.checked } })} />
                                    <label htmlFor="far"> ALL RISK</label>
                                </div>
                            </div>
                            <div className="grid m-0">
                                <div className="col-4 p-1">
                                    <Checkbox id='fod' disabled={props.id !== null || !apkForm.apkFirma.decyzja} checked={apkForm.apkFirma.ocDzialalnosci} onChange={(e) => setApkForm({ ...apkForm, apkFirma: { ...apkForm.apkFirma, ocDzialalnosci: e.checked } })} />
                                    <label htmlFor="fod"> OC DZIAŁALNOŚCI</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='fzl' disabled={props.id !== null || !apkForm.apkFirma.decyzja} checked={apkForm.apkFirma.zdarzeniaLosowe} onChange={(e) => setApkForm({ ...apkForm, apkFirma: { ...apkForm.apkFirma, zdarzeniaLosowe: e.checked } })} />
                                    <label htmlFor="fzl"> ZDARZENIA LOSOWE</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='ff' disabled={props.id !== null || !apkForm.apkFirma.decyzja} checked={apkForm.apkFirma.finansowe} onChange={(e) => setApkForm({ ...apkForm, apkFirma: { ...apkForm.apkFirma, finansowe: e.checked } })} />
                                    <label htmlFor="ff"> FINANSOWE</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 font-bold border-top-2">
                            Rolne
                        </div>
                        <div className="col-2 border-x-1 border-top-2">
                            <SelectButton
                                locked={props.id !== null}
                                value={apkForm.apkRolne.decyzja}
                                onChange={(e) => setApkForm({ ...apkForm, apkRolne: { decyzja: e.value } })}
                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                            />
                        </div>
                        <div className="col-6 border-top-2 p-0">
                            <div className="grid m-0 border-bottom-1">
                                <div className="col-4 p-1">
                                    <Checkbox id='ror' disabled={props.id !== null || !apkForm.apkRolne.decyzja} checked={apkForm.apkRolne.ocRolnika} onChange={(e) => setApkForm({ ...apkForm, apkRolne: { ...apkForm.apkRolne, ocRolnika: e.checked } })} />
                                    <label htmlFor="ror"> OC ROLNIKA</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='rm' disabled={props.id !== null || !apkForm.apkRolne.decyzja} checked={apkForm.apkRolne.mienie} onChange={(e) => setApkForm({ ...apkForm, apkRolne: { ...apkForm.apkRolne, mienie: e.checked } })} />
                                    <label htmlFor="rm"> MIENIE</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='ru' disabled={props.id !== null || !apkForm.apkRolne.decyzja} checked={apkForm.apkRolne.uprawy} onChange={(e) => setApkForm({ ...apkForm, apkRolne: { ...apkForm.apkRolne, uprawy: e.checked } })} />
                                    <label htmlFor="ru"> UPRAWY</label>
                                </div>
                            </div>
                            <div className="grid m-0">
                                <div className="col-4 p-1">
                                    <Checkbox id='rb' disabled={props.id !== null || !apkForm.apkRolne.decyzja} checked={apkForm.apkRolne.budynki} onChange={(e) => setApkForm({ ...apkForm, apkRolne: { ...apkForm.apkRolne, budynki: e.checked } })} />
                                    <label htmlFor="rb"> BUDYNKI</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='rud' disabled={props.id !== null || !apkForm.apkRolne.decyzja} checked={apkForm.apkRolne.ubezpieczenieDobrowolne} onChange={(e) => setApkForm({ ...apkForm, apkRolne: { ...apkForm.apkRolne, ubezpieczenieDobrowolne: e.checked } })} />
                                    <label htmlFor="rud"> UBEZPIECZENIE DOBROWOLNE</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='rzl' disabled={props.id !== null || !apkForm.apkRolne.decyzja} checked={apkForm.apkRolne.zdarzeniaLosowe} onChange={(e) => setApkForm({ ...apkForm, apkRolne: { ...apkForm.apkRolne, zdarzeniaLosowe: e.checked } })} />
                                    <label htmlFor="rzl"> ZDARZENIA LOSOWE</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 font-bold border-top-2">
                            Grupowe
                        </div>
                        <div className="col-2 border-x-1 border-top-2">
                            <SelectButton
                                locked={props.id !== null}
                                value={apkForm.apkGrupowe.decyzja}
                                onChange={(e) => setApkForm({ ...apkForm, apkGrupowe: { decyzja: e.value } })}
                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                            />
                        </div>
                        <div className="col-6 border-top-2 p-0">
                            <div className="grid m-0 border-bottom-1">
                                <div className="col-4 p-1">
                                    <Checkbox id='gip' disabled={props.id !== null || !apkForm.apkGrupowe.decyzja} checked={apkForm.apkGrupowe.iloscPracownikow} onChange={(e) => setApkForm({ ...apkForm, apkGrupowe: { ...apkForm.apkGrupowe, iloscPracownikow: e.checked } })} />
                                    <label htmlFor="gip"> ILOŚĆ PRACOWNIKÓW</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='gsz' disabled={props.id !== null || !apkForm.apkGrupowe.decyzja} checked={apkForm.apkGrupowe.szpital} onChange={(e) => setApkForm({ ...apkForm, apkGrupowe: { ...apkForm.apkGrupowe, szpital: e.checked } })} />
                                    <label htmlFor="gsz"> SZPITAL</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='grd' disabled={props.id !== null || !apkForm.apkGrupowe.decyzja} checked={apkForm.apkGrupowe.rodziceDzieci} onChange={(e) => setApkForm({ ...apkForm, apkGrupowe: { ...apkForm.apkGrupowe, rodziceDzieci: e.checked } })} />
                                    <label htmlFor="grd"> RODZICE/DZIECI</label>
                                </div>
                            </div>
                            <div className="grid m-0">
                                <div className="col-4 p-1">
                                    <Checkbox id='grd' disabled={props.id !== null || !apkForm.apkGrupowe.decyzja} checked={apkForm.apkGrupowe.partnerzy} onChange={(e) => setApkForm({ ...apkForm, apkGrupowe: { ...apkForm.apkGrupowe, partnerzy: e.checked } })} />
                                    <label htmlFor="grd"> PARTNERZY</label>
                                </div>
                                <div className="col-4 p-1 border-x-1">
                                    <Checkbox id='grd' disabled={props.id !== null || !apkForm.apkGrupowe.decyzja} checked={apkForm.apkGrupowe.powazneZachorowania} onChange={(e) => setApkForm({ ...apkForm, apkGrupowe: { ...apkForm.apkGrupowe, powazneZachorowania: e.checked } })} />
                                    <label htmlFor="grd"> POWAŻNE ZACHOROWANIA</label>
                                </div>
                                <div className="col-4 p-1">
                                    <Checkbox id='grd' disabled={props.id !== null || !apkForm.apkGrupowe.decyzja} checked={apkForm.apkGrupowe.dodatki} onChange={(e) => setApkForm({ ...apkForm, apkGrupowe: { ...apkForm.apkGrupowe, dodatki: e.checked } })} />
                                    <label htmlFor="grd"> DODATKI</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 font-bold border-top-2">
                            Pakiety medyczne
                        </div>
                        <div className="col-2 border-x-1 border-top-2">
                            <SelectButton
                                locked={props.id !== null}
                                value={apkForm.apkPakietyMedyczne.decyzja}
                                onChange={(e) => setApkForm({ ...apkForm, apkPakietyMedyczne: { decyzja: e.value } })}
                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                            />
                        </div>
                        <div className="col-6 border-top-2 p-0">
                            <div className="grid m-0 border-bottom-1">
                                <div className="col-6 p-1">
                                    <Checkbox id='pmif' disabled={props.id !== null || !apkForm.apkPakietyMedyczne.decyzja} checked={apkForm.apkPakietyMedyczne.indywidualneFirmowe} onChange={(e) => setApkForm({ ...apkForm, apkPakietyMedyczne: { ...apkForm.apkPakietyMedyczne, indywidualneFirmowe: e.checked } })} />
                                    <label htmlFor="pmif"> INDYWIDUALNE/FIRMOWE</label>
                                </div>
                                <div className="col-6 p-1 border-left-1">
                                    <Checkbox id='pmif' disabled={props.id !== null || !apkForm.apkPakietyMedyczne.decyzja} checked={apkForm.apkPakietyMedyczne.dostepnoscPlacowek} onChange={(e) => setApkForm({ ...apkForm, apkPakietyMedyczne: { ...apkForm.apkPakietyMedyczne, dostepnoscPlacowek: e.checked } })} />
                                    <label htmlFor="pmif"> DOSTĘPNOŚĆ PLACÓWEK</label>
                                </div>
                            </div>
                            <div className="grid m-0">
                                <div className="col-6 p-1">
                                    <Checkbox id='pmif' disabled={props.id !== null || !apkForm.apkPakietyMedyczne.decyzja} checked={apkForm.apkPakietyMedyczne.medycynaPracy} onChange={(e) => setApkForm({ ...apkForm, apkPakietyMedyczne: { ...apkForm.apkPakietyMedyczne, medycynaPracy: e.checked } })} />
                                    <label htmlFor="pmif"> MEDYCYNA PRACY</label>
                                </div>
                                <div className="col-6 p-1 border-left-1">
                                    <Checkbox id='pmif' disabled={props.id !== null || !apkForm.apkPakietyMedyczne.decyzja} checked={apkForm.apkPakietyMedyczne.specjalizacjeMedyczne} onChange={(e) => setApkForm({ ...apkForm, apkPakietyMedyczne: { ...apkForm.apkPakietyMedyczne, specjalizacjeMedyczne: e.checked } })} />
                                    <label htmlFor="pmif"> SPECJALIZACJE MEDYCZNE</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid col-10 my-3 p-2 border-1">
                        <div className="font-bold mr-1">
                            Uwagi klienta:
                        </div>
                        <div className="flex-grow-1">
                            <InputTextarea
                                value={apkForm.apkUwagi}
                                onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUwagi: e.target.value })}
                                className="f-w"
                            />
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-0 border-1">
                        <div className="col-12 font-bold text-center">
                            Czy jest Pan/Pani zainteresowany/a zakupem umowy ubezpieczenia?
                        </div>
                        <div className="grid col-6 border-top-1 border-right-1 m-0">
                            <div className="col text-right">Pojazdu</div>
                            <div className="col">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzZainteresowanie.pojazd}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzZainteresowanie: { ...apkForm.apkUbzZainteresowanie, pojazd: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className="grid col-6 border-top-1 m-0">
                            <div className="col text-right">Majątku/Domu/Mieszkania</div>
                            <div className="col">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzZainteresowanie.majatek}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzZainteresowanie: { ...apkForm.apkUbzZainteresowanie, majatek: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className="grid col-6 border-top-1 border-right-1 m-0">
                            <div className="col text-right">Podróż</div>
                            <div className="col">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzZainteresowanie.podroz}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzZainteresowanie: { ...apkForm.apkUbzZainteresowanie, podroz: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className="grid col-6 border-top-1 m-0">
                            <div className="col text-right">Firmy</div>
                            <div className="col">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzZainteresowanie.firmy}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzZainteresowanie: { ...apkForm.apkUbzZainteresowanie, firmy: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className="grid col-6 border-top-1 border-right-1 m-0">
                            <div className="col text-right">Rolne</div>
                            <div className="col">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzZainteresowanie.rolne}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzZainteresowanie: { ...apkForm.apkUbzZainteresowanie, rolne: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className="grid col-6 border-top-1 m-0">
                            <div className="col text-right">Życiowe</div>
                            <div className="col">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzZainteresowanie.zyciowe}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzZainteresowanie: { ...apkForm.apkUbzZainteresowanie, zyciowe: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className="grid col-12 border-top-1 m-0">
                            <div className="col text-right">Innego (np. zdrowotne, wypadkowe)</div>
                            <div className="col">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzZainteresowanie.inne}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzZainteresowanie: { ...apkForm.apkUbzZainteresowanie, inne: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-0 border-1">
                        <div className="col-12 font-bold text-center">
                            Ubezpieczenie pojazdu
                        </div>
                        <div className="col-12 border-y-1">
                            Jakie ubezpieczenie Pana/Panią interesuje?
                        </div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzPojazdu.oc ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, oc: !apkForm.apkUbzPojazdu.oc } })}>OC</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzPojazdu.ac ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, ac: !apkForm.apkUbzPojazdu.ac } })}>AC</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzPojazdu.nnw ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, nnw: !apkForm.apkUbzPojazdu.nnw } })}>NNW</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzPojazdu.ass ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, ass: !apkForm.apkUbzPojazdu.ass } })}>ASS</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzPojazdu.szyby ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, szyby: !apkForm.apkUbzPojazdu.szyby } })}>SZYBY</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzPojazdu.opony ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, opony: !apkForm.apkUbzPojazdu.opony } })}>OPONY</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzPojazdu.gap ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, gap: !apkForm.apkUbzPojazdu.gap } })}>GAP</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzPojazdu.zk ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, zk: !apkForm.apkUbzPojazdu.zk } })}>ZK</div>
                        <div className={`col text-center ${apkForm.apkUbzPojazdu.ochzn ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, ochzn: !apkForm.apkUbzPojazdu.ochzn } })}>OCH.ZN.</div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Inne:</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzPojazdu.inne} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, inne: e.target.value } })} />
                        </div>
                        <div className='flex col-12 align-items-top border-top-1 p-0'>
                            <div className="border-right-1 col-3 sm:col-2" style={{ minWidth: '100px', maxWidth: '200px' }}>AC</div>
                            <div className="flex-grow-1 col">
                                <div>Czy interesuje Pana/Panią:</div>
                                <div className="grid">
                                    <div className="mt-2" style={{ width: '100px' }}>
                                        <div className="col">
                                            <SelectButton
                                                locked={props.id !== null}
                                                style={{ width: "100px" }}
                                                value={apkForm.apkUbzPojazdu.acOpt1}
                                                onChange={(e) => setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, acOpt1: e.value } })}
                                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                            />
                                        </div>
                                        <div className="col">
                                            <SelectButton
                                                locked={props.id !== null}
                                                style={{ width: "100px" }}
                                                value={apkForm.apkUbzPojazdu.acOpt2}
                                                onChange={(e) => setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, acOpt2: e.value } })}
                                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                            />
                                        </div>
                                        <div className="col">
                                            <SelectButton
                                                locked={props.id !== null}
                                                style={{ width: "100px" }}
                                                value={apkForm.apkUbzPojazdu.acOpt3}
                                                onChange={(e) => setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, acOpt3: e.value } })}
                                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                            />
                                        </div>
                                        <div className="col">
                                            <SelectButton
                                                locked={props.id !== null}
                                                style={{ width: "100px" }}
                                                value={apkForm.apkUbzPojazdu.acOpt4}
                                                onChange={(e) => setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, acOpt4: e.value } })}
                                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="flex align-items-center" style={{ height: '46px' }}>- aby mieć przez cały okres stałą wartość pojazdu</div>
                                        <div className="flex align-items-center" style={{ height: '46px' }}>- aby suma nie była zmniejszana o wypłacone odszkodowanie</div>
                                        <div className="flex align-items-center" style={{ height: '46px' }}>- aby znieść amortyzację części</div>
                                        <div className="flex align-items-center" style={{ height: '46px' }}>- aby naprawić pojazd w ASO/warsztatach partnerskich</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex col-12 align-items-top border-top-1 p-0'>
                            <div className="border-right-1 col-3 sm:col-2" style={{ minWidth: '100px', maxWidth: '200px' }}>NNW</div>
                            <div className="flex-grow-1 col">
                                <div>Jak wysoka suma Pana/Panią interesuje:</div>
                                <div className="flex align-items-center m-1">
                                    <RadioButton inputId="option1" value={1} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, nnwSuma: e.value } })} checked={apkForm.apkUbzPojazdu.nnwSuma === 1} />
                                    <label htmlFor="option1" className="ml-2">5-10 tyś. zł.</label>
                                </div>
                                <div className="flex align-items-center m-1">
                                    <RadioButton inputId="option2" value={2} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, nnwSuma: e.value } })} checked={apkForm.apkUbzPojazdu.nnwSuma === 2} />
                                    <label htmlFor="option2" className="ml-2">10-50 tyś. zł.</label>
                                </div>
                                <div className="flex align-items-center m-1">
                                    <RadioButton inputId="option3" value={3} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, nnwSuma: e.value } })} checked={apkForm.apkUbzPojazdu.nnwSuma === 3} />
                                    <label htmlFor="option3" className="ml-2">powyżej 50 tyś. zł.</label>
                                </div>
                            </div>
                        </div>
                        <div className='flex col-12 align-items-top border-top-1 p-0'>
                            <div className="border-right-1 col-3 sm:col-2" style={{ minWidth: '100px', maxWidth: '200px' }}>ASSISTANCE</div>
                            <div className="flex-grow-1 col">
                                <div>Chy chciałby/a Pan/Pani aby assistance objemował:</div>
                                <div className="grid">
                                    <div className="mt-2" style={{ width: '100px' }}>
                                        <div className="col">
                                            <SelectButton
                                                locked={props.id !== null}
                                                style={{ width: "100px" }}
                                                value={apkForm.apkUbzPojazdu.assOpt1}
                                                onChange={(e) => setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, assOpt1: e.value } })}
                                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                            />
                                        </div>
                                        <div className="col">
                                            <SelectButton
                                                locked={props.id !== null}
                                                style={{ width: "100px" }}
                                                value={apkForm.apkUbzPojazdu.assOpt2}
                                                onChange={(e) => setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, assOpt2: e.value } })}
                                                options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="flex align-items-center" style={{ height: '46px' }}>- holowanie pojazdu za granicą</div>
                                        <div className="flex align-items-center" style={{ height: '46px' }}>- maksymalnie wysoki limit kilometrów holowania</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Czy chciałby/aby Pan/Pani coś dodać?</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzPojazdu.uwagi} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPojazdu: { ...apkForm.apkUbzPojazdu, uwagi: e.target.value } })} />
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-0 border-1">
                        <div className="col-12 font-bold text-center">
                            Ubezpieczenie majątku/domu/mieszkania
                        </div>
                        <div className="col-12 border-y-1">
                            Jakie ryzyka chciałby/aby Pan/Pani, aby obejmowało ubezpieczenie domu/mieszkania?
                        </div>
                        <div className={`col-3 border-right-1 text-center ${apkForm.apkUbzMajatku.allRisk ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, allRisk: !apkForm.apkUbzMajatku.allRisk } })}>ALL RISK</div>
                        <div className={`col-3 border-right-1 text-center ${apkForm.apkUbzMajatku.ogien ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, ogien: !apkForm.apkUbzMajatku.ogien } })}>OGIEŃ</div>
                        <div className={`col-3 border-right-1 text-center ${apkForm.apkUbzMajatku.zdarzeniaLosowe ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, zdarzeniaLosowe: !apkForm.apkUbzMajatku.zdarzeniaLosowe } })}>ZDARZENIA LOSOWE</div>
                        <div className={`col-3 text-center ${apkForm.apkUbzMajatku.cesja ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, cesja: !apkForm.apkUbzMajatku.cesja } })}>CESJA</div>
                        <div className={`col-3 border-top-1 border-right-1 text-center ${apkForm.apkUbzMajatku.kradziez ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, kradziez: !apkForm.apkUbzMajatku.kradziez } })}>KRADZIEŻ</div>
                        <div className={`col-3 border-top-1 border-right-1 text-center ${apkForm.apkUbzMajatku.oc ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, oc: !apkForm.apkUbzMajatku.oc } })}>OC</div>
                        <div className={`col-3 border-top-1 border-right-1 text-center ${apkForm.apkUbzMajatku.elementySzklane ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, elementySzklane: !apkForm.apkUbzMajatku.elementySzklane } })}>ELEMENTY SZKLANE</div>
                        <div className={`col-3 border-top-1 text-center ${apkForm.apkUbzMajatku.dzialalnosc ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, dzialalnosc: !apkForm.apkUbzMajatku.dzialalnosc } })}>DZIAŁALNOŚĆ</div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Inne (np. działania wojenne):</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzMajatku.inne} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, inne: e.target.value } })} />
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Czy chciałby/aby Pan/Pani coś dodać?</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzMajatku.uwagi} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzMajatku: { ...apkForm.apkUbzMajatku, uwagi: e.target.value } })} />
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-0 border-1">
                        <div className="col-12 font-bold text-center">
                            Ubezpieczenie podróży
                        </div>
                        <div className="col-12 border-y-1">
                            <div>Jak wysoką sumę ubezpieczenia kosztów leczenia i assistance jest Pan/Pani zainteresowany/a:</div>
                            <div className="flex align-items-center m-1">
                                <RadioButton inputId="option1" value={1} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, suma: e.value } })} checked={apkForm.apkUbzPodrozy.suma === 1} />
                                <label htmlFor="option1" className="ml-2">do 200 tyś. zł.</label>
                            </div>
                            <div className="flex align-items-center m-1">
                                <RadioButton inputId="option2" value={2} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, suma: e.value } })} checked={apkForm.apkUbzPodrozy.suma === 2} />
                                <label htmlFor="option2" className="ml-2">200-400 tyś. zł.</label>
                            </div>
                            <div className="flex align-items-center m-1">
                                <RadioButton inputId="option3" value={3} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, suma: e.value } })} checked={apkForm.apkUbzPodrozy.suma === 3} />
                                <label htmlFor="option3" className="ml-2">powyżej 400 tyś. zł.</label>
                            </div>

                        </div>
                        <div className={`col-4 border-right-1 text-center ${apkForm.apkUbzPodrozy.euSwiat ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, euSwiat: !apkForm.apkUbzPodrozy.euSwiat } })}>EU/ŚWIAT</div>
                        <div className={`col-4 border-right-1 text-center ${apkForm.apkUbzPodrozy.bagaz ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, bagaz: !apkForm.apkUbzPodrozy.bagaz } })}>BAGAŻ</div>
                        <div className={`col-4 text-center ${apkForm.apkUbzPodrozy.ocNnw ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, ocNnw: !apkForm.apkUbzPodrozy.ocNnw } })}>OC/NNW</div>
                        <div className={`col-4 border-top-1 border-right-1 text-center ${apkForm.apkUbzPodrozy.sport ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, sport: !apkForm.apkUbzPodrozy.sport } })}>SPORT</div>
                        <div className={`col-4 border-top-1 border-right-1 text-center ${apkForm.apkUbzPodrozy.pracaTurystyka ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, pracaTurystyka: !apkForm.apkUbzPodrozy.pracaTurystyka } })}>PRACA/TURYSTYKA</div>
                        <div className={`col-4 border-top-1 text-center ${apkForm.apkUbzPodrozy.choroby ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, choroby: !apkForm.apkUbzPodrozy.choroby } })}>CHOROBY</div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Inne (np. anulowanie biletu/pobytu):</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzPodrozy.inne} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, inne: e.target.value } })} />
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Czy chciałby/aby Pan/Pani coś dodać?</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzPodrozy.uwagi} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzPodrozy: { ...apkForm.apkUbzPodrozy, uwagi: e.target.value } })} />
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-0 border-1">
                        <div className="col-12 font-bold text-center">
                            Ubezpieczenie firmy
                        </div>
                        <div className="col-12 border-y-1">
                            Które obszary działalności Pana/Pani firmy są ważne i wymagają objęcia ochroną?
                        </div>
                        <div className='flex col-12 align-items-top'>
                            <span>Majątek firmy (zabezpieczenie szkód wyrządzonych we własnym sprzęcie): </span>
                            <div className="ml-2">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzFirmy.wlasnySprzet}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, wlasnySprzet: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className={`col border-top-1 border-right-1 text-center ${apkForm.apkUbzFirmy.budynki ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, budynki: !apkForm.apkUbzFirmy.budynki } })}>Budynki/budowle/lokal</div>
                        <div className={`col border-top-1 border-right-1 text-center ${apkForm.apkUbzFirmy.naklady ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, naklady: !apkForm.apkUbzFirmy.naklady } })}>Nakłady inwestycyjne</div>
                        <div className={`col border-top-1 border-right-1 text-center ${apkForm.apkUbzFirmy.maszyny ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, maszyny: !apkForm.apkUbzFirmy.maszyny } })}>Maszyny/urządzenia/wyposażenie</div>
                        <div className={`col border-top-1 border-right-1 text-center ${apkForm.apkUbzFirmy.mienie ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, mienie: !apkForm.apkUbzFirmy.mienie } })}>Mienie osób trzecich</div>
                        <div className={`col border-top-1 text-center ${apkForm.apkUbzFirmy.srodki ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, srodki: !apkForm.apkUbzFirmy.srodki } })}>Środki obrotowe</div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Czy ubezpieczenie ma posiadać możliwe najszerszy zakres, uwzględniać ryzyka kradzieży?</span>
                            <div className="ml-2">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzFirmy.najszerszy}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, najszerszy: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className='col-12 align-items-top border-top-1'>
                            <div>O jakie dodatkowe ryzyka chciałby/aby Pan/Pani dodatkowo rozszerzyć ubezpieczenie?</div>
                            <InputTextarea className="col-12 mt-1" value={apkForm.apkUbzFirmy.dodatki} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, dodatki: e.target.value } })} />
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Odpowiedzialność cywilna:</span>
                            <div className="ml-2">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzFirmy.odpowiedzialnosc}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, odpowiedzialnosc: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className="col-12 border-y-1">
                            Czy interesuje Pana/Panią ubezpieczenie od szkód spowodowanych przez:
                        </div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzFirmy.produkt ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, produkt: !apkForm.apkUbzFirmy.produkt } })}>Produkt/usługę</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzFirmy.choroby ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, choroby: !apkForm.apkUbzFirmy.choroby } })}>Choroby zakaźne</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzFirmy.podwykonawcy ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, podwykonawcy: !apkForm.apkUbzFirmy.podwykonawcy } })}>Podwykonawców</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzFirmy.niedbalstwo ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, niedbalstwo: !apkForm.apkUbzFirmy.niedbalstwo } })}>Rażące niedbalstwo</div>
                        <div className='col-12 align-items-top border-top-1'>
                            <div>O jakie dodatkowe ryzyka chciałby/aby Pan/Pani dodatkowo rozszerzyć ubezpieczenie?</div>
                            <InputTextarea className="col-12 mt-1" value={apkForm.apkUbzFirmy.dodatki2} onChange={(e) => props.id !== null ? setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, dodatki2: e.target.value } }) : null} />
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Czy jest Pan/Pani zainteresowany/a ochroną OC wykonywanego zawodu:</span>
                            <div className="ml-2">
                                <SelectButton
                                    locked={props.id !== null}
                                    style={{ width: "100px" }}
                                    value={apkForm.apkUbzFirmy.ocZawodu}
                                    onChange={(e) => setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, ocZawodu: e.value } })}
                                    options={[{ label: 'TAK', value: true }, { label: "NIE", value: false }]}
                                />
                            </div>
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Jaki zawód Pan/Pani wykonuje?</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzFirmy.zawod} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, zawod: e.target.value } })} />
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Czy chciałby/aby Pan/Pani coś dodać?</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzFirmy.uwagi} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzFirmy: { ...apkForm.apkUbzFirmy, uwagi: e.target.value } })} />
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-0 border-1">
                        <div className="col-12 font-bold text-center">
                            Ubezpieczenie rolne
                        </div>
                        <div className="col-12 border-y-1">
                            Jakim obowiązkowym ubezpieczeniem jest Pan/Pani zainteresowany/a?
                        </div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzRolne.ocRolnika ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, ocRolnika: !apkForm.apkUbzRolne.ocRolnika } })}>OC Rolnika</div>
                        <div className={`col text-center ${apkForm.apkUbzRolne.budynkowRolnych ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, budynkowRolnych: !apkForm.apkUbzRolne.budynkowRolnych } })}>Budynków rolnych</div>
                        <div className="col-12 border-y-1">
                            Jakie inne ryzyka chciałby/aby Pan/Pani objąć ubezpieczeniem?
                        </div>
                        <div className={`col-4 border-right-1 text-center ${apkForm.apkUbzRolne.staleElementy ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, staleElementy: !apkForm.apkUbzRolne.staleElementy } })}>Stałe elementy</div>
                        <div className={`col-4 border-right-1 text-center ${apkForm.apkUbzRolne.kradziez ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, kradziez: !apkForm.apkUbzRolne.kradziez } })}>Kradzież</div>
                        <div className={`col-4 text-center ${apkForm.apkUbzRolne.ruchomosci ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, ruchomosci: !apkForm.apkUbzRolne.ruchomosci } })}>Ruchomości</div>
                        <div className={`col-4 border-top-1 border-right-1 text-center ${apkForm.apkUbzRolne.nnw ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, nnw: !apkForm.apkUbzRolne.nnw } })}>NNW</div>
                        <div className={`col-4 border-top-1 border-right-1 text-center ${apkForm.apkUbzRolne.uprawy ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, uprawy: !apkForm.apkUbzRolne.uprawy } })}>Uprawy</div>
                        <div className={`col-4 border-top-1 text-center ${apkForm.apkUbzRolne.zdarzeniaLosowe ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, zdarzeniaLosowe: !apkForm.apkUbzRolne.zdarzeniaLosowe } })}>Zdarzenia losowe</div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Inne (np. OC w życiu prywatnym, zwierzęta gospodarcze):</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzRolne.inne} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, inne: e.target.value } })} />
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Czy chciałby/aby Pan/Pani coś dodać?</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzRolne.uwagi} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzRolne: { ...apkForm.apkUbzRolne, uwagi: e.target.value } })} />
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-0 border-1">
                        <div className="col-12 font-bold text-center">
                            Ubezpieczenie na życie
                        </div>
                        <div className="col-12 border-y-1">
                            Jakiego rodzaju ubezpieczeniem na życie jest Pan/Pani zainteresowany/a?
                        </div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzZycie.terminowe ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, terminowe: !apkForm.apkUbzZycie.terminowe } })}>Terminowym</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzZycie.bezterminowe ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, bezterminowe: !apkForm.apkUbzZycie.bezterminowe } })}>Bezterminowym</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzZycie.ochronne ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, ochronne: !apkForm.apkUbzZycie.ochronne } })}>Ochronnym</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzZycie.ochInw ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, ochInw: !apkForm.apkUbzZycie.ochInw } })}>Ochronno-inwestycyjnym</div>
                        <div className={`col text-center ${apkForm.apkUbzZycie.inwestycyjne ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, inwestycyjne: !apkForm.apkUbzZycie.inwestycyjne } })}>Inwestycyjnym</div>
                        <div className="col-12 border-y-1">
                            Kogo chciałby/aby Pan/Pani zabezpieczyć?
                        </div>
                        <div className={`col-4 border-right-1 text-center ${apkForm.apkUbzZycie.siebie ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, siebie: !apkForm.apkUbzZycie.siebie } })}>Siebie</div>
                        <div className={`col-4 border-right-1 text-center ${apkForm.apkUbzZycie.bliskich ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, bliskich: !apkForm.apkUbzZycie.bliskich } })}>Swoich bliskich</div>
                        <div className={`col-4 text-center ${apkForm.apkUbzZycie.bank ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, bank: !apkForm.apkUbzZycie.bank } })}>Bank (ubezpieczenie pod cesję)</div>
                        <div className="col-12 border-y-1">
                            Od jakich zdarzeń chciałby/aby Pan/Pani uzyskać zabezpieczenie?
                        </div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzZycie.smierci ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, smierci: !apkForm.apkUbzZycie.smierci } })}>Śmierci</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzZycie.pobytWszpitalu ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, pobytWszpitalu: !apkForm.apkUbzZycie.pobytWszpitalu } })}>Pobytu w szpitalu</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzZycie.powazneZachorowania ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, powazneZachorowania: !apkForm.apkUbzZycie.powazneZachorowania } })}>Poważnego zachorowania</div>
                        <div className={`col border-right-1 text-center ${apkForm.apkUbzZycie.nnw ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, nnw: !apkForm.apkUbzZycie.nnw } })}>NNW</div>
                        <div className={`col text-center ${apkForm.apkUbzZycie.operacji ? 'sb-on' : 'sb-off'}`} onClick={() => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, operacji: !apkForm.apkUbzZycie.operacji } })}>Operacji</div>

                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Inne (np. OC w życiu prywatnym, zwierzęta domowe):</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzZycie.inne} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, inne: e.target.value } })} />
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Czy chciałby/aby Pan/Pani coś dodać?</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzZycie.uwagi} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzZycie: { ...apkForm.apkUbzZycie, uwagi: e.target.value } })} />
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-0 border-1">
                        <div className="col-12 font-bold text-center">
                            Ubezpieczenia na inne
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Od jakich innych sytuacji chciałby/aby Pan/Pani się zabezpieczyć?</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzInne.sytuacje} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzInne: { ...apkForm.apkUbzInne, sytuacje: e.target.value } })} />
                        </div>
                        <div className='flex col-12 align-items-top border-top-1'>
                            <span>Na czym szczególnie Panu/Pani zależy?</span>
                            <InputTextarea className="ml-2 flex-grow-1" value={apkForm.apkUbzInne.szczegolne} onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUbzInne: { ...apkForm.apkUbzInne, szczegolne: e.target.value } })} />
                        </div>
                    </div>
                    <div className="grid col-10 my-3 p-2 border-1">
                        <div className="font-bold mr-1">
                            Uwagi klienta:
                        </div>
                        <div className="flex-grow-1">
                            <InputTextarea
                                value={apkForm.apkUwagi2}
                                onChange={(e) => props.id ? null : setApkForm({ ...apkForm, apkUwagi2: e.target.value })}
                                className="f-w"
                            />
                        </div>
                    </div>
                    <ScrollTop target="parent" threshold={100} className="w-3rem h-3rem p-button mr-4" icon="pi pi-arrow-up text-base" />
                </div >
            </div >
        </>
    )
}