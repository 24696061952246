/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/apk";

export async function getAll() {
    try {
        const promise = await httpService.get(apiEndpoint + "/getAll");
        const { data: response } = promise;
        return response;
    } catch (error) {
        return null;
    }
}

export async function getPackage(params) {
    try {
        const promise = await httpService.get(apiEndpoint + "/getPackage", { params });
        const { data: response } = promise;
        return response;
    } catch (error) {
        return null;
    }
}

export async function getDetails(id) {
    try {
        const promise = await httpService.get(apiEndpoint + "/getDetails/"+id);
        const { data: response } = promise;
        return response;
    } catch (error) {
        return null;
    }
}

export async function createForm(apkForm) {
    try {
        const promise = await httpService.post(apiEndpoint + "/createForm", { params: { apkForm } });
        const { data: response } = promise;
        return response;
    } catch (error) {
        return null;
    }
}

export async function linkPolicy(apkID, polID) {
    try {
        const promise = await httpService.post(apiEndpoint + "/linkPolicy", { params: { apkID, polID } });
        const { data: response } = promise;
        return response;
    } catch (error) {
        return null;
    }
}

export default {
    getAll,
    getPackage,
    getDetails,
    createForm,
    linkPolicy,
};