/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import moment from "moment";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import dictionaryService from "../../services/dictionaryService";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import vehicleService from "../../services/vehicleService";
import { yearsArray } from "../../common/tools";

export default function AddVehicle(props) {

    const [details, setDetails] = useState({
        pojRejestracja: null,
        pojMarkaID: null,
        pojModelID: null,
        pojTyp: null,
        pojRokProd: null,
        pojPierRej: null,
        pojRodzajID: null,
        pojStatusID: null,
        pojVIN: null,
        pojVINNiestandardowy: null,
        pojPojemnosc: null,
        pojMoc: null,
        pojLadownosc: null,
        pojIloMiejsc: null,
        pojDMC: null,
        pojDataBadania: null,
        pojPrzebieg: null
    });
    const [status, setStatus] = useState([]);
    const [kinds, setKinds] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);


    const toast = useRef();

    useEffect(() => {
        const loadDictionary = async () => {
            let response = await dictionaryService.getVehicleStatus();
            setStatus(response);
            response = await dictionaryService.getVehicleKinds();
            setKinds(response);
            response = await dictionaryService.getVehicleBrands();
            setBrands(response);
            response = await dictionaryService.getVehicleModels();
            setModels(response);
        }

        loadDictionary();
    }, [])

    const saveVehicle = async () => {
        if (details.pojRejestracja && details.pojModelID && details.pojMarkaID && details.pojRodzajID && details.pojRokProd) {
            let response = await vehicleService.create(details);
            if (response.status === 0)
                props.close(response.pojID, response.pojRejestracja)
            else
                toast.current.show({ severity: 'warn', summary: "Błąd", detail: 'Nie udało się dodać pojazdu!', closable: true })
        } else {
            let str = 'Brak wymaganych danych: ';
            let c = 0;
            if (!details.pojRejestracja) {
                str = str.concat("numer rejestracyjny");
                c++;
            }
            if (!details.pojMarkaID) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat("marka");
                c++;
            }
            if (!details.pojModelID) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('model');
                c++;
            }
            if (!details.pojRokProd) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('rok produkcji');
                c++;
            }
            if (!details.pojRodzajID) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('rodzaj');
                c++;
            }
            toast.current.show({ severity: 'warn', summary: "Błąd", detail: str, closable: true })
        }
    }

    return (
        <>
            <Toast ref={toast} />

            <div className="grid col-12 m-0 p-0 justify-content-center">
                <div className="col-3 text-right text-red">
                    Nr rejestracyjny:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter="alphanum"
                        value={details.pojRejestracja}
                        onChange={(e) => {
                            details.pojRejestracja = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Marka:
                </div>
                <div className="col-3 min-h-3rem">
                    <Dropdown
                        value={details.pojMarkaID}
                        options={brands}
                        onChange={(e) => {
                            details.pojMarkaID = e.value;
                            details.pojModelID = null;
                            setDetails({ ...details });
                        }}
                        filter
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Model:
                </div>
                <div className="col-3 min-h-3rem">
                    <Dropdown
                        value={details.pojModelID}
                        options={details.pojMarkaID ? models.filter(m => m.brand === details.pojMarkaID) : models}
                        onChange={(e) => {
                            if (details.pojMarkaID === null) {
                                details.pojMarkaID = models.find(m => m.value === e.value).brand
                            }
                            details.pojModelID = e.value;
                            setDetails({ ...details });
                        }}
                        filter
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Typ:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter={/[^^[\]!@#$%*()&"':=+{};<>?\\/|]/}
                        value={details.pojTyp ? details.pojTyp : ""}
                        onChange={(e) => {
                            details.pojTyp = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Rok produkcji:
                </div>
                <div className="col-3 min-h-3rem">
                    <Dropdown
                        value={details.pojRokProd}
                        options={yearsArray()}
                        onChange={(e) => {
                            details.pojRokProd = e.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Pierwsza rej.:
                </div>
                <div className="col-3 min-h-3rem">
                    <Calendar
                        value={details.pojPierRej ? moment(details.pojPierRej).toDate() : ""}
                        dateFormat="dd/mm/yy"
                        locale="pl"
                        onChange={(e) => {
                            details.pojPierRej = moment(e.value).format();
                            setDetails({ ...details });
                        }}
                        monthNavigator
                        yearNavigator
                        yearRange={"1900:2030"}
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Rodzaj:
                </div>
                <div className="col-3 min-h-3rem">
                    <Dropdown
                        value={details.pojRodzajID}
                        options={kinds}
                        onChange={(e) => {
                            details.pojRodzajID = e.value;
                            setDetails({ ...details });
                        }}
                        filter
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Status:
                </div>
                <div className="col-3 min-h-3rem">
                    <Dropdown
                        value={details.pojStatusID}
                        options={status}
                        onChange={(e) => {
                            details.pojStatusID = e.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Nr VIN:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter={/[A-HJ-NPR-Z0-9]/}
                        value={details.pojVIN}
                        onChange={(e) => {
                            details.pojVIN = e.target.value;
                            setDetails({ ...details });
                        }}
                        maxLength="17"
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Niestandardowy VIN:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter="alphanum"
                        value={details.pojVINNiestandardowy}
                        onChange={(e) => {
                            details.pojVINNiestandardowy = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Pojemność:
                </div>
                <div className="col-3 min-h-3rem">
                    <div className="p-inputgroup">
                        <InputText
                            keyfilter="num"
                            value={details.pojPojemnosc}
                            onChange={(e) => {
                                details.pojPojemnosc = e.target.value;
                                setDetails({ ...details });
                            }}
                            className="f-w"
                        />
                        <span className="p-inputgroup-addon">cm<sup>3</sup></span>
                    </div>
                </div>
                <div className="col-3 text-right text-red">
                    Moc:
                </div>
                <div className="col-3 min-h-3rem">
                    <div className="p-inputgroup">
                        <InputText
                            keyfilter="num"
                            value={details.pojMoc}
                            onChange={(e) => {
                                details.pojMoc = e.target.value;
                                setDetails({ ...details });
                            }}
                            className="f-w"
                        />
                        <span className="p-inputgroup-addon">KW</span>
                    </div>
                </div>
                <div className="col-3 text-right text-red">
                    Ładowność:
                </div>
                <div className="col-3 min-h-3rem">
                    <div className="p-inputgroup">
                        <InputText
                            keyfilter="num"
                            value={details.pojLadownosc}
                            onChange={(e) => {
                                details.pojLadownosc = e.target.value;
                                setDetails({ ...details });
                            }}
                            className="f-w"
                        />
                        <span className="p-inputgroup-addon">kg</span>
                    </div>
                </div>
                <div className="col-3 text-right text-red">
                    DMC:
                </div>
                <div className="col-3 min-h-3rem">
                    <div className="p-inputgroup">
                        <InputText
                            keyfilter="num"
                            value={details.pojDMC}
                            onChange={(e) => {
                                details.pojDMC = e.target.value;
                                setDetails({ ...details });
                            }}
                            className="f-w"
                        />
                        <span className="p-inputgroup-addon">kg</span>
                    </div>
                </div>
                <div className="col-3 text-right text-red">
                    Ilość miejsc:
                </div>
                <div className="col-3 min-h-3rem">
                    <InputText
                        keyfilter="num"
                        value={details.pojIloMiejsc}
                        onChange={(e) => {
                            details.pojIloMiejsc = e.target.value;
                            setDetails({ ...details });
                        }}
                        className="f-w"
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Data badania:
                </div>
                <div className="col-3 min-h-3rem">
                    <Calendar
                        value={details.pojDataBadania ? moment(details.pojDataBadania).toDate() : ""}
                        dateFormat="dd/mm/yy"
                        locale="pl"
                        onChange={(e) => {
                            details.pojDataBadania = e.value;
                            setDetails({ ...details });
                        }}
                        monthNavigator
                        yearNavigator
                        yearRange={"1900:2030"}
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Przebieg:
                </div>
                <div className="col-3 min-h-3rem">
                    <div className="p-inputgroup">
                        <InputText
                            keyfilter="num"
                            value={details.pojPrzebieg}
                            onChange={(e) => {
                                details.pojPrzebieg = e.target.value;
                                setDetails({ ...details });
                            }}
                            className="f-w"
                        />
                        <span className="p-inputgroup-addon">km</span>
                    </div>
                </div>
                <div className="col-6"></div>


            </div>

            <div className="grid col-12 mt-3 justify-content-center">
                <Button
                    className="odf-shine"
                    label="Zapisz"
                    icon="pi pi-fw pi-save"
                    onClick={() => saveVehicle()}
                />
            </div>
        </>
    )
}