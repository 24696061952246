/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/dictionary";

export async function getTUs(wpa) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getTUs", { params: { wpa } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getAgentTUs(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAgentTUs", { params: { id } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPolicyStatus() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPolicyStatus");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPolicyTypes() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPolicyTypes");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getClientStatus() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getClientStatus");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getClientTypes() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getClientTypes");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getIDTypes() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getIDTypes");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getStreetTypes() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getStreetTypes");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getAddressTypes() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAddressTypes");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getVehicleBrands() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getVehicleBrands");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getVehicleModels() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getVehicleModels");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getVehicleKinds() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getVehicleKinds");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getVehicleStatus() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getVehicleStatus");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getObjectTypes() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getObjectTypes");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getCurrencies() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getCurrencies");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getSeries() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getSeries");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getClientUbzTypes() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getClientUbzTypes");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getRiskTypes() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getRiskTypes");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getBms() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getBms");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getInkasoStatus() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getInkasoStatus");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPaymentForms() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPaymentForms");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getAddPrintSeries() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAddPrintSeries");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getDocKinds() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getDocKinds");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getAddPrintStatus() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAddPrintStatus");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export default {
  getTUs,
  getAgentTUs,
  getPolicyStatus,
  getPolicyTypes,
  getClientStatus,
  getClientTypes,
  getIDTypes,
  getStreetTypes,
  getAddressTypes,
  getVehicleBrands,
  getVehicleModels,
  getVehicleKinds,
  getVehicleStatus,
  getObjectTypes,
  getCurrencies,
  getSeries,
  getClientUbzTypes,
  getRiskTypes,
  getBms,
  getInkasoStatus,
  getPaymentForms,
  getAddPrintSeries,
  getDocKinds,
  getAddPrintStatus
};
