/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import moment from "moment";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";

import { Toast } from "primereact/toast";
import userService from "../../services/userService";
import { Button } from "primereact/button";
import clientService from "../../services/clientService";
import dictionaryService from "../../services/dictionaryService";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import ClientAddress from "./ClientAddressDetails";
import policyService from "../../services/policyService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PolicyDetails from "./PolicyDetails";

export default function ClientDetails(props) {

    const [details, setDetails] = useState({});
    const [policies, setPolicies] = useState({});
    const [creator, setCreator] = useState();
    const [modifier, setModifier] = useState();
    const [changes, setChanges] = useState(false);
    const [status, setStatus] = useState([]);
    const [types, setTypes] = useState([]);
    const [idTypes, setIDTypes] = useState([]);
    const [addressVisible, setAddressVisible] = useState(false);
    const [ubzs, setUbzs] = useState([]);
    const [policyStatus, setPolicyStatus] = useState([]);
    const [policyDetails, setPolicyDetails] = useState({ polID: null, polNumerTxt: "" });
    const [loading, setLoading] = useState(true);


    const toast = useRef();

    const ynArr = [{ label: "Tak", value: 1 }, { label: "Nie", value: 0 }];

    useEffect(() => {

        const loadDictionary = async () => {
            let response = await dictionaryService.getClientStatus();
            setStatus(response);
            response = await dictionaryService.getClientTypes();
            setTypes(response);
            response = await dictionaryService.getIDTypes();
            setIDTypes(response);
            response = await dictionaryService.getTUs();
            setUbzs(response);
            response = await dictionaryService.getPolicyStatus();
            setPolicyStatus(response)
        }

        loadDictionary();
        fetchData();
    }, [])

    const fetchData = async () => {
        let response = await clientService.getDetails(props.id);
        if (response.kliNiechciany === null) response.kliNiechciany = 0;
        if (response.kliZgoda === null) response.kliZgoda = 0;
        if (response.kliZgodaR === null) response.kliZgodaR = 0;
        if (response.kliBrakZgodyTE === null) response.kliBrakZgodyTE = 0;

        setDetails(response);
        let creatorRes = await userService.getByID(response.kliUtworzyl);
        setCreator(creatorRes);
        if (response.kliModyfik) {
            let modRes = await userService.getByID(response.kliModyfik);
            setModifier(modRes);
        }

        response = await policyService.getClientPolicies(response.kliID);
        setPolicies(response);
        setLoading(false);
    };

    const saveChanges = async () => {
        let response = await clientService.edit(details);
        if (response) {
            props.success()
            props.close();
        } else {
            toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Nie udało się zapisać zmian.', life: 5000, closable: true });
        }
    }

    const birthday = () => {
        if (moment(details.kliDataUr).format('DDMM') === moment().format('DDMM'))
            return <i className="pi pi-fw pi-bell bd-bell" />
        return null
    }

    return (
        <>
            <Toast ref={toast} />

            <div className="grid col-12 m-0 p-0 justify-content-center">
                <div className="col-3 text-right text-red">
                    Imię:
                </div>
                <div className="col-9 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliImie || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter="alpha"
                                value={details.kliImie}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliImie = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Nazwisko:
                </div>
                <div className="col-9 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliNazwisko || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter="alpha"
                                value={details.kliNazwisko}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliNazwisko = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Nazwa:
                </div>
                <div className="col-9 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliNazwa || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[^^[\]!@#$%*()=+{};<>?\\/|]/}
                                value={details.kliNazwa}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliNazwa = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
            </div>

            <div className="grid col-12 m-0 p-0 justify-content-center">
                <div className="grid col-4 m-0">
                    <div className="col-6 text-right text-red">
                        Drugie imię:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {details.kliImie2 || "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <InputText
                                    keyfilter={'alpha'}
                                    value={details.kliImie2}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliImie2 = e.target.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                    <div className="col-6 text-right text-red">
                        PESEL:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {details.kliPESEL || "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <InputMask
                                    mask="99999999999"
                                    value={details.kliPESEL}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliPESEL = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                    <div className="col-6 text-right text-red">
                        Status:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {status.length && details.kliStatusID ? status.find(s => s.value === details.kliStatusID).label : "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <Dropdown
                                    value={details.kliStatusID}
                                    options={status}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliStatusID = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                    <div className="col-6 text-right text-red">
                        Telefon:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {details.kliTel || "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <InputMask
                                    mask="99-999-99-99"
                                    value={details.kliTel}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliTel = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                </div>
                <div className="grid col-4 m-0">
                    <div className="col-6 text-right text-red">
                        Data urodzenia:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {birthday()}{details.kliDataUr ? moment(details.kliDataUr).format('DD/MM/yy') : "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <Calendar
                                    value={details.kliDataUr ? moment(details.kliDataUr).toDate() : moment().toDate()}
                                    dateFormat="dd/mm/yy"
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliDataUr = moment(e.value).format();
                                        setDetails({ ...details });
                                    }}
                                    monthNavigator
                                    yearNavigator
                                    yearRange={"1900:" + moment().format("YYYY")}
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                    <div className="col-6 text-right text-red">
                        NIP:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {details.kliNIP || "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <InputMask
                                    mask="9999999999"
                                    value={details.kliNIP}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliNIP = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                    <div className="col-6 text-right text-red">
                        Typ:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {types.length && details.kliTypID ? types.find(t => t.value === details.kliTypID).label : "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <Dropdown
                                    value={details.kliTypID}
                                    options={types}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliTypID = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                    <div className="col-6 text-right text-red">
                        Tel. kom.:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {details.kliMobil || "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <InputMask
                                    mask="999-999-999"
                                    value={details.kliMobil}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliMobil = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>

                </div>
                <div className="grid col-4">
                    <div className="col-6 text-right text-red">
                        Płeć:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {details.kliPlec === 1 ? "Mężczyzna" : details.kliPlec === 0 ? "Kobieta" : "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <Dropdown
                                    value={details.kliPlec}
                                    options={[{ label: "Brak danych", value: null }, { label: "Kobieta", value: 0 }, { label: "Mężczyzna", value: 1 }]}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliPlec = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                    <div className="col-6 text-right text-red">
                        REGON:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {details.kliREGON || "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <InputMask
                                    mask="999999999"
                                    value={details.kliREGON}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliREGON = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                    <div className="col-6 text-right text-red">
                        Niechciany:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {details.kliNiechciany ? "Tak" : "Nie"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <Dropdown
                                    value={details.kliNiechciany}
                                    options={ynArr}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliNiechciany = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>
                    <div className="col-6 text-right text-red">
                        Tel. kom. 2:
                    </div>
                    <div className="col-6 min-h-3rem">
                        <Inplace>
                            <InplaceDisplay>
                                {details.kliMobil2 || "Brak"}
                            </InplaceDisplay>
                            <InplaceContent>
                                <InputMask
                                    mask="999-999-999"
                                    value={details.kliMobil2}
                                    onChange={(e) => {
                                        setChanges(true);
                                        details.kliMobil2 = e.value;
                                        setDetails({ ...details });
                                    }}
                                    className="f-w"
                                />
                            </InplaceContent>
                        </Inplace>
                    </div>

                </div>
            </div>
            <div className="grid col-12 m-0 p-0 justify-content-center">
                <div className="col-3 text-right text-red">
                    E-mail:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliEMail || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                value={details.kliEMail}
                                keyfilter="email"
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliEMail = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Adres:
                </div>
                <div className="col-3 min-h-3rem">
                    <Button
                        label="Podgląd"
                        icon="pi pi-fw pi-search"
                        onClick={() => setAddressVisible(true)}
                    />
                </div>
                <div className="col-3 text-right text-red">
                    Typ dowodu:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {idTypes.length && details.kliDowodID ? idTypes.find(t => t.value === details.kliDowodID).label : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.kliDowodID}
                                options={idTypes}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliDowodID = e.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Nr dowodu:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliDowodNr || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter="alphanum"
                                value={details.kliDowodNr}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliDowodNr = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Zgoda:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliZgoda ? "Tak" : "Nie"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.kliZgoda}
                                options={ynArr}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliZgoda = e.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Data zgody:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliZgData ? moment(details.kliZgData).format('DD/MM/yy') : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Calendar
                                value={details.kliZgData ? moment(details.kliZgData).toDate() : moment().toDate()}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliZgData = moment(e.value).format();
                                    setDetails({ ...details });
                                }}
                                monthNavigator
                                yearNavigator
                                yearRange={"1900:" + moment().format("YYYY")}
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Zgoda R:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliZgodaR ? "Tak" : "Nie"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.kliZgodaR}
                                options={ynArr}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliZgodaR = e.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Data zgody R:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliZgRData ? moment(details.kliZgRData).format('DD/MM/yy') : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Calendar
                                value={details.kliZgRData ? moment(details.kliZgRData).toDate() : moment().toDate()}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliZgRData = moment(e.value).format();
                                    setDetails({ ...details });
                                }}
                                monthNavigator
                                yearNavigator
                                yearRange={"1900:" + moment().format("YYYY")}
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Brak zgody TE:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliBrakZgodyTE ? "Tak" : "Nie"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.kliZgodaR}
                                options={ynArr}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliZgodaR = e.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Data Pr:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliDataPr ? moment(details.kliDataPr).format('DD/MM/yy') : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Calendar
                                value={details.kliDataPr ? moment(details.kliDataPr).toDate() : moment().toDate()}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliDataPr = moment(e.value).format();
                                    setDetails({ ...details });
                                }}
                                monthNavigator
                                yearNavigator
                                yearRange={"1900:" + moment().format("YYYY")}
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Komentarz:
                </div>
                <div className="col-9 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliKomentarz || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                value={details.kliKomentarz}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.kliKomentarz = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Utworzył:
                </div>
                <div className="col-3 h-5rem">
                    {creator ? creator.usNazw + " " + creator.usImie : ""}
                    <br />
                    {moment(details.kliDataUt).format("DD/MM/yy HH:mm")}
                </div>
                {modifier ?
                    <>
                        <div className="col-3 text-right text-red">
                            Modyfikował:
                        </div>
                        <div className="col-3">
                            {modifier.usNazw + " " + modifier.usImie}
                            <br />
                            {moment(details.kliDataMod).format("DD/MM/yy HH:mm")}
                        </div>
                    </>
                    :
                    <div className="col-6 h-5rem">

                    </div>
                }
            </div>

            {
                changes ?
                    <div className="grid col-12 mt-3 justify-content-center">
                        <Button
                            className="odf-shine"
                            label="Zapisz"
                            icon="pi pi-fw pi-save"
                            onClick={() => saveChanges()}
                        />
                    </div>
                    :
                    null
            }

            <div className="grid col-12 justify-content-center">
                <div className='ml-1 mr-1 mt-3 table-header'>
                    Polisy
                </div>
                <DataTable
                    className='ml-1 mr-1 mb-3 f-w'
                    value={policies}
                    loading={loading}
                    //paginator
                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                    dataKey="polID"
                    responsiveLayout='scroll'
                    emptyMessage="Brak wyników"
                    onRowDoubleClick={(e) => setPolicyDetails({ polID: e.data.polID, polNumerTxt: e.data.polNumerTxt })}
                >
                    <Column field="polNumerTxt" header="Numer" sortable />
                    <Column field="polDataZaw" header="Data zawarcia" sortable body={(rowData) => { return rowData.polDataZaw ? moment(rowData.polDataZaw).format('DD/MM/yy') : "Brak" }} />
                    <Column field="polDataOd" header="Data od" sortable body={(rowData) => { return rowData.polDataOd ? moment(rowData.polDataOd).format('DD/MM/yy') : "Brak" }} />
                    <Column field="polDataDo" header="Data do" sortable body={(rowData) => { return rowData.polDataDo ? moment(rowData.polDataDo).format('DD/MM/yy') : "Brak" }} />
                    <Column style={{ width: "15%" }} field="polUbzID" header="Kod TU" sortable body={(rowData) => ubzs.length && rowData.polUbzID ? ubzs.find(u => u.value === rowData.polUbzID).label : "Brak"} />
                    <Column style={{ width: "15%" }} field="polStatusID" header="Status" sortable body={(rowData) => policyStatus.length && rowData.polStatusID ? policyStatus.find(s => s.value === rowData.polStatusID).label : "Brak"} />
                </DataTable>
            </div>

            <Dialog
                visible={addressVisible}
                header={"Adres - " + (details.kliNazwa || (details.kliImie + " " + details.kliNazwisko))}
                onHide={() => setAddressVisible(false)}
                dismissableMask
                className="col-8"
            >
                <ClientAddress
                    clientID={details.kliID}
                    close={() => setAddressVisible(false)}
                    showSuccessToast={() => toast.current.show({ severity: 'success', summary: 'Pomyślnie zapisano zmiany!', closable: true })
                    }
                />
            </Dialog>

            <Dialog
                visible={policyDetails.polID !== null}
                header={"Polisa " + policyDetails.polNumerTxt}
                onHide={() => setPolicyDetails({ polID: null, polNumerTxt: "" })}
                dismissableMask
                maximizable
                className='col-8'
            >
                <PolicyDetails
                    id={policyDetails.polID}
                    close={() => setPolicyDetails({ polID: null, polNumerTxt: "" })}
                    success={() => { toast.current.show({ severity: 'success', summary: 'Pomyślnie zapisano zmiany!', closable: true }); fetchData() }
                    }
                />
            </Dialog>
        </>
    )
}