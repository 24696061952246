import moment from "moment";
import { Image } from "primereact/image";
import { useEffect, useState } from "react"
import newsService from "../../services/newsService";
import { Carousel } from "primereact/carousel";
import { Card } from 'primereact/card';
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import emptyImg from '../../assets/empty-image.jpg'


export default function News(props) {

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate();


    useEffect(() => {
        let fetchData = async () => {
            let response = await newsService.getPackage(null, null, true);
            setNews(response);
            setLoading(false);
        }
        fetchData();
    }, [])

    const articleTemplate = (article) => {
        return <Card
            className="ml-2 mr-2 article-card"
            header={
                <Image
                    src={article.mainImgBase64 || emptyImg}
                    alt={article.mainImgTitle}
                    imageStyle={{ height: '300px' }}
                />
            }
            title={<div className='flex align-items-center col-12 font-bold text-2xl' style={{ height: '100px' }}>{article.akNaglowek}</div>}
            onClick={() => navigate('/newsdetails', { state: { article } })}
            footer={
                <div className="align-items-center font-italic  align-content-end" style={{ borderTop: '1px solid #C8C8C8', }}>
                    <div>{moment(article.akWidocznyOd).format('DD/MM/YYYY')}</div>
                    <div>{article.uusImie + ' ' + article.uusNazw}</div>
                </div>
            }
        >
        </Card >
    }

    return (
        <div>
            {
                loading ?
                    <div className="grid col-12 justify-content-center">
                        <ProgressSpinner />
                    </div>
                    :
                    news.length === 0 ?
                        <div className="grid justify-content-center m-5 p-4" style={{ backgroundColor: 'white', borderRadius: '15px', border: '1px solid hsl(350, 68%, 44%)' }}>
                            <div className="col text-center text-2xl m-5">
                                Brak aktualności do wyświetlenia...
                            </div>
                        </div>
                        :
                        news.length <= 3 ?
                            <div className="grid justify-content-center m-5">
                                {news.map((n, idx) => <div key={idx} style={{ width: '450px' }}>{articleTemplate(n)}</div>)}
                            </div>
                            :
                            <Carousel value={news} numScroll={1} numVisible={3} circular itemTemplate={articleTemplate} autoplayInterval={5000} className="col-12 mt-2" style={{ maxHeight: '76vh' }} />
            }
        </div>
    )
}