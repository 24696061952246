import moment from "moment";
import { Image } from "primereact/image";
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import newsService from "../../services/newsService";


export default function NewsDetails(props) {

    const [article, setArticle] = useState({ akWidoczny: true, akWidocznyOd: moment().format(), akTresc: "", akNaglowek: "" });
    const [mainImage, setMainImage] = useState({});
    //const [images, setImages] = useState([]);

    const { state } = useLocation();

    useEffect(() => {
        const fetchData = async (akID) => {
            let response = await newsService.getDetails(akID);

            if (response) {
                setArticle(response.article);
                setMainImage(response.mainImage);
            }
        }

        fetchData(state.article.akID);
    }, [state])


    return (
        <div>
            <div className="grid justify-content-center m-3 pt-3 pb-3" style={{ backgroundColor: 'white', borderRadius: '15px', border: '1px solid rgb(161, 31, 31)', }}>
                {
                    mainImage.base64 ?
                        <div className="grid justify-content-center col-12">
                            <Image
                                src={mainImage.base64}
                                alt={mainImage.name}
                                imageStyle={{ maxHeight: '60vh', maxWidth: '80vw' }}
                            />
                        </div>
                        :
                        null
                }
                <div className="col-10 sm:col-8 md:col-6 text-center font-bold text-4xl mb-3">
                    {article.akNaglowek}
                </div>
                <div className="col-10" style={{ borderTop: '2px solid #C8C8C8', borderBottom: '2px solid #C8C8C8' }}>
                    <div style={{ width: '100%', minHeight: '100px' }} dangerouslySetInnerHTML={{ __html: article.akTresc }} />
                </div>
                <div className="grid col-10 font-italic justify-content-center">
                    <div className="m-2">
                        <label className="font-bold">Autor :</label> {article.uusImie + ' ' + article.uusNazw}
                    </div>
                    <div className="m-2">
                        <label className="font-bold">Data publikacji :</label> {moment(article.akWidocznyOd || article.akDataUt).format('DD/MM/YYYY HH:mm')}
                    </div>
                    {
                        article.musImie ?
                            <>
                                <div className="m-2">
                                    <label className="font-bold">Modyfikował :</label> {article.musImie + ' ' + article.musNazw}
                                </div>
                                <div className="m-2">
                                    <label className="font-bold">Data modyfikacji :</label> {moment(article.akDataMod).format('DD/MM/YYYY HH:mm')}
                                </div>
                            </>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    )
}