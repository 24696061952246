import logo from "../assets/logo-odf.png";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { authenticate } from "../services/authService";
import { Toast } from 'primereact/toast';


export default function LoginPanel() {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [logAttempt, setlogAttempt] = useState(false);
    const [logError, setLogError] = useState(false);

    const toast = useRef(null);

    const signIn = async () => {
        setlogAttempt(true);
        if (login && password) {
            let result = await authenticate(login, password);
            if (result) {
                window.location.reload();
            } else {
                toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Nieprawidłowe dane logowania!',  closable: true });
                setLogError(true);
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Oba pola są wymagane.',  closable: true });
        }
    }

    useEffect(() => {
        if (localStorage.getItem("jwtExpired")) {
            toast.current.show({ severity: 'info', summary: 'Wylogowano!', detail: 'Twoja sesja wygasła.', sticky: true, closable: true });
        }
    }, []);

    return (
        <div>
            <Toast ref={toast} />
            <div className="grid align-content-center fh">
                <div className="grid col-6 justify-content-center">
                    <div className="blue-box col-10 md:col-8 lg:col-6 text-white text-center text-4xl p-3">
                        Logowanie
                        <div className="text-xl mt-5">
                            Login:
                            <InputText
                                className={logError || (logAttempt && !login) ? "error-box ml-3" : "ml-3"}
                                value={login}
                                onChange={(event) => {setLogin(event.target.value); setLogError(false)}}
                                onKeyDown={(event) => event.key === 'Enter' ? signIn() : null}
                                inputclassname="col-12"
                            />
                        </div>
                        <div className="text-xl mt-5">
                            Hasło:
                            <Password
                                className="ml-3"
                                inputclassname={logError || (logAttempt && !password)  ? "error-box" : ""}
                                value={password}
                                onChange={(event) => {setPassword(event.target.value); setLogError(false)}}
                                onKeyDown={(event) => event.key === 'Enter' ? signIn() : null}
                                feedback={false}
                            />
                        </div>
                        <div>
                            <Button
                                className="odf-shine mt-5"
                                label="Zaloguj"
                                icon="pi pi-fw pi-sign-in"
                                onClick={() => signIn()}
                            />
                        </div>
                        <Link className="text-lg text-white" to="/">Nie pamiętasz hasła?</Link>
                    </div>
                </div>
                <div className="grid col-6 justify-content-center align-content-center">
                    <img src={logo} alt="ODF" />
                </div>
            </div>
        </div>
    )
}