import { useEffect } from "react";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import { useState } from "react";
import dictionaryService from "../../services/dictionaryService";
import userService from "../../services/userService";
import moment from "moment";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import policyService from "../../services/policyService";
import { Dialog } from "primereact/dialog";
import AddAdditionalPrint from "../adders/AddAdditionalPrint";


export default function PolicyDetails(props) {

    const [policyTypes, setPolicyTypes] = useState([]);
    const [policyStatus, setPolicyStatus] = useState([]);
    const [currencies, setCurrencies] = useState([{ value: 1, label: "PLN" }]);
    const [ubzs, setUbzs] = useState([]);
    const [agents, setAgents] = useState([]);
    const [series, setSeries] = useState([]);
    const [clientUbzTypes, setClientUbzTypes] = useState([]);
    const [riskTypes, setRiskTypes] = useState([]);
    const [inkasoStatus, setInkasoStatus] = useState([]);
    const [paymentForms, setPaymentForms] = useState([]);
    const [vehicleBrands, setVehicleBrands] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [vehicleKinds, setVehicleKinds] = useState([]);
    const [vehicleStatus, setVehicleStatus] = useState([]);
    const [insObjTypes, setInsObjTypes] = useState([]);
    const [addPrintSeries, setAddPrintSeries] = useState([])


    const [policy, setPolicy] = useState({});
    const [policyUsers, setPolicyUsers] = useState([]);
    const [risks, setRisks] = useState([]);
    const [instalments, setInstalments] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [otherObjs, setOtherObjs] = useState([]);
    const [additionalPrints, setAdditionalPrints] = useState([]);
    const [inkaso, setInkaso] = useState([]);
    const [remarks, setRemarks] = useState([]);
    const [otherPrints, setOtherPrints] = useState([]);

    const [loading, setLoading] = useState(true);
    const [remarksVisible, setRemarksVisible] = useState(false);
    const [addPrintsVisible, setAddPrintsVisible] = useState(false);

    const [selectedPrint, setSelectedPrint] = useState(null);

    const toast = useRef(null);


    useEffect(() => {
        const fetchData = async () => {
            let response = await dictionaryService.getPolicyTypes();
            setPolicyTypes(response);
            response = await dictionaryService.getPolicyStatus();
            setPolicyStatus(response);
            response = await dictionaryService.getCurrencies();
            setCurrencies(response);
            response = await dictionaryService.getTUs();
            setUbzs(response);
            response = await userService.getList();
            setAgents(response);
            response = await dictionaryService.getSeries();
            setSeries(response);
            response = await dictionaryService.getClientUbzTypes();
            setClientUbzTypes(response);
            response = await dictionaryService.getRiskTypes();
            setRiskTypes(response);
            response = await dictionaryService.getInkasoStatus();
            setInkasoStatus(response);
            response = await dictionaryService.getPaymentForms();
            setPaymentForms(response);
            response = await dictionaryService.getVehicleBrands();
            setVehicleBrands(response);
            response = await dictionaryService.getVehicleModels();
            setVehicleModels(response);
            response = await dictionaryService.getVehicleKinds();
            setVehicleKinds(response);
            response = await dictionaryService.getVehicleStatus();
            setVehicleStatus(response);
            response = await dictionaryService.getObjectTypes();
            setInsObjTypes(response);
            response = await dictionaryService.getAddPrintSeries();
            setAddPrintSeries(response);
            response = await policyService.getPolicyDocs(props.id);
            setOtherPrints(response);
            response = await policyService.getDetails(props.id);
            setPolicy(response);
            response = await policyService.getPolicyAdditionalData(props.id);
            setAdditionalPrints(response.additionalPrints);
            setPolicyUsers(response.policyUsers);
            setRisks(response.risks);
            setInstalments(response.instalments);
            setInkaso(response.inkaso);
            setVehicles(response.vehicles);
            setOtherObjs(response.otherObjs);
            setRemarks(response.remarks);
            setLoading(false);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <Toast ref={toast} />
            {loading ?
                <div className="grid col-12 justify-content-center">
                    <div className="loader"></div>
                    <div className="col-12 text-center">Ładuję dane...</div>
                </div>
                :
                <div className="p-3">
                    <Fieldset
                        legend="Klient / Osoby powiązane"
                    >
                        <div className="grid col-12">
                            <div className="col">
                                <DataTable
                                    value={policyUsers}
                                    dataKey="klient_kliID"
                                    responsiveLayout='scroll'
                                    emptyMessage="Brak wyników"
                                >
                                    <Column header="Typ" field="pkTypKl" body={(rowData) => { return clientUbzTypes.find(t => t.value === rowData.pkTypKl) ? clientUbzTypes.find(t => t.value === rowData.pkTypKl).label : "" }} />
                                    <Column header="Nazwa" field="pkNazwa" />
                                    <Column header="Adres" field="pkAdres" />
                                    <Column header="Miasto" field="pkMiasto" />
                                    <Column header="Telefon" field="pkTelefon" />
                                </DataTable>
                            </div>
                        </div>
                    </Fieldset>
                    <Fieldset
                        legend="Polisa"
                        className="my-3"
                    >
                        <div className="col-12">
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Typ polisy:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={policyTypes}
                                        value={policy.polTypID}
                                        emptyMessage="Brak wyników"
                                    />
                                </div>
                            </div>
                            {
                                policy.polTypID === 2 ?
                                    <div className="grid bg-gray-50 border-round m-2">
                                        <div className="col-3 text-right font-bold line-height-4">
                                            Poprzednia polisa:
                                        </div>
                                        <div className="col-9">
                                            {policy.polPoprzID ? policy.polPopSeriaTxt + "/" + policy.polPopNumerTxt : "Brak"}
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                policy.polTypID === 4 ?
                                    <div className="grid bg-gray-50 border-round m-2">
                                        <div className="col-3 text-right font-bold line-height-4">
                                            Aneks do polisy:
                                        </div>
                                        <div className="col-9">
                                            {policy.popAnNumerTxt ? policy.popAnSeriaTxt + "/" + policy.popAnNumerTxt : "Wybierz"}
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Towarzystwo ubezpieczeń
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={ubzs}
                                        value={policy.polUbzID}
                                        emptyMessage="Brak wyników"
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Seria:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={series.filter(s => s.ubz === policy.polUbzID)}
                                        value={policy.polSeriaID}
                                        emptyMessage="Brak wyników"
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Numer:
                                </div>
                                <div className="col-9">
                                    {
                                        policy.polSeriaID && series.find(s => s.value === policy.polSeriaID && s.virtual === 0) ?
                                            <Dropdown
                                                className="f-w"
                                                options={[]}
                                                emptyMessage="Brak wyników"
                                            />
                                            :
                                            <InputText
                                                className="f-w"
                                                value={policy.polNumerTxt}
                                            />
                                    }
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Data zawarcia:
                                </div>
                                <div className="col-9">
                                    <Calendar
                                        className="f-w"
                                        dateFormat="dd/mm/yy"
                                        value={moment(policy.polDataZaw).toDate()}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Data startu:
                                </div>
                                <div className="col-9">
                                    <Calendar
                                        className="f-w"
                                        dateFormat="dd/mm/yy"
                                        value={moment(policy.polDataOd).toDate()}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Data końca:
                                </div>
                                <div className="col-9">
                                    <Calendar
                                        className="f-w"
                                        dateFormat="dd/mm/yy"
                                        value={moment(policy.polDataDo).toDate()}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Waluta:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={currencies}
                                        value={policy.polWalID}
                                        emptyMessage="Brak wyników"
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Agent:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        disabled
                                        className="f-w"
                                        options={agents}
                                        value={policy.polAgentID}
                                        emptyMessage="Brak wyników"
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Status:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={policyStatus}
                                        value={policy.polStatusID}
                                        disabled={true}
                                        emptyMessage="Brak wyników"
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">

                                </div>
                                <div className="grid col-9">
                                    <div className="col">
                                        <Checkbox /> Inny adres
                                    </div>
                                    <div className="col">
                                        <Checkbox
                                            checked={policy.polKontynuacja}
                                            disabled={policy.polTypID !== 2}
                                        /> Kontynuacja
                                    </div>
                                    <div className="col">
                                        <Checkbox
                                            checked={policy.polSprzedana === 1}
                                        /> Sprzedana
                                    </div>
                                    <div className="col">
                                        <Checkbox
                                            checked={policy.polAnulowana === 1}
                                        /> Anulowana
                                    </div>
                                    <div className="col">
                                        <Checkbox
                                            checked={policy.polPelnaTresc === 1}
                                        /> Pełna treść
                                    </div>
                                    <div className="col">
                                        <Checkbox
                                            checked={policy.polZatwierdzona === 1}
                                        /> Zatwierdzona
                                    </div>
                                    <div className="col">
                                        <Checkbox
                                            checked={policy.polZerwana === 1}
                                        /> Zerwana
                                    </div>
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Uwagi
                                </div>
                                <div className="col-9">
                                    <DataTable
                                        value={remarks}
                                        dataKey="uwID"
                                        emptyMessage="Brak wyników"
                                    >
                                        <Column field="uwUser" header="Użytkownik" className="col-2" />
                                        <Column field="uwData" header="Data" className="col-2" body={(rowData) => moment(rowData.uwData).format('DD/MM/YYYY')} />
                                        <Column field="uwTekst" header="Tekst" />
                                    </DataTable>
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Druki dodatkowe
                                </div>
                                <div className="col-9">
                                    <DataTable
                                        value={additionalPrints}
                                        dataKey="pidID"
                                        responsiveLayout='scroll'
                                        emptyMessage="Brak wyników"
                                    >
                                        <Column header="Seria" field="pidSeriaID" body={(rowData) => { return addPrintSeries.find(s => s.value === rowData.pidSeriaID) ? addPrintSeries.find(s => s.value === rowData.pidSeriaID).label : "" }} />
                                        <Column header="Numer" field="pidNumer" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                    <Fieldset
                        legend="Ryzyka"
                        className="mt-3"
                    >
                        <div className="grid">
                            <div className="col">
                                <DataTable
                                    value={risks}
                                    dataKey="ryzID"
                                    responsiveLayout='scroll'
                                    emptyMessage="Brak wyników"
                                >
                                    <Column header="Ryzyko" field="ryzRyzID" style={{ width: "15%" }} body={(rowData) => { return riskTypes.find(t => t.value === rowData.ryzRyzID).label }} />
                                    <Column header="Ochrona od" field="ryzDataOd" body={(rowData) => { return moment(rowData.ryzDataOd).format("DD/MM/YYYY") }} />
                                    <Column header="Ochrona do" field="ryzDataDo" body={(rowData) => { return moment(rowData.ryzDataDo).format("DD/MM/YYYY") }} />
                                    <Column header="Składka" field="ryzSklada" body={(rowData) => { return rowData.ryzSkladka.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                                    <Column header="Suma ubz" field="ryzSumaUbz" body={(rowData) => { return rowData.ryzSumaUbz ? rowData.ryzSumaUbz.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) : "" }} />
                                    <Column header="Ile rat" field="ryzIleRat" />
                                    {/* <Column header="B/M" field="ryzBM" body={(rowData) => { return rowData.ryzBM ? bms.find(t => t.value === rowData.ryzBM).label : "" }} /> */}
                                    <Column header="Komentarz" field="ryzKometarz" style={{ width: "35%" }} />
                                </DataTable>
                            </div>
                        </div>
                    </Fieldset>
                    <div className="grid col-12 justify-content-end mt-2 m-0">
                        <div>
                            Składka razem: <label className="font-bold text-xl">{policy.polSklCalk ? policy.polSklCalk.toLocaleString('pl-PL', { style: 'currency', currency: policy.polWalID ? currencies.find(c => c.value === policy.polWalID).label : "PLN" }) : " -,"}</label>
                        </div>
                    </div>
                    <TabView className="mb-3 mt-2">
                        <TabPanel header="Podsumowanie rat">
                            <DataTable
                                value={instalments}
                                dataKey="rratID"
                                responsiveLayout='scroll'
                                emptyMessage="Brak wyników"
                            >
                                <Column header="Kwota" field="rrKwota" body={(rowData) => { return (rowData.rrKwota ? rowData.rrKwota : 0).toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                                <Column header="Termin płatności" field="rrData" body={(rowData) => { return rowData.rrData ? moment(rowData.rrData).format("DD/MM/YYYY") : "Brak" }} />
                                <Column header="Rata nr" field="rrNr" />
                                <Column header="Inkaso" field="rrZaplat" body={(rowData) => { return <Checkbox checked={rowData.rrZaplat === 1} /> }} />
                                <Column header="Zapłata cz." field="rrZaplatC" body={(rowData) => { return <Checkbox checked={rowData.rrZaplatC} /> }} />
                                <Column header="Kwota zapł." field="rrZaplatKwota" body={(rowData) => { return (rowData.rrZaplatKwota ? rowData.rrZaplatKwota : 0).toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label }) }} />
                                <Column header="Pozostało" field="rrZaplatKwotaP" body={(rowData) => { return (rowData.rrZaplataKwotaP ? rowData.rrZaplataKwotaP : 0).toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label }) }} />
                                <Column header="Data inkaso" field="rrDataZap" body={(rowData) => { return rowData.rrDataZap ? moment(rowData.rrDataZap).format("DD/MM/YYYY") : "Brak" }} />
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Inkaso">
                            <div className="grid">
                                <div className="col">
                                    <DataTable
                                        value={inkaso}
                                        dataKey="inkID"
                                        responsiveLayout='scroll'
                                        emptyMessage="Brak wyników"
                                        editMode="cell"
                                    >
                                        <Column header="Kwota zapłacona" field="inkKwota" body={(rowData) => { return (rowData.inkKwota ? rowData.inkKwota : 0).toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                                        <Column header="Data inkaso" field="inkDataZap" body={(rowData) => { return rowData.inkDataZap ? moment(rowData.inkDataZap).format("DD/MM/YYYY") : "Brak" }} />
                                        <Column header="Termin płatności" field="inkDataPlat" body={(rowData) => { return rowData.inkDataPlat ? moment(rowData.inkDataPlat).format("DD/MM/YYYY") : "Brak" }} />
                                        <Column header="Status" field="inkStatusID" body={(rowData) => { return rowData.inkStatusID ? inkasoStatus.find(s => s.value === rowData.inkStatusID).label : "" }} />
                                        <Column header="Forma płatności" field="inkFormaPlatID" body={(rowData) => { return rowData.inkFormaPlatID ? paymentForms.find(f => f.value === rowData.inkFormaPlatID).label : "" }} />
                                        <Column header="Nr transakcji" field="inkNrTran" />
                                        <Column header="Nr raty" field="rraty_rrNr" />
                                        <Column header="Odsetki" field="inkOdsetki" body={(rowData) => { return (rowData.inkOdsetki ? rowData.inkOdsetki : 0).toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                                        <Column header="Nr trans. online" field="inkNumerPlatnosciOnline" />
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <TabView>
                        <TabPanel header="Pojazdy">
                            <div className="grid">
                                <div className="col">
                                    <DataTable
                                        value={vehicles}
                                        dataKey="pojID"
                                        responsiveLayout='scroll'
                                        emptyMessage="Brak wyników"
                                    >
                                        <Column header="Nr rej." field="pojRejestracja" />
                                        <Column header="Marka" field="pojMarkaID" body={(rowData) => { return rowData.pojMarkaID ? vehicleBrands.find(m => m.value === rowData.pojMarkaID).label : "" }} />
                                        <Column header="Model" field="pojModelID" body={(rowData) => { return rowData.pojModelID ? vehicleModels.find(m => m.value === rowData.pojModelID).label : "" }} />
                                        <Column header="Typ" field="pojTyp" />
                                        <Column header="Rok prod" field="pojRokProd" />
                                        <Column header="Data p. rej." field="pojPierRej" body={(rowData) => { return rowData.pojPierRej ? moment(rowData.pojPierRej).format("DD/MM/YYYY") : "" }} />
                                        <Column header="VIN" field="pojVIN" />
                                        <Column header="Rodzaj" field="pojRodzajID" body={(rowData) => { return rowData.pojRodzajID ? vehicleKinds.find(k => k.value === rowData.pojRodzajID).label : "" }} />
                                        <Column header="Status" field="pojStatusID" body={(rowData) => { return rowData.pojStatusID ? vehicleStatus.find(s => s.value === rowData.pojStatusID).label : "" }} />
                                        <Column header="Pojemność" field="pojPojemnosc" />
                                        <Column header="Il. miejsc" field="pojIloMiejsc" />
                                        <Column header="Data badania" field="pojDataBadania" body={(rowData) => { return rowData.pojDataBadania ? moment(rowData.pojDataBadania).format("DD/MM/YYYY") : "" }} />
                                        <Column header="Przebieg" field="pojPrzebieg" />
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Inne obiekty">
                            <div className="grid">
                                <div className="col">
                                    <DataTable
                                        value={otherObjs}
                                        dataKey="ouID"
                                        responsiveLayout='scroll'
                                        emptyMessage="Brak wyników"
                                    >
                                        <Column header="Typ" field="ouTypID" body={(rowData) => { return insObjTypes.find(t => t.value === rowData.ouTypID).label }} />
                                        <Column header="Nazwa" field="ouNazwa" />
                                        <Column header="Adres" field="ouAdres" />
                                        <Column header="Nr domu" field="ouNrDomu" />
                                        <Column header="Nr lokalu" field="ouNrLok" />
                                        <Column header="Kod pocz." field="ouKodPocz" />
                                        <Column header="Miasto" field="ouMiasto" />
                                        <Column header="Opis" style={{ width: "35%" }} field="ouOpis" />
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Aneksy">
                            <DataTable>
                                <Column header=" " style={{ width: "30px" }} />
                                <Column header="Data aneksu" />
                                <Column header="Seria" />
                                <Column header="Numer" />
                                <Column header="Rodzaj" />
                                <Column header="Opis" style={{ width: "35%" }} />
                            </DataTable>
                        </TabPanel>
                    </TabView>

                    <Dialog
                        visible={remarksVisible}
                        header="Uwagi"
                        onHide={() => setRemarksVisible(false)}
                        dismissableMask
                        maximizable
                        className='col-8'
                    >
                        <DataTable
                            value={remarks}
                            dataKey="uwID"
                            emptyMessage="Brak wyników"
                        >
                            <Column field="uwUser" header="Użytkownik" className="col-2" />
                            <Column field="uwData" header="Data" className="col-2" body={(rowData) => moment(rowData.uwData).format('DD/MM/YYYY')} />
                            <Column field="uwTekst" header="Tekst" />
                        </DataTable>

                    </Dialog>

                    <Dialog
                        visible={addPrintsVisible}
                        header="Druk dodatkowy"
                        onHide={() => setAddPrintsVisible(false)}
                        dismissableMask
                        maximizable
                        className='col-12 lg:col-10'
                    >
                        <AddAdditionalPrint
                            id={selectedPrint}
                            tuList={ubzs}
                            agents={agents}
                        />
                    </Dialog>
                </div >
            }
        </>
    )
}