/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import { useState } from "react";
import dictionaryService from "../../services/dictionaryService";
import userService from "../../services/userService";
import moment from "moment";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import policyService from "../../services/policyService";
import { ContextMenu } from "primereact/contextmenu";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import VehicleDetails from "./VehicleDetails";
import vehicleService from "../../services/vehicleService";
import Policies from "../Policies";
import InsuranceObjects from "../InsuranceObjects";
import objectsService from "../../services/objectsService";
import Vehicles from "../Vehicles";
import { InputTextarea } from "primereact/inputtextarea";
import Clients from "../Clients";
import clientService from "../../services/clientService";
import AddAdditionalPrint from "../adders/AddAdditionalPrint";


export default function EditPolicy(props) {

    const [policyTypes, setPolicyTypes] = useState([]);
    const [policyStatus, setPolicyStatus] = useState([]);
    const [currencies, setCurrencies] = useState([{ value: 1, label: "PLN" }]);
    const [ubzs, setUbzs] = useState([]);
    const [agents, setAgents] = useState([]);
    const [series, setSeries] = useState([]);
    const [clientUbzTypes, setClientUbzTypes] = useState([]);
    const [riskTypes, setRiskTypes] = useState([]);
    const [bms, setBms] = useState([]);
    const [inkasoStatus, setInkasoStatus] = useState([]);
    const [paymentForms, setPaymentForms] = useState([]);
    const [vehicleBrands, setVehicleBrands] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [vehicleKinds, setVehicleKinds] = useState([]);
    const [vehicleStatus, setVehicleStatus] = useState([]);
    const [insObjTypes, setInsObjTypes] = useState([]);
    const [addPrintSeries, setAddPrintSeries] = useState([])


    const [policy, setPolicy] = useState({});
    const [policyUsers, setPolicyUsers] = useState([]);
    const [risks, setRisks] = useState([]);
    const [instalments, setInstalments] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [otherObjs, setOtherObjs] = useState([]);
    const [additionalPrints, setAdditionalPrints] = useState([]);
    const [inkaso, setInkaso] = useState([]);
    const [remarks, setRemarks] = useState([]);
    const [otherPrints, setOtherPrints] = useState([]);

    const [addUserVisible, setAddUserVisible] = useState(false);
    const [clientBrowserVisible, setClientBrowserVisible] = useState(false);
    const [addRiskVisible, setAddRiskVisible] = useState(false);
    const [vehicleBrowserVisible, setVehicleBrowserVisible] = useState(false);
    const [objectsBrowserVisible, setObjectsBrowserVisible] = useState(false);
    const [policyBrowserVisible, setPolicyBrowserVisible] = useState(false);
    const [vehicleEditVisible, setVehicleEditVisible] = useState(false);
    const [annexInput, setAnnexInput] = useState(true);
    const [remarksActiveTab, setRemarksActiveTab] = useState(0);
    const [addPrintsVisible, setAddPrintsVisible] = useState(false);

    const [loading, setLoading] = useState(true);
    const [selection, setSelection] = useState({});
    const [changed, setChanged] = useState(false);
    const [selectedPrint, setSelectedPrint] = useState(null);

    const [additionalPrint, setAdditionalPrint] = useState({});
    const [newPolicyUser, setNewPolicyUser] = useState({});
    const [newAnnex, setNewAnnex] = useState({});
    const [newRisk, setNewRisk] = useState({
        ryzDataOd: moment().format(),
        ryzDataDo: moment().add(1, 'y').add(-1, 'd').toDate()
    });
    const [newRemark, setNewRemark] = useState("");

    const [policyUsersMod, setPolicyUsersMod] = useState({ changed: false, add: [], del: [], mod: [] });
    const [additionalPrintsMod, setAdditionalPrintsMod] = useState({ changed: false, add: [], del: [], mod: [] });
    const [risksMod, setRisksMod] = useState({ changed: false, add: [], del: [], mod: [] });
    const [instalmentsMod, setInstalmentsMod] = useState({ changed: false, add: [], del: [], mod: [] });
    const [inkasoMod, setInkasoMod] = useState({ changed: false, add: [], del: [], mod: [] });
    const [vehicleMod, setVehicleMod] = useState({ changed: false, add: [], del: [], mod: [] });
    const [otherObjsMod, setOtherObjsMod] = useState({ changed: false, add: [], del: [], mod: [] });

    // eslint-disable-next-line no-unused-vars
    const riskCM = useRef(null);
    const inkasoCM = useRef(null);
    const usersCM = useRef(null);
    const vehicleCM = useRef(null);
    const objCM = useRef(null);
    const printsCM = useRef(null);
    const toast = useRef(null);


    useEffect(() => {
        const fetchData = async () => {
            let response = await dictionaryService.getPolicyTypes();
            setPolicyTypes(response);
            response = await dictionaryService.getPolicyStatus();
            setPolicyStatus(response);
            response = await dictionaryService.getCurrencies();
            setCurrencies(response);
            response = await dictionaryService.getTUs();
            setUbzs(response);
            response = await userService.getList();
            setAgents(response);
            response = await dictionaryService.getSeries();
            setSeries(response);
            response = await dictionaryService.getClientUbzTypes();
            setClientUbzTypes(response);
            response = await dictionaryService.getRiskTypes();
            setRiskTypes(response);
            response = await dictionaryService.getBms();
            setBms(response);
            response = await dictionaryService.getInkasoStatus();
            setInkasoStatus(response);
            response = await dictionaryService.getPaymentForms();
            setPaymentForms(response);
            response = await dictionaryService.getVehicleBrands();
            setVehicleBrands(response);
            response = await dictionaryService.getVehicleModels();
            setVehicleModels(response);
            response = await dictionaryService.getVehicleKinds();
            setVehicleKinds(response);
            response = await dictionaryService.getVehicleStatus();
            setVehicleStatus(response);
            response = await dictionaryService.getObjectTypes();
            setInsObjTypes(response);
            response = await dictionaryService.getAddPrintSeries();
            setAddPrintSeries(response);
            response = await policyService.getPolicyDocs(props.id);
            setOtherPrints(response);
            response = await policyService.getDetails(props.id);
            setPolicy(response);
            response = await policyService.getPolicyAdditionalData(props.id);
            setAdditionalPrints(response.additionalPrints);
            setPolicyUsers(response.policyUsers);
            setRisks(response.risks);
            setInstalments(response.instalments);
            setInkaso(response.inkaso);
            setVehicles(response.vehicles);
            setOtherObjs(response.otherObjs);
            setRemarks(response.remarks);
            setLoading(false);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getNumberMask = (arg) => {
        var m = "";
        for (var i = 1; i <= arg; i++)
            m = m.concat("*");
        return m;
    }

    const contributionSum = () => {
        var sum = 0;
        risks.forEach((r) => sum += r.ryzSkladka);
        policy.polSklCalk = sum;
        setPolicy({ ...policy });
        //setChanged(true);
        return sum;
    }

    // const calculateInstalments = () => {
    //     let instMap = new Map();
    //     risks.forEach((risk) => {
    //         var rest = ((risk.ryzSkladka * 100) % risk.ryzIleRat) / 100;
    //         var startDate;
    //         var dateStep;
    //         if (risk.ryzIleRat === 11) {
    //             startDate = moment(risk.ryzDataOd).add(1, 'week').toDate();
    //             dateStep = 1;
    //         }
    //         else {
    //             startDate = risk.ryzDataOd;
    //             dateStep = 12 / risk.ryzIleRat;
    //         }
    //         instMap.set(moment(startDate).format('L'), (instMap.get(moment(startDate).format('L')) || 0) + (risk.ryzSkladka - rest) / risk.ryzIleRat + rest);
    //         if (risk.ryzIleRat !== 11)
    //             startDate = moment(startDate).add(-1, 'd');
    //         for (var i = 2; i <= risk.ryzIleRat; i++) {
    //             instMap.set(moment(startDate).add(dateStep * (i - 1), 'month').format('L'),
    //                 (instMap.get(moment(startDate).add(dateStep * (i - 1), 'month').format('L')) || 0) + (risk.ryzSkladka - rest) / risk.ryzIleRat);
    //         }
    //     });
    //     var idx = 0;
    //     var arr = [];
    //     instMap = [...instMap.entries()].sort((a, b) => moment(a[0]).isAfter(moment(b[0])) ? 1 : -1);
    //     for (var entry of instMap) {
    //         idx++;
    //         arr.push({ rratID: idx,rrKwota: entry[1], rrData: moment(entry[0], 'L').format(), rrNr: idx, rrZaplat: false, rrZaplatC: false, rrZaplatKwota: 0, rrZaplatKwotaP: entry[1], rrDataZap: null,polisa_polID: policy.polID });
    //     };
    //     setInstalments(arr);
    //     setInkaso([]);

    // }

    const inkasoAction = (data, checked) => {
        let idx = instalments.findIndex(i => i === data);
        let inst = instalments[idx];
        if (checked) {
            if (idx === 0 || instalments[idx - 1].rrZaplat === 1) {
                inst.rrZaplat = 1;
                inst.rrDataZap = moment().format();
                inst.rrZaplatKwotaP = 0;
                inst.rrZaplatKwota = data.rrKwota;
                let ink = {
                    inkDataZap: moment().format(),
                    inkDataPlat: inst.rrData,
                    inkKwota: data.rrZaplatKwota,
                    rrKwota: data.rrKwota,
                    inkOdsetki: 0,
                    rraty_rrNr: inst.rrNr,
                    inkStatusID: 2,
                    inkFormaPlatID: 1,
                    inkNrTran: "",
                    inkNumerPlatnosciOnline: "",
                    polisa_polID: policy.polID
                }
                inkaso.push(ink);
                inkasoMod.add.push(ink);
                instalmentsMod.mod.push(inst);
                setInkasoMod({ ...inkasoMod, changed: true });
                setInstalmentsMod({ ...instalmentsMod, changed: true });
            }
            setInkaso([...inkaso]);
        } else {
            let ink = inkaso;
            for (var i = idx; i < instalments.length; i++) {
                if (instalments[i].rrZaplat === 1) {
                    instalments[i].rrZaplat = checked;
                    instalments[i].rrDataZap = null
                    instalments[i].rrZaplatKwotaP = data.rrKwota;
                    instalments[i].rrZaplatKwota = 0;
                    instalments[i].rrZaplatC = false;
                    instalmentsMod.mod.push(instalments[i]);
                    instalmentsMod.changed = true;
                    for (var j in ink)
                        if (ink[j].rraty_rrNr === instalments[i].rrNr)
                            inkasoMod.del.push(ink[j]);
                    // eslint-disable-next-line no-loop-func
                    ink = ink.filter(ink => ink.rraty_rrNr !== instalments[i].rrNr);
                }
            }
            setInkaso([...ink]);
            setInkasoMod({ ...inkasoMod, changed: true });
            setInstalmentsMod({ ...instalmentsMod, changed: true });
        }
        setInstalments([...instalments]);
    }

    const cellEditor = (event) => {
        switch (event.field) {
            case "inkKwota":
                return <InputNumber
                    className="f-w"
                    mode="currency"
                    currency="PLN"
                    value={event.value}
                    onValueChange={(e) => {
                        let sum = 0;
                        for (var i in inkaso) {
                            if (inkaso[i].rraty_rrNr === event.rowData.rraty_rrNr && inkaso[i].inkDataZap !== event.rowData.inkDataZap)
                                sum += inkaso[i].inkKwota;
                        }
                        sum += e.value;
                        if (sum > event.rowData.rrKwota)
                            toast.current.show({ severity: 'warn', summary: 'Błąd', detail: 'Za wysoka wartość!', closable: true })
                        else if (e.value < 0)
                            toast.current.show({ severity: 'warn', summary: 'Błąd', detail: 'Za niska wartość!', closable: true })
                        else
                            event.editorCallback(e.value);
                    }}
                />
            case "rrKwota":
            case "inkOdsetki":
                return <InputNumber
                    className="f-w"
                    mode="currency"
                    currency="PLN"
                    value={event.value}
                    onValueChange={(e) => event.editorCallback(e.value)}
                />
            case "inkDataZap":
            case "rrData":
                return <Calendar
                    className="f-w"
                    dateFormat="dd/mm/yy"
                    value={moment(event.value).toDate()}
                    onChange={(e) => event.editorCallback(moment(e.value).format())}
                    monthNavigator
                    yearNavigator
                    yearRange={"1900:2030"}
                />
            case "inkFormaPlatID":
                return <Dropdown
                    className="f-w"
                    value={event.value}
                    options={paymentForms.filter(f => [1, 2, 3, 5].includes(f.value))}
                    onChange={(e) => {
                        inkaso[event.rowIndex][event.field] = e.value; event.editorCallback(e.value);
                        inkasoMod.mod.push(inkaso[event.rowIndex]);
                        setInkasoMod({ ...inkasoMod, changed: true });
                    }}
                    emptyMessage="Brak wyników"
                />
            case "inkStatusID":
                return <Dropdown
                    className="f-w"
                    value={event.value}
                    options={inkasoStatus}
                    onChange={(e) => {
                        inkaso[event.rowIndex][event.field] = e.value; event.editorCallback(e.value);
                        inkasoMod.mod.push(inkaso[event.rowIndex]);
                        setInkasoMod({ ...inkasoMod, changed: true });
                    }}
                    emptyMessage="Brak wyników"
                />
            case "ryzRyzID":
                return <Dropdown
                    className="f-w"
                    value={event.value}
                    options={riskTypes}
                    onChange={(e) => {
                        risks[event.rowIndex][event.field] = e.value;
                        policyService.editRisk(risks[event.rowIndex]);
                        event.editorCallback(e.value);
                    }}
                    emptyMessage="Brak wyników"
                />
            default:
                return <InputText
                    className="f-w"
                    keyfilter={/[^^[\]!@#$%*()&"',:=+{};<>?\\/|]/}
                    value={event.value}
                    onChange={(e) => event.editorCallback(e.target.value)}
                />
        }
    }

    const onCellEditComplete = (event) => {
        event.rowData[event.field] = event.newRowData[event.field];
        let ins;
        let sum;
        switch (event.field) {
            case "inkKwota":
                ins = instalments.find(i => i.rrNr === event.rowData.rraty_rrNr);
                sum = 0;
                for (var i in inkaso) {
                    if (inkaso[i].rraty_rrNr === event.rowData.rraty_rrNr && inkaso[i].inkDataZap !== event.rowData.inkDataZap)
                        sum += inkaso[i].inkKwota;
                }
                sum += event.newValue;
                ins.rrZaplatKwotaP = ins.rrKwota - sum;
                ins.rrZaplatKwota = sum;
                ins.rrZaplatC = sum < ins.rrKwota && ins.rrZaplat;
                inkasoMod.mod.push(event.newRowData);
                setInkasoMod({ ...inkasoMod, changed: true });
                instalmentsMod.mod.push(ins);
                setInstalmentsMod({ ...instalmentsMod, changed: true });
                break;
            case "rrData":
                for (let ink in inkaso.filter(i => i.rraty_rrNr === event.rowData.rrNr)) {
                    inkaso[ink].inkDataPlat = event.newValue;
                    inkasoMod.mod.push(inkaso[ink]);
                }
                setInkasoMod({ ...inkasoMod, changed: true });
                instalmentsMod.mod.push(event.newRowData);
                setInstalmentsMod({ ...instalmentsMod, changed: true });
                break;
            case "inkDataZap":
                ins = instalments.find(i => i.rrNr === event.rowData.rraty_rrNr);
                ins.rrDataZap = event.newValue;
                instalmentsMod.mod.push(ins);
                setInstalmentsMod({ ...instalmentsMod, changed: true });
                inkasoMod.mod.push(event.newRowData);
                setInkasoMod({ ...inkasoMod, changed: true });
                break;
            case "rrKwota":
                ins = instalments.find(i => i.rrNr === event.rowData.rrNr);
                sum = 0;
                for (var j in inkaso) {
                    if (inkaso[j].rraty_rrNr === event.rowData.rrNr) {
                        sum += inkaso[j].inkKwota;
                        inkaso[j].rrKwota = event.newValue;
                    }
                }
                ins.rrZaplatKwotaP = ins.rrKwota - sum;
                ins.rrZaplatKwota = sum;
                ins.rrZaplatC = sum < ins.rrKwota && ins.rrZaplat;
                instalmentsMod.mod.push(ins);
                setInstalmentsMod({ ...instalmentsMod, changed: true });
                break;
            default:
                break;
        }
        setInkaso([...inkaso]);
        setInstalments([...instalments]);
        //setRisks([...risks]);
    }

    const addInkaso = () => {
        let inst = instalments.find(i => i.rrNr === selection.rraty_rrNr);
        let sum = 0;
        for (var i in inkaso) {
            if (inkaso[i].rraty_rrNr === inst.rrNr)
                sum += inkaso[i].inkKwota;
        }
        inst.rrZaplatKwotaP = 0;
        inst.rrZaplatKwota = inst.rrKwota;
        inst.rrZaplatC = false;
        let ink = {
            inkID: inkaso.length,
            inkDataZap: moment().format(),
            inkDataPlat: inst.rrData,
            inkKwota: inst.rrKwota - sum,
            rrKwota: inst.rrKwota,
            inkOdsetki: 0,
            rraty_rrNr: inst.rrNr,
            inkStatusID: 2,
            inkFormaPlatID: 1,
            inkNrTran: "",
            inkNumerPlatnosciOnline: "",
            polisa_polID: policy.polID
        };
        inkasoMod.add.push(ink);
        setInkasoMod({ ...inkasoMod, changed: true });
        instalmentsMod.mod.push(inst);
        setInstalmentsMod({ ...instalmentsMod, changed: true });
        inkaso.push(ink);
        setInkaso([...inkaso]);
        setInstalments([...instalments]);
    }

    const deleteInkaso = () => {
        let tmp = inkaso.filter(i => i !== selection);
        if (tmp.length) {
            let inst = instalments.find(i => i.rrNr === selection.rraty_rrNr);
            let sum = 0;
            for (var i in tmp) {
                if (tmp[i].rraty_rrNr === inst.rrNr)
                    sum += tmp[i].rrKwota;
            }
            inst.rrZaplatKwotaP = inst.rrKwota - sum;
            inst.rrZaplatKwota = sum;
            inst.rrZaplatC = inst.rrZaplatKwotaP > 0;
            inkasoMod.del.push(inkaso.find(i => i === selection));
            setInkasoMod({ ...inkasoMod, changed: true });
            instalmentsMod.mod.push(inst);
            setInstalmentsMod({ ...instalmentsMod, changed: true });
            setInkaso([...tmp]);
            setInstalments([...instalments]);
        }

    }

    // const saveChanges = async () => {
    //     toast.current.show({ severity: 'info', summary: 'Funkcja niedostępna', detail: "Edycja polisy w trakcie implementowania.", closable: true })
    // }

    useEffect(() => {
        if (changed) {
            policyService.edit(policy);
            setChanged(false);
        }
    }, [changed])

    useEffect(() => {
        if (additionalPrintsMod.changed) {
            if (additionalPrintsMod.add.length) {
                const add = async () => {
                    let result = await policyService.addPrints(additionalPrintsMod.add, policy);
                    for (let r in result)
                        additionalPrints.find(p => p.pidNumer === result[r].pidNumer).pidID = result[r].pidID;
                }
                add();
            }
            if (additionalPrintsMod.del.length) {
                policyService.deletePrints(additionalPrintsMod.del);
            }
            setAdditionalPrintsMod({ changed: false, add: [], del: [], mod: [] });
        }
    }, [additionalPrintsMod.changed])

    useEffect(() => {
        if (policyUsersMod.changed) {
            if (policyUsersMod.add.length) {
                const add = async () => {
                    let result = await policyService.addPolicyUsers(policyUsersMod.add);
                    for (let r in result)
                        policyUsers.find(u => u.pkTypKl === result[r].pkTypKl && u.klient_kliID === result[r].klient_kliID).pkID = result[r].pkID;
                }
                add();
            }
            if (policyUsersMod.del.length) {
                policyService.deletePolicyUsers(policyUsersMod.del);
            }
            setPolicyUsersMod({ changed: false, add: [], del: [], mod: [] });
        }
    }, [policyUsersMod.changed])

    // useEffect(() => {
    //     if (risksMod.changed) {
    //         console.log(risksMod)
    //         if (risksMod.add.length) {
    //             const add = async () => {
    //                 let result = await policyService.addRisks(risksMod.add);
    //                 for (let i in result)
    //                     risks.find(r => r.ryzRyzID === result[i].ryzRyzID).ryzID = result[i].ryzID;
    //             }
    //             add();
    //         }
    //         if (risksMod.del.length) {
    //             policyService.deleteRisks(risksMod.del);
    //         }
    //         if(risksMod.mod.length) {
    //             policyService.editRisks(risksMod.mod);
    //         }
    //         setRisksMod({ changed: false, add: [], del: [], mod: [] });
    //     }
    // }, [risksMod.changed])

    useEffect(() => {
        if (instalmentsMod.changed) {
            if (instalmentsMod.add.length) {
                const add = async () => {
                    let result = await policyService.addInstalments(instalmentsMod.add);
                    for (let r in result)
                        instalments.find(i => i.rrData === result[r].rrData && i.rrNr === result[r].rrNr).rratID = result[r].rratID;
                }
                add();
            }
            if (instalmentsMod.del.length) {
                policyService.deleteInstalments(instalmentsMod.del);
            }
            if (instalmentsMod.mod.length) {
                policyService.editInstalments(instalmentsMod.mod);
            }
            setInstalmentsMod({ changed: false, add: [], del: [], mod: [] });
        }
    }, [instalmentsMod.changed])

    useEffect(() => {
        if (inkasoMod.changed) {
            if (inkasoMod.add.length) {
                const add = async () => {
                    let result = await policyService.addInkaso(inkasoMod.add);
                    for (let r in result)
                        inkaso.find(i => i.rraty_rrNr === result[r].rraty_rrNr && i.inkKwota === result[r].inkKwota && i.inkFormaPlatID === result[r].inkFormaPlatID).inkID = result[r].inkID
                }
                add();
            }
            if (inkasoMod.del.length) {
                policyService.deleteInkaso(inkasoMod.del);
            }
            if (inkasoMod.mod.length) {
                policyService.editInkaso(inkasoMod.mod);
            }
            setInkasoMod({ changed: false, add: [], del: [], mod: [] });
        }
    }, [inkasoMod.changed])

    useEffect(() => {
        if (vehicleMod.changed) {
            if (vehicleMod.add.length) {
                policyService.addVehicles(vehicleMod.add);
            }
            if (vehicleMod.del.length) {
                policyService.deleteVehicles(vehicleMod.del);
            }
            setVehicleMod({ changed: false, add: [], del: [], mod: [] });
        }
    }, [vehicleMod.changed])

    useEffect(() => {
        if (otherObjsMod.changed) {
            if (otherObjsMod.add.length) {
                policyService.addOtherObjs(otherObjsMod.add);
            }
            if (otherObjsMod.del.length) {
                policyService.deleteOtherObjs(otherObjsMod.del);
            }
            setOtherObjsMod({ changed: false, add: [], del: [], mod: [] });
        }
    }, [otherObjsMod.changed])

    return (
        <>
            <Toast ref={toast} />
            {loading ?
                <div className="grid col-12 justify-content-center">
                    <div className="loader"></div>
                    <div className="col-12 text-center">Ładuję dane...</div>
                </div>
                :
                <div className="p-3">
                    <Fieldset
                        legend="Klient / Osoby powiązane"
                    >
                        <div className="grid col-12">
                            <div style={{ width: "30px" }} className="m-2">
                                <Button
                                    icon="pi pi-fw pi-plus"
                                    onClick={() => setAddUserVisible(true)}
                                />
                            </div>
                            <div className="col">
                                <ContextMenu
                                    model={[
                                        {
                                            label: "Usuń", icon: "pi pi-fw pi-trash", command: () => {
                                                policyUsersMod.del.push(policyUsers.find(u => u.pkID === selection.pkID));
                                                setPolicyUsersMod({ ...policyUsersMod, changed: true });
                                                setPolicyUsers(policyUsers.filter(u => u.pkID !== selection.pkID));
                                            }
                                        }
                                    ]}
                                    ref={usersCM}
                                />
                                <DataTable
                                    value={policyUsers}
                                    dataKey="pkID"
                                    responsiveLayout='scroll'
                                    emptyMessage="Brak wyników"
                                    onContextMenuSelectionChange={e => setSelection(e.value)}
                                    onContextMenu={e => usersCM.current.show(e.originalEvent)}
                                >
                                    <Column header="Typ" field="pkTypKl" body={(rowData) => { return rowData.pkTypKl ? clientUbzTypes.find(t => t.value === rowData.pkTypKl).label : "" }} />
                                    <Column header="Nazwa" field="pkNazwa" />
                                    <Column header="Adres" field="pkAdres" />
                                    <Column header="Miasto" field="pkMiasto" />
                                    <Column header="Telefon" field="pkTelefon" />
                                </DataTable>
                            </div>
                        </div>
                    </Fieldset>
                    <Fieldset
                        legend="Polisa"
                        className="my-3"
                    >
                        <div className="col-12">
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Typ polisy:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={policyTypes}
                                        value={policy.polTypID}
                                        emptyMessage="Brak wyników"
                                        onChange={(e) => {
                                            setPolicy({
                                                ...policy,
                                                polTypID: e.value,
                                                polPoprzID: null,
                                                polPopNumerTxt: null,
                                                polPopSeriaTxt: null,
                                                popAnNumerTxt: null,
                                                popAneksGID: null,
                                                popAnSeriaTxt: null,
                                                polKontynuacja: false
                                            });
                                            setChanged(true);
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                policy.polTypID === 2 ?
                                    <div className="grid bg-gray-50 border-round m-2">
                                        <div className="col-3 text-right font-bold line-height-4">
                                            Poprzednia polisa:
                                        </div>
                                        <div className="col-9">
                                            <Button
                                                //className="f-w"
                                                label={policy.polPoprzID ? policy.polPopSeriaTxt + "/" + policy.polPopNumerTxt : "Wybierz"}
                                                onClick={() => setPolicyBrowserVisible(true)}
                                            />
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            {
                                policy.polTypID === 4 ?
                                    <div className="grid bg-gray-50 border-round m-2">
                                        <div className="col-3 text-right font-bold line-height-4">
                                            Aneks do polisy:
                                        </div>
                                        <div className="col-9">
                                            <Button
                                                //className="f-w"
                                                label={policy.popAnNumerTxt ? policy.popAnSeriaTxt + "/" + policy.popAnNumerTxt : "Wybierz"}
                                                onClick={() => setPolicyBrowserVisible(true)}
                                            />
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Towarzystwo ubezpieczeń
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={ubzs}
                                        value={policy.polUbzID}
                                        emptyMessage="Brak wyników"
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Seria:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={series.filter(s => s.ubz === policy.polUbzID)}
                                        value={policy.polSeriaID}
                                        emptyMessage="Brak wyników"
                                        onChange={(e) => { setPolicy({ ...policy, polSeriaID: e.value, polSeriaTxt: series.find(s => s.value === e.value).label }); setChanged(true) }}
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Seria:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={series.filter(s => s.ubz === policy.polUbzID)}
                                        value={policy.polSeriaID}
                                        emptyMessage="Brak wyników"
                                        onChange={(e) => { setPolicy({ ...policy, polSeriaID: e.value, polSeriaTxt: series.find(s => s.value === e.value).label }); setChanged(true) }}
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Numer:
                                </div>
                                <div className="col-9">
                                    {
                                        policy.polSeriaID && series.find(s => s.value === policy.polSeriaID && s.virtual === 0) ?
                                            <Dropdown
                                                className="f-w"
                                                options={[]}
                                                emptyMessage="Brak wyników"
                                            />
                                            :
                                            <InputMask
                                                className="f-w"
                                                value={policy.polNumerTxt}
                                                mask={getNumberMask(policy.polSeriaID ? series.find(s => s.value === policy.polSeriaID).mask : 1)}
                                                onComplete={(e) => { setPolicy({ ...policy, polNumerTxt: e.value }); setChanged(true); props.editNumber(e.value); }}
                                            />
                                    }
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Data zawarcia:
                                </div>
                                <div className="col-9">
                                    <Calendar
                                        className="f-w"
                                        dateFormat="dd/mm/yy"
                                        value={moment(policy.polDataZaw).toDate()}
                                        showIcon
                                        monthNavigator
                                        yearNavigator
                                        yearRange={"1900:2030"}
                                        onChange={(e) => { setPolicy({ ...policy, polDataZaw: moment(e.value).format() }); setChanged(true) }}
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Data startu:
                                </div>
                                <div className="col-9">
                                    <Calendar
                                        className="f-w"
                                        dateFormat="dd/mm/yy"
                                        value={moment(policy.polDataOd).toDate()}
                                        showIcon
                                        monthNavigator
                                        yearNavigator
                                        yearRange={"1900:2030"}
                                        onChange={(e) => {
                                            if (policy.polTypID !== 3)
                                                policy.polDataDo = moment(e.value).add(1, 'y').add(-1, 'd').format();
                                            policy.polDataOd = moment(e.value).format();
                                            setPolicy({ ...policy });
                                            setChanged(true);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Data końca
                                </div>
                                <div className="col-9">
                                    <Calendar
                                        className="f-w"
                                        dateFormat="dd/mm/yy"
                                        value={moment(policy.polDataDo).toDate()}
                                        showIcon
                                        monthNavigator
                                        yearNavigator
                                        yearRange={"1900:2030"}
                                        onChange={(e) => { setPolicy({ ...policy, changed: true, polDataDo: moment(e.value).format() }); setChanged(true) }}
                                    />
                                    <button
                                        onClick={() =>
                                            setPolicy({ ...policy, polDataDo: moment(policy.polDataOd).add(1, 'M').add(-1, 'd').format() })
                                        }
                                        className="link-like-button m-2"
                                    >
                                        {policy.polDataDo === moment(policy.polDataOd).add(1, 'M').add(-1, 'd').format() ? '>' : ''}
                                        1 miesiąc
                                    </button>
                                    <button
                                        onClick={() =>
                                            setPolicy({ ...policy, polDataDo: moment(policy.polDataOd).add(3, 'M').add(-1, 'd').format() })
                                        }
                                        className="link-like-button m-2"
                                    >
                                        {policy.polDataDo === moment(policy.polDataOd).add(3, 'M').add(-1, 'd').format() ? '>' : ''}
                                        3 miesiące
                                    </button>
                                    <button
                                        onClick={() =>
                                            setPolicy({ ...policy, polDataDo: moment(policy.polDataOd).add(6, 'M').add(-1, 'd').format() })
                                        }
                                        className="link-like-button m-2"
                                    >
                                        {policy.polDataDo === moment(policy.polDataOd).add(6, 'M').add(-1, 'd').format() ? '>' : ''}
                                        6 miesięcy
                                    </button>
                                    <button
                                        onClick={() =>
                                            setPolicy({ ...policy, polDataDo: moment(policy.polDataOd).add(1, 'y').add(-1, 'd').format() })
                                        }
                                        className="link-like-button m-2"
                                    >
                                        {policy.polDataDo === moment(policy.polDataOd).add(1, 'y').add(-1, 'd').format() ? '>' : ''}
                                        1 rok
                                    </button>
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Waluta:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={currencies}
                                        value={policy.polWalID}
                                        emptyMessage="Brak wyników"
                                        onChange={(e) => { setPolicy({ ...policy, polWalID: e.value }); setChanged(true) }}
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Agent:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        disabled
                                        className="f-w"
                                        options={agents}
                                        value={policy.polAgentID}
                                        emptyMessage="Brak wyników"
                                    />
                                </div>
                            </div>
                            <div className="grid bg-gray-50 border-round m-2">
                                <div className="col-3 text-right font-bold line-height-4">
                                    Status:
                                </div>
                                <div className="col-9">
                                    <Dropdown
                                        className="f-w"
                                        options={policyStatus}
                                        value={policy.polStatusID}
                                        emptyMessage="Brak wyników"
                                        onChange={(e) => { setPolicy({ ...policy, polStatusID: e.value }); setChanged(true) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">

                            </div>
                            <div className="grid col-9">
                                <div className="col">
                                    <Checkbox checked={policy.polInnyAdr} /> Inny adres
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polKontynuacja == true}
                                        disabled={policy.polTypID !== 2}
                                    /> Kontynuacja
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polSprzedana == true}
                                    /> Sprzedana
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polAnulowana == true}
                                        onChange={(e) => { setPolicy({ ...policy, polAnulowana: e.checked, polDataAnulowania: e.checked ? moment().format() : null }); setChanged(true) }}
                                    /> Anulowana
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polPelnaTresc == true}
                                        onChange={(e) => { setPolicy({ ...policy, polPelnaTresc: e.checked, polDataPelTresc: e.checked ? moment().format() : null }); setChanged(true) }}
                                    /> Pełna treść
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polZatwierdzona == true}
                                    //onChange={(e) => {setPolicy({...policy, polZatwierdzona: e.checked,polDataZatw: e.checked ? moment().format() : null}); setChanged(true)}}
                                    /> Zatwierdzona
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polZerwana == true}
                                        onChange={(e) => { setPolicy({ ...policy, polZerwana: e.checked, polZerwanaData: e.checked ? moment().format() : null }); setChanged(true) }}
                                    /> Zerwana
                                </div>
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Uwagi
                            </div>
                            <div className="col-9">
                                <TabView activeIndex={remarksActiveTab} onTabChange={(e) => setRemarksActiveTab(e.index)}>
                                    <TabPanel header="Lista">
                                        <DataTable
                                            value={remarks}
                                            dataKey="uwID"
                                            emptyMessage="Brak wyników"
                                        >
                                            <Column field="uwUser" header="Użytkownik" className="col-2" />
                                            <Column field="uwData" header="Data" className="col-2" body={(rowData) => moment(rowData.uwData).format('DD/MM/YYYY')} />
                                            <Column field="uwTekst" header="Tekst" />
                                        </DataTable>
                                    </TabPanel>
                                    <TabPanel header="Nowa uwaga">
                                        <div className="grid justify-content-center pt-3 pb-1">
                                            <InputTextarea
                                                className="col-10"
                                                value={newRemark}
                                                onChange={(e) => setNewRemark(e.target.value)}
                                            />
                                            <Button onClick={async () => {
                                                let response = await policyService.addPolicyRemark(newRemark, policy.polID);
                                                if (response) {
                                                    setRemarks(response.remarks);
                                                    setNewRemark("");
                                                    setRemarksActiveTab(0);
                                                } else {
                                                    toast.current.show({ severity: 'error', summary: 'Błąd', detail: "Nie udało się zapisać uwagi!", closable: true });
                                                }
                                            }}
                                                icon="pi pi-fw pi-save"
                                                className="h-4rem w-4rem"
                                            />
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Druki dodatkowe
                            </div>
                            <div className="col-9">
                                <div className="grid">
                                        <Button
                                            icon="pi pi-fw pi-plus"
                                            className="mr-2 w-4rem"
                                            onClick={() => setAddPrintsVisible(true)}
                                        />
                                    <div className="flex-grow-1">
                                        <DataTable
                                            dataKey="ddID"
                                            value={otherPrints}
                                            onRowDoubleClick={(e) => { setSelectedPrint(e.data.ddID); setAddPrintsVisible(true) }}
                                            emptyMessage="Brak wyników"
                                        >
                                            <Column field="ddTekst" />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Inne druki
                            </div>
                            <div className="col-9">
                                <DataTable
                                    dataKey="druk_drID"
                                    value={additionalPrints}
                                    onContextMenuSelectionChange={e => setSelection(e.value)}
                                    onContextMenu={e => printsCM.current.show(e.originalEvent)}
                                    emptyMessage="Brak wyników"
                                    header={
                                        <div className="grid">
                                            <div className="m-1">
                                                Seria:
                                            </div>
                                            <div className="ml-1 mr-2">
                                                <Dropdown
                                                    className="f-w"
                                                    options={addPrintSeries.filter(s => s.ubz === policy.polUbzID)}
                                                    value={additionalPrint.pidSeriaID}
                                                    onChange={(e) => {
                                                        additionalPrint.pidSeriaID = e.value;
                                                        setAdditionalPrint({ ...additionalPrint });
                                                    }}
                                                    emptyMessage="Brak wyników"
                                                />
                                            </div>
                                            <div className="m-1">
                                                Numer:
                                            </div>
                                            <div className="ml-1">
                                                <InputMask
                                                    className="f-w"
                                                    disabled={additionalPrint.pidSeriaID === null}
                                                    mask={getNumberMask(additionalPrint.pidSeriaID ? addPrintSeries.find(s => s.value === additionalPrint.pidSeriaID).mask : 1)}
                                                    value={additionalPrint.pidNumer}
                                                    onChange={(e) => {
                                                        additionalPrint.pidNumer = e.value;
                                                        setAdditionalPrint({ ...additionalPrint });
                                                    }}
                                                />
                                            </div>
                                            <Button
                                                icon="pi pi-fw pi-plus"
                                                className="mb-3"
                                                onClick={() => {
                                                    if (additionalPrint.pidSeriaID && additionalPrint.pidNumer) {
                                                        if (additionalPrints.find(p => p.pidSeriaID === additionalPrint.pidSeriaID && p.drNumer === additionalPrint.pidNumer)) {
                                                            toast.current.show({ severity: 'warn', summary: "Błąd", detail: 'Ten dokument znajduję się już na liście!', closable: true })
                                                        } else {
                                                            additionalPrintsMod.add.push({ ...additionalPrint, polisa_polID: policy.polID, druk_drID: policy.polNumerID, pidID: (additionalPrints.length).toString(), pidSeria: policy.polSeriaTxt });
                                                            setAdditionalPrintsMod({ ...additionalPrintsMod, changed: true });
                                                            additionalPrints.push(additionalPrint);
                                                            setAdditionalPrint({});
                                                            setAdditionalPrints([...additionalPrints]);
                                                        }
                                                    } else {
                                                        toast.current.show({ severity: 'warn', summary: 'Błąd', detail: "Najpierw uzupełnij dane!", closable: true })
                                                    }
                                                }}
                                            />
                                            <ContextMenu
                                                model={[
                                                    {
                                                        label: "Usuń", icon: "pi pi-fw pi-trash", command: () => {
                                                            additionalPrintsMod.del.push(additionalPrints.find(p => p.pidID === selection.pidID));
                                                            setAdditionalPrintsMod({ ...additionalPrintsMod, changed: true });
                                                            setAdditionalPrints(additionalPrints.filter(p => p.pidID !== selection.pidID));
                                                        }
                                                    }
                                                ]}
                                                ref={printsCM}
                                            />
                                        </div>
                                    }
                                >
                                    <Column header="Seria" field="pidSeriaID" body={(rowData) => { return addPrintSeries.find(s => s.value === rowData.pidSeriaID).label }} />
                                    <Column header="Numer" field="pidNumer" />
                                </DataTable>
                            </div>
                        </div>
                    </Fieldset >
                    <Fieldset
                        legend="Ryzyka"
                        className="mt-3"
                    >
                        <div className="grid">
                            {/* <div className="m-2" style={{ width: "30px" }}>
                                <Button
                                    icon="pi pi-fw pi-plus"
                                    onClick={() => setAddRiskVisible(true)}
                                />
                            </div> */}
                            <div className="col">
                                {/* <ContextMenu
                                    model={[
                                        {
                                            label: "Usuń", icon: "pi pi-fw pi-trash", command: () => {
                                                risksMod.del.push(risks.find(r => r.ryzID === selection.ryzID));
                                                setRisksMod({ ...risksMod, changed: true });
                                                setRisks(risks.filter(r => r.ryzID !== selection.ryzID));
                                            }
                                        }
                                    ]}
                                    ref={riskCM}
                                /> */}
                                <DataTable
                                    value={risks}
                                    dataKey="ryzID"
                                    responsiveLayout='scroll'
                                    emptyMessage="Brak wyników"
                                    editMode="cell"
                                //onContextMenuSelectionChange={e => setSelection(e.value)}
                                //onContextMenu={e => riskCM.current.show(e.originalEvent)}
                                >
                                    <Column header="Ryzyko" field="ryzRyzID" style={{ width: "15%" }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={(rowData) => { return riskTypes.find(t => t.value === rowData.ryzRyzID).label }} />
                                    <Column header="Ochrona od" field="ryzDataOd" body={(rowData) => { return moment(rowData.ryzDataOd).format("DD/MM/YYYY") }} />
                                    <Column header="Ochrona do" field="ryzDataDo" body={(rowData) => { return moment(rowData.ryzDataDo).format("DD/MM/YYYY") }} />
                                    <Column header="Składka" field="ryzSklada" body={(rowData) => { return rowData.ryzSkladka.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                                    <Column header="Suma ubz" field="ryzSumaUbz" body={(rowData) => { return rowData.ryzSumaUbz ? rowData.ryzSumaUbz.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) : "" }} />
                                    <Column header="Ile rat" field="ryzIleRat" />
                                    {/* <Column header="B/M" field="ryzBM" body={(rowData) => { return rowData.ryzBM ? bms.find(t => t.value === rowData.ryzBM).label : "" }} /> */}
                                    <Column header="Komentarz" field="ryzKometarz" style={{ width: "35%" }} />
                                </DataTable>
                            </div>
                        </div>
                    </Fieldset>
                    <div className="grid col-12 justify-content-between mt-2 m-0">
                        <div>
                            {/* <Button
                                label="Zaplanuj raty"
                                icon="pi pi-fw pi-calendar"
                                onClick={() => calculateInstalments()}
                            /> */}
                        </div>
                        <div>
                            Składka razem: <label className="font-bold text-xl">{(risksMod.changed ? contributionSum() : policy.polSklCalk) ? policy.polSklCalk.toLocaleString('pl-PL', { style: 'currency', currency: policy.polWalID ? currencies.find(c => c.value === policy.polWalID).label : "PLN" }) : ",-"}</label>
                        </div>
                    </div>
                    <TabView className="mb-3 mt-2">
                        <TabPanel header="Podsumowanie rat">
                            <DataTable
                                value={instalments}
                                dataKey="rratID"
                                responsiveLayout='scroll'
                                emptyMessage="Brak wyników"
                                editMode="cell"
                            >
                                <Column header="Kwota" field="rrKwota" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={(rowData) => { return rowData.rrKwota.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                                <Column header="Termin płatności" field="rrData" body={(rowData) => { return rowData.rrData ? moment(rowData.rrData).format("DD/MM/YYYY") : "Brak" }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                <Column header="Rata nr" field="rrNr" />
                                <Column header="Inkaso" field="rrZaplat" body={(rowData) => { return <Checkbox checked={rowData.rrZaplat === 1} onChange={(e) => inkasoAction(rowData, e.checked)} /> }} />
                                <Column header="Zapłata cz." field="rrZaplatC" body={(rowData) => { return <Checkbox checked={rowData.rrZaplatC} /> }} />
                                <Column header="Kwota zapł." field="rrZaplatKwota" body={(rowData) => { return rowData.rrZaplatKwota.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                                <Column header="Pozostało" field="rrZaplatKwotaP" body={(rowData) => { return rowData.rrZaplatKwotaP.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                                <Column header="Data inkaso" field="rrDataZap" body={(rowData) => { return rowData.rrDataZap ? moment(rowData.rrDataZap).format("DD/MM/YYYY") : "Brak" }} />
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Inkaso">
                            <div className="grid">
                                <div className="col">
                                    <ContextMenu
                                        model={[
                                            {
                                                label: "Powiel", icon: "pi pi-fw pi-copy", command: () => addInkaso()
                                            },
                                            {
                                                label: "Usuń", icon: "pi pi-fw pi-trash", command: () => deleteInkaso()
                                            }
                                        ]}
                                        ref={inkasoCM}
                                    />
                                    <DataTable
                                        value={inkaso}
                                        dataKey="inkID"
                                        responsiveLayout='scroll'
                                        emptyMessage="Brak wyników"
                                        editMode="cell"
                                        onContextMenuSelectionChange={e => setSelection(e.value)}
                                        onContextMenu={e => inkasoCM.current.show(e.originalEvent)}
                                    >
                                        <Column header="Kwota zapłacona" field="inkKwota" body={(rowData) => { return rowData.inkKwota.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                        <Column header="Data inkaso" field="inkDataZap" body={(rowData) => { return rowData.inkDataZap ? moment(rowData.inkDataZap).format("DD/MM/YYYY") : "Brak" }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                        <Column header="Termin płatności" field="inkDataPlat" body={(rowData) => { return rowData.inkDataPlat ? moment(rowData.inkDataPlat).format("DD/MM/YYYY") : "Brak" }} />
                                        <Column header="Status" field="inkStatusID" body={(rowData) => { return inkasoStatus.find(s => s.value === rowData.inkStatusID).label }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                        <Column header="Forma płatności" field="inkFormaPlatID" body={(rowData) => { return paymentForms.find(f => f.value === rowData.inkFormaPlatID).label }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                        <Column header="Nr transakcji" field="inkNrTran" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                        <Column header="Nr raty" field="rraty_rrNr" />
                                        <Column header="Odsetki" field="inkOdsetki" body={(rowData) => { return (rowData.inkOdsetki || 0).toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                        <Column header="Nr trans. online" field="inkNumerPlatnosciOnline" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <TabView>
                        <TabPanel header="Pojazdy">
                            <div className="grid">
                                <div className="m-2" style={{ width: "30px" }}>
                                    <Button icon="pi pi-fw pi-plus" className="mb-3" onClick={() => setVehicleBrowserVisible(true)} />
                                </div>
                                <div className="col">
                                    <ContextMenu
                                        model={[
                                            {
                                                label: "Edytuj", icon: "pi pi-fw pi-pencil", command: () => setVehicleEditVisible(true)
                                            },
                                            {
                                                label: "Usuń", icon: "pi pi-fw pi-trash", command: () => { vehicleMod.del.push({ ...vehicles.find(v => v.pojID === selection.pojID), polisa_polID: policy.polID }); setVehicleMod({ ...vehicleMod, changed: true }); setVehicles(vehicles.filter(v => v.pojID !== selection.pojID)); }
                                            }
                                        ]}
                                        ref={vehicleCM}
                                    />
                                    <DataTable
                                        value={vehicles}
                                        dataKey="pojID"
                                        responsiveLayout='scroll'
                                        emptyMessage="Brak wyników"
                                        onContextMenuSelectionChange={e => setSelection(e.value)}
                                        onContextMenu={e => vehicleCM.current.show(e.originalEvent)}
                                    >
                                        <Column header="Nr rej." field="pojRejestracja" />
                                        <Column header="Marka" field="pojMarkaID" body={(rowData) => { return rowData.pojMarkaID ? vehicleBrands.find(m => m.value === rowData.pojMarkaID).label : "" }} />
                                        <Column header="Model" field="pojModelID" body={(rowData) => { return rowData.pojModelID ? vehicleModels.find(m => m.value === rowData.pojModelID).label : "" }} />
                                        <Column header="Typ" field="pojTyp" />
                                        <Column header="Rok prod" field="pojRokProd" />
                                        <Column header="Data p. rej." field="pojPierRej" body={(rowData) => { return rowData.pojPierRej ? moment(rowData.pojPierRej).format("DD/MM/YYYY") : "" }} />
                                        <Column header="VIN" field="pojVIN" />
                                        <Column header="Rodzaj" field="pojRodzajID" body={(rowData) => { return rowData.pojRodzajID ? vehicleKinds.find(k => k.value === rowData.pojRodzajID).label : "" }} />
                                        <Column header="Status" field="pojStatusID" body={(rowData) => { return rowData.pojStatusID ? vehicleStatus.find(s => s.value === rowData.pojStatusID).label : "" }} />
                                        <Column header="Pojemność" field="pojPojemnosc" />
                                        <Column header="Il. miejsc" field="pojIloMiejsc" />
                                        <Column header="Data badania" field="pojDataBadania" body={(rowData) => { return rowData.pojDataBadania ? moment(rowData.pojDataBadania).format("DD/MM/YYYY") : "" }} />
                                        <Column header="Przebieg" field="pojPrzebieg" />
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Inne obiekty">
                            <div className="grid">
                                <div className="m-2" style={{ width: "30px" }}>
                                    <Button icon="pi pi-fw pi-plus" className="mb-3" onClick={() => setObjectsBrowserVisible(true)} />
                                </div>
                                <div className="col">
                                    <ContextMenu
                                        model={[
                                            {
                                                label: "Usuń", icon: "pi pi-fw pi-trash", command: () => { otherObjsMod.del.push({ ...otherObjs.find(o => o.ouID === selection.ouID), polisa_polID: policy.polID }); setOtherObjsMod({ ...otherObjsMod, changed: true }); setOtherObjs(otherObjs.filter(o => o.ouID !== selection.ouID)); }
                                            }
                                        ]}
                                        ref={objCM}
                                    />
                                    <DataTable
                                        value={otherObjs}
                                        dataKey="ouID"
                                        responsiveLayout='scroll'
                                        emptyMessage="Brak wyników"
                                        onContextMenuSelectionChange={e => setSelection(e.value)}
                                        onContextMenu={e => objCM.current.show(e.originalEvent)}
                                    >
                                        <Column header="Typ" field="ouTypID" body={(rowData) => { return insObjTypes.find(t => t.value === rowData.ouTypID).label }} />
                                        <Column header="Nazwa" field="ouNazwa" />
                                        <Column header="Adres" field="ouAdres" />
                                        <Column header="Nr domu" field="ouNrDomu" />
                                        <Column header="Nr lokalu" field="ouNrLok" />
                                        <Column header="Kod pocz." field="ouKodPocz" />
                                        <Column header="Miasto" field="ouMiasto" />
                                        <Column header="Opis" style={{ width: "35%" }} field="ouOpis" />
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    {
                        // (changed || additionalPrintsMod.changed || policyUsersMod.changed ||
                        //     risksMod.changed || instalmentsMod.changed || inkasoMod.changed || vehicleMod.changed || otherObjsMod.changed) ?
                        //     <div className="grid col-12 justify-content-center mt-3">
                        //         <Button className="h-3rem col-4 sm:col-3 md:col-2" label="Zapisz" icon="pi pi-fw pi-save" onClick={() => saveChanges()} />
                        //     </div>
                        //     :
                        //     null
                    }


                    <Dialog
                        visible={addUserVisible}
                        resizable
                        dismissableMask
                        className="col-6"
                        onHide={() => {
                            setNewPolicyUser({});
                            setAddUserVisible(false)
                        }}
                        header="Podmioty"
                    >
                        <div className="grid justify-content-center">
                            <label className="col-1 pt-3 text-right">Typ:</label>
                            <div className="col-4">
                                <Dropdown
                                    className="f-w"
                                    options={clientUbzTypes}
                                    value={newPolicyUser.pkTypKl}
                                    onChange={(e) => {
                                        newPolicyUser.pkTypKl = e.value;
                                        setNewPolicyUser({ ...newPolicyUser });
                                    }}
                                    emptyMessage="Brak wyników"
                                />
                            </div>
                            <label className="col-1 pt-3 text-right">Nazwa:</label>
                            <div className="col-4">
                                <Button
                                    className="f-w"
                                    disabled={typeof newPolicyUser.pkTypKl === "undefined"}
                                    label={newPolicyUser.pkNazwa || "Szukaj"}
                                    onClick={() => setClientBrowserVisible(true)}
                                />
                            </div>

                            <Button
                                className="col-3 mt-5"
                                label="Dodaj wpis"
                                icon="pi pi-fw pi-plus"
                                onClick={() => {
                                    if (newPolicyUser.pkTypKl === 0) {
                                        policyUsers.push({ ...newPolicyUser, pkTypKl: 1, polisa_polID: policy.polID });
                                        policyUsers.push({ ...newPolicyUser, pkTypKl: 2, polisa_polID: policy.polID });
                                        policyUsersMod.add.push({ ...newPolicyUser, pkTypKl: 1, polisa_polID: policy.polID });
                                        policyUsersMod.add.push({ ...newPolicyUser, pkTypKl: 2, polisa_polID: policy.polID });
                                    } else {
                                        policyUsers.push({ ...newPolicyUser, polisa_polID: policy.polID });
                                        policyUsersMod.add.push({ ...newPolicyUser, polisa_polID: policy.polID });
                                    }
                                    setPolicyUsers([...policyUsers]);
                                    setPolicyUsersMod({ ...policyUsersMod, changed: true });
                                    setNewPolicyUser({});
                                    setAddUserVisible(false);
                                }}
                            />
                        </div>
                    </Dialog>

                    <Dialog
                        visible={clientBrowserVisible}
                        resizable
                        dismissableMask
                        className="col-10"
                        onHide={() => setClientBrowserVisible(false)}
                    >
                        <Clients
                            searchMode={true}
                            onChoice={async (id, name) => {
                                newPolicyUser.pkID = policyUsers.length;
                                newPolicyUser.pkNazwa = name;
                                newPolicyUser.klient_kliID = id;
                                let response = await clientService.getAddress(id);
                                newPolicyUser.pkAdres = response.kaAdres;
                                newPolicyUser.pkMiasto = response.kaMiasto;
                                const { kliTel } = await clientService.getDetails(id);
                                newPolicyUser.pkTelefon = kliTel;
                                setNewPolicyUser({ ...newPolicyUser });
                                setClientBrowserVisible(false);
                            }} />
                    </Dialog>
                    <Dialog
                        visible={addRiskVisible}
                        resizable
                        dismissableMask
                        className="col-8"
                        onHide={() => setAddRiskVisible(false)}
                        onShow={() => setNewRisk({ ryzDataOd: policy.polDataOd, ryzDataDo: policy.polDataDo, ryzSumaUbz: 0, ryzSkladka: 0 })}
                        header="Podmioty"
                    >
                        <div className="grid justify-content-center">
                            <label className="col-2 pt-3 text-right">Typ:</label>
                            <div className="col-4">
                                <Dropdown
                                    className="f-w"
                                    options={policy.polUbzID ? riskTypes.filter(t => t.ubz === policy.polUbzID) : riskTypes}
                                    value={newRisk.ryzRyzID}
                                    onChange={(e) => {
                                        newRisk.ryzRyzID = e.value;
                                        setNewRisk({ ...newRisk });
                                    }}
                                    filter
                                    filterPlaceholder="Szukaj..."
                                    emptyMessage="Brak wyników"
                                    emptyFilterMessage="Brak pasujących wyników"
                                />
                            </div>
                            <div className="col-6"></div>
                            <label className="col-2 pt-3 text-right">
                                Ochrona od:
                            </label>
                            <div className="col-4">
                                <Calendar
                                    className="f-w"
                                    showIcon
                                    dateFormat="dd/mm/yy"
                                    value={moment(newRisk.ryzDataOd).toDate()}
                                    onChange={(e) => {
                                        newRisk.ryzDataOd = moment(e.value).format();
                                        setNewRisk({ ...newRisk });
                                    }}
                                    monthNavigator
                                    yearNavigator
                                    yearRange={"1900:2030"}
                                />
                            </div>
                            <label className="col-2 pt-3 text-right">
                                Ochrona do:
                            </label>
                            <div className="col-4">
                                <Calendar
                                    className="f-w"
                                    showIcon
                                    dateFormat="dd/mm/yy"
                                    value={moment(newRisk.ryzDataDo).toDate()}
                                    onChange={(e) => {
                                        newRisk.ryzDataDo = moment(e.value).format();
                                        setNewRisk({ ...newRisk });
                                    }}
                                    monthNavigator
                                    yearNavigator
                                    yearRange={"1900:2030"}
                                />
                            </div>
                            <label className="col-2 pt-3 text-right">
                                Składka:
                            </label>
                            <div className="col-4">
                                <InputNumber
                                    className="f-w"
                                    mode="currency"
                                    currency="PLN"
                                    showButtons
                                    value={newRisk.ryzSkladka}
                                    onValueChange={(e) => {
                                        newRisk.ryzSkladka = e.value;
                                        setNewRisk({ ...newRisk });
                                    }}
                                />
                            </div>
                            <label className="col-2 pt-3 text-right">
                                Suma ubz:
                            </label>
                            <div className="col-4">
                                <InputNumber
                                    className="f-w"
                                    mode="currency"
                                    currency="PLN"
                                    showButtons
                                    value={newRisk.ryzSumaUbz}
                                    onValueChange={(e) => {
                                        newRisk.ryzSumaUbz = e.value;
                                        setNewRisk({ ...newRisk });
                                    }}
                                />
                            </div>
                            <label className="col-2 pt-3 text-right">
                                Ile rat:
                            </label>
                            <div className="col-4">
                                <Dropdown
                                    className="f-w"
                                    options={[1, 2, 3, 4, 6, 11, 12]}
                                    value={newRisk.ryzIleRat}
                                    onChange={(e) => {
                                        newRisk.ryzIleRat = e.value;
                                        setNewRisk({ ...newRisk });
                                    }}
                                />
                            </div>
                            <label className="col-2 pt-3 text-right">
                                B/M:
                            </label>
                            <div className="col-4">
                                <Dropdown
                                    className="f-w"
                                    options={bms}
                                    value={newRisk.ryzBM}
                                    onChange={(e) => {
                                        newRisk.ryzBM = e.value;
                                        setNewRisk({ ...newRisk });
                                    }}
                                    emptyMessage="Brak wyników"
                                />
                            </div>
                            <label className="col-2 pt-3 text-right">
                                Komentarz:
                            </label>
                            <div className="col-10">
                                <InputTextarea
                                    className="f-w"
                                    value={newRisk.ryzKometarz}
                                    onChange={(e) => {
                                        newRisk.ryzKometarz = e.target.value;
                                        setNewRisk({ ...newRisk });
                                    }}
                                />
                            </div>

                            <Button
                                className="col-3 mt-5"
                                label="Dodaj wpis"
                                icon="pi pi-fw pi-plus"
                                onClick={() => {
                                    if (newRisk.ryzRyzID && newRisk.ryzSkladka && newRisk.ryzIleRat) {
                                        newRisk.ryzID = risks.length;
                                        setNewRisk({ ...newRisk });
                                        risksMod.add.push(newRisk);
                                        setRisksMod({ ...risksMod, changed: true });
                                        risks.push(newRisk);
                                        setRisks([...risks]);
                                        setAddRiskVisible(false);
                                    } else {
                                        let str = 'Brak wymaganych danych: ';
                                        let c = 0;
                                        if (typeof newRisk.ryzRyzID === 'undefined') {
                                            str = str.concat("typ");
                                            c++;
                                        }
                                        if (typeof newRisk.ryzSkladka === 'undefined') {
                                            if (c > 0)
                                                str = str.concat(', ')
                                            str = str.concat("składka");
                                            c++;
                                        }
                                        if (typeof newRisk.ryzIleRat === 'undefined') {
                                            if (c > 0)
                                                str = str.concat(', ')
                                            str = str.concat('ilość rat');
                                            c++;
                                        }
                                        toast.current.show({ severity: 'warn', summary: "Błąd", detail: str, closable: true })
                                    }
                                }}
                            />
                        </div>
                    </Dialog>

                    <Dialog
                        visible={vehicleBrowserVisible}
                        resizable
                        dismissableMask
                        className="col-8"
                        onHide={() => {

                            setVehicleBrowserVisible(false)
                        }}
                        header="Pojazdy"
                    >
                        <Vehicles
                            searchMode={true}
                            onChoice={async (id, regNr) => {
                                let vehicle = await vehicleService.getDetails(id);
                                if (vehicles.find(v => v.pojID === vehicle.pojID))
                                    toast.current.show({ severity: 'warn', summary: "Błąd", detail: 'Ten pojazd znajduję się już na liście!', closable: true })
                                else {
                                    vehicles.push({ ...vehicle, polisa_polID: policy.polID });
                                    vehicleMod.add.push({ ...vehicle, polisa_polID: policy.polID });
                                    setVehicleMod({ ...vehicleMod, changed: true });
                                    setVehicles([...vehicles]);
                                }
                                setVehicleBrowserVisible(false);
                            }}
                        />
                    </Dialog>

                    <Dialog
                        visible={objectsBrowserVisible}
                        resizable
                        dismissableMask
                        className="col-10"
                        onHide={() => {

                            setObjectsBrowserVisible(false)
                        }}
                        header="Obiekty"
                    >
                        <InsuranceObjects
                            searchMode={true}
                            onChoice={async (id, regNr) => {
                                let otherObj = await objectsService.getDetails(id);
                                if (otherObjs.find(o => o.ouID === otherObj.ouID))
                                    toast.current.show({ severity: 'warn', summary: "Błąd", detail: 'Ten obiekt znajduję się już na liście!', closable: true })
                                else {
                                    otherObjs.push({ ...otherObj, polisa_polID: policy.polID });
                                    otherObjsMod.add.push({ ...otherObj, polisa_polID: policy.polID });
                                    setOtherObjsMod({ ...otherObjsMod, changed: true });
                                    setOtherObjs([...otherObjs]);
                                }
                                setObjectsBrowserVisible(false);
                            }}
                        />
                    </Dialog>

                    <Dialog
                        visible={policyBrowserVisible}
                        resizable
                        dismissableMask
                        className={annexInput ? "col-6" : "col-10"}
                        onHide={() => {
                            setAnnexInput(true);
                            setNewAnnex({});
                            setPolicyBrowserVisible(false)
                        }}
                        header={policy.polTypID === 4 ? "Aneks" : "Polisa"}
                    >
                        {policy.polTypID === 4 && annexInput ?
                            <div className="grid col-12 h-8rem justify-content-center align-content-center">
                                <div className="col-2 pr-0">
                                    <span className="p-float-label">
                                        <InputText
                                            id="annexSeriesInput"
                                            className="f-w"
                                            value={newAnnex.popAnSeriaTxt}
                                            onChange={(e) => setNewAnnex({ ...newAnnex, popAnSeriaTxt: e.target.value })}
                                        />
                                        <label htmlFor="annexSeriesInput">Seria</label>
                                    </span>
                                </div>
                                <div className="col-3 pl-0 pr-0">
                                    <span className="p-float-label f-w">
                                        <InputText
                                            id="annexNumberInput"
                                            className="f-w"
                                            value={newAnnex.popAnNumerTxt}
                                            onChange={(e) => setNewAnnex({ ...newAnnex, popAnNumerTxt: e.target.value })}
                                        />
                                        <label htmlFor="annexNumberInput">Numer</label>
                                    </span>
                                </div>
                                <div className="col-1 pl-0">
                                    <Button
                                        className="f-w"
                                        icon="pi pi-fw pi-chevron-circle-right"
                                        onClick={() => {
                                            setPolicy({ ...policy, popAneksGID: newAnnex.popAneksGID, popAnSeriaTxt: newAnnex.popAnSeriaTxt });
                                            setChanged(true);
                                            setNewAnnex({});
                                            setPolicyBrowserVisible(false);
                                        }}
                                    />
                                </div>
                                <div className="col-4">
                                    <Button
                                        className="f-w"
                                        label="Wybierz z listy"
                                        icon="pi pi-fw pi-search"
                                        onClick={() => setAnnexInput(false)}
                                    />
                                </div>
                            </div>
                            :
                            < Policies
                                searchMode={true}
                                onChoice={async (id, number) => {
                                    let response = await policyService.getDetails(id);
                                    if (policy.polTypID === 4) {
                                        policy.popAneksGID = id;
                                        policy.popAnNumerTxt = number;
                                        policy.popAnSeriaTxt = response.polSeriaTxt;
                                    } else {
                                        let prev = await policyService.getPrevPolicyData(id);
                                        policy.polKontynuacja = true;
                                        policy.polPoprzID = id;
                                        policy.polPopNumerTxt = number;
                                        policy.polPopSeriaTxt = response.polSeriaTxt;
                                        policy.polWalID = response.polWalID;
                                        policy.polUbzID = response.polUbzID;
                                        policy.polSeriaID = response.polSeriaID;
                                        policy.polPelnaTresc = response.polPelnaTresc;
                                        policy.polSeriaTxt = response.polSeriaTxt;
                                        setAdditionalPrints(prev.additionalPrints);
                                        setPolicyUsers(prev.policyUsers);
                                        setVehicles(prev.vehicles);
                                        setOtherObjs(prev.otherObjs);
                                    }
                                    setPolicy({ ...policy });
                                    setChanged(true);
                                    setAnnexInput(true);
                                    setPolicyBrowserVisible(false);
                                }}
                            />
                        }
                    </Dialog>

                    <Dialog
                        visible={vehicleEditVisible}
                        header={"Pojazd " + selection.pojRejestracja}
                        onHide={() => setVehicleEditVisible(false)}
                        dismissableMask
                        maximizable
                        className='col-8'
                    >
                        <VehicleDetails
                            id={selection.pojID}
                            close={() => setVehicleEditVisible(false)}
                            success={async () => {
                                toast.current.show({ severity: 'success', summary: 'Pomyślnie zapisano zmiany!', closable: true });
                                let vehicle = await vehicleService.getDetails(selection.pojID);
                                vehicles[vehicles.findIndex(v => v.pojID === selection.pojID)] = vehicle;
                                setVehicles([...vehicles]);
                            }}
                        />
                    </Dialog>

                    <Dialog
                        visible={addPrintsVisible}
                        header="Druk dodatkowy"
                        onHide={() => { setAddPrintsVisible(false); setTimeout(() => setSelectedPrint(null), 1000) }}
                        dismissableMask
                        maximizable
                        className='col-12 lg:col-10'
                    >
                        <AddAdditionalPrint
                            id={selectedPrint}
                            policy={policy}
                            tuList={ubzs}
                            agents={agents}
                            user={props.user}
                            vehicles={vehicles}
                            otherObjs={otherObjs}
                            onCreate={() => {
                                setAddPrintsVisible(false);
                                toast.current.show({ severity: 'success', summary: 'Sukces', detail: 'Pomyślnie dodano druk!' });
                            }}
                        />
                    </Dialog>
                </div >
            }
        </>
    )
}