import { useEffect } from "react";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { ContextMenu } from "primereact/contextmenu";
import dictionaryService from "../../services/dictionaryService";
import userService from "../../services/userService";
import moment from "moment";
import Clients from "../Clients";
import clientService from "../../services/clientService";
import { useRef } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import Vehicles from "../Vehicles";
import vehicleService from "../../services/vehicleService";
import InsuranceObjects from "../InsuranceObjects";
import objectsService from "../../services/objectsService";
import Policies from "../Policies";
import policyService from "../../services/policyService";
import VehicleDetails from "../details/VehicleDetails";
import { InputMask } from "primereact/inputmask";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";


export default function AddPolicy(props) {

    const [policyTypes, setPolicyTypes] = useState([]);
    const [policyStatus, setPolicyStatus] = useState([]);
    const [currencies, setCurrencies] = useState([{ value: 1, label: "PLN" }]);
    const [ubzs, setUbzs] = useState([]);
    const [agents, setAgents] = useState([]);
    const [series, setSeries] = useState([]);
    const [clientUbzTypes, setClientUbzTypes] = useState([]);
    const [riskTypes, setRiskTypes] = useState([]);
    //const [bms, setBms] = useState([]);
    const [inkasoStatus, setInkasoStatus] = useState([]);
    const [paymentForms, setPaymentForms] = useState([]);
    const [vehicleBrands, setVehicleBrands] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [vehicleKinds, setVehicleKinds] = useState([]);
    const [vehicleStatus, setVehicleStauts] = useState([]);
    const [insObjTypes, setInsObjTypes] = useState([]);
    const [addPrintSeries, setAddPrintSeries] = useState([])

    const [addUserVisible, setAddUserVisible] = useState(false);
    const [clientBrowserVisible, setClientBrowserVisible] = useState(false);
    const [addRiskVisible, setAddRiskVisible] = useState(false);
    const [vehicleBrowserVisible, setVehicleBrowserVisible] = useState(false);
    const [objectsBrowserVisible, setObjectsBrowserVisible] = useState(false);
    const [policyBrowserVisible, setPolicyBrowserVisible] = useState(false);
    const [vehicleEditVisible, setVehicleEditVisible] = useState(false);
    //const [remarksVisible, setRemarksVisible] = useState(false);
    const [remarksActiveTab, setRemarksActiveTab] = useState(0);
    const [annexInput, setAnnexInput] = useState(true);

    const [policy, setPolicy] = useState({
        polSeriaID: null,
        polNumerTxt: "",
        polTypID: 1,
        polStatusID: 1,
        polWalID: 1,
        polPelnaTresc: 0,
        polDataZaw: moment().format(),
        polDataOd: moment().format(),
        polDataDo: moment().add(1, 'y').add(-1, 'd').format(),
    });
    const [policyUsers, setPolicyUsers] = useState([]);
    const [newPolicyUser, setNewPolicyUser] = useState({});
    const [newAnnex, setNewAnnex] = useState({});
    const [risks, setRisks] = useState([]);
    const [newRisk, setNewRisk] = useState({
        ryzDataOd: moment().toDate(),
        ryzDataDo: moment().add(1, 'y').add(-1, 'd').toDate()
    });
    const [instalments, setInstalments] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [otherObjs, setOtherObjs] = useState([]);
    const [additionalPrints, setAdditionalPrints] = useState([]);
    const [additionalPrint, setAdditionalPrint] = useState({});
    const [inkaso, setInkaso] = useState([]);
    const [remarks, setRemarks] = useState([]);
    const [newRemark, setNewRemark] = useState("");

    const [selection, setSelection] = useState({});
    const riskCM = useRef(null);
    const inkasoCM = useRef(null);
    const usersCM = useRef(null);
    const vehicleCM = useRef(null);
    const objCM = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        const loadDictionary = async () => {
            let response = await dictionaryService.getPolicyTypes();
            setPolicyTypes(response);
            response = await dictionaryService.getPolicyStatus();
            setPolicyStatus(response);
            response = await dictionaryService.getCurrencies();
            setCurrencies(response);
            response = await dictionaryService.getTUs(true);
            setUbzs(response);
            response = await userService.getList();
            let agent = response.find(a => userService.getCurrentID() === a.value);
            if (agent && agent.officeID === 5) {
                setPolicy(p => ({ ...p, polAgentID: 159 }));
            } else if (agent) {
                setPolicy(p => ({ ...p, polAgentID: agent.value }));
            }
            setAgents(response);
            response = await dictionaryService.getSeries();
            setSeries(response);
            response = await dictionaryService.getClientUbzTypes();
            response.unshift({ label: "Ubezpieczający/Ubezpieczony", value: 0 });
            setClientUbzTypes(response);
            response = await dictionaryService.getRiskTypes();
            setRiskTypes(response);
            // response = await dictionaryService.getBms();
            // setBms(response);
            response = await dictionaryService.getInkasoStatus();
            setInkasoStatus(response);
            response = await dictionaryService.getPaymentForms();
            setPaymentForms(response);
            response = await dictionaryService.getVehicleBrands();
            setVehicleBrands(response);
            response = await dictionaryService.getVehicleModels();
            setVehicleModels(response);
            response = await dictionaryService.getVehicleKinds();
            setVehicleKinds(response);
            response = await dictionaryService.getVehicleStatus();
            setVehicleStauts(response);
            response = await dictionaryService.getObjectTypes();
            setInsObjTypes(response);
            response = await dictionaryService.getAddPrintSeries();
            setAddPrintSeries(response);
        }

        loadDictionary();

    }, [])

    useEffect(() => {
        let instMap = new Map();
        risks.forEach((risk) => {
            var rest = ((risk.ryzSkladka * 100) % risk.ryzIleRat) / 100;
            var startDate;
            var dateStep;
            if (risk.ryzIleRat === 11) {
                startDate = moment(risk.ryzDataOd).add(1, 'week').toDate();
                dateStep = 1;
            }
            else {
                startDate = risk.ryzDataOd;
                dateStep = 12 / risk.ryzIleRat;
            }
            instMap.set(moment(startDate).format('L'), (instMap.get(moment(startDate).format('L')) || 0) + (risk.ryzSkladka - rest) / risk.ryzIleRat + rest);
            if (risk.ryzIleRat !== 11)
                startDate = moment(startDate).add(-1, 'd');
            for (var i = 2; i <= risk.ryzIleRat; i++) {
                instMap.set(moment(startDate).add(dateStep * (i - 1), 'month').format('L'),
                    (instMap.get(moment(startDate).add(dateStep * (i - 1), 'month').format('L')) || 0) + (risk.ryzSkladka - rest) / risk.ryzIleRat);
            }
        });
        var idx = 0;
        var arr = [];
        instMap = [...instMap.entries()].sort((a, b) => moment(a[0]).isAfter(moment(b[0])) ? 1 : -1);
        for (var entry of instMap) {
            idx++;
            arr.push({ rrKwota: entry[1], rrData: moment(entry[0], 'L').format(), rrNr: idx, rrZaplat: false, rrZaplatC: false, rrZaplatKwota: 0, rrZaplatKwotaP: entry[1], rrDataZap: null });
        };
        setInstalments(arr);
        setInkaso([]);

    }, [risks])

    const getNumberMask = (arg) => {
        var m = "";
        for (var i = 1; i <= arg; i++)
            m = m.concat("*");
        return m;
    }

    const confirmNumber = (arg) => {
        let number;
        let mask;
        switch (arg) {
            case 0:
                number = policy.polNumerTxt;
                mask = series.find(s => s.value === policy.polSeriaID).mask;
                break;
            case 1:
                number = additionalPrint.drNumer;
                mask = addPrintSeries.find(s => s.value === additionalPrint.drukdef_drdID).mask;
                break;
            default:
                break;
        }

        confirmDialog({
            closable: false,
            message: `Czy mimo to chcesz użyć numeru '${number}' (dł: ${number.length})?`,
            header: `Podany numer nie ma wymaganej długości (dł:${mask})`,
            icon: 'pi pi-fw pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Tak',
            acceptIcon: 'pi pi-fw pi-check',
            rejectLabel: 'Nie',
            rejectIcon: 'pi pi-fw pi-times',
            accept: () => { },
            reject: () => {
                switch (arg) {
                    case 0:
                        setPolicy({ ...policy, polNumerTxt: '' });
                        break;
                    case 1:
                        setAdditionalPrint({ ...additionalPrint, drNumer: '' })
                        break;
                    default:
                        break;
                }
            }
        });
    }

    const contributionSum = () => {
        var sum = 0;
        risks.forEach((r) => sum += r.ryzSkladka);
        policy.polSklCalk = sum;
        return sum;
    }

    const inkasoAction = (data, checked) => {
        let idx = instalments.findIndex(i => i === data);
        let inst = instalments[idx];
        if (checked) {
            if (idx === 0 || instalments[idx - 1].rrZaplat) {

                inst.rrZaplat = checked;
                inst.rrDataZap = moment().format();
                inst.rrZaplatKwotaP = 0;
                inst.rrZaplatKwota = data.rrKwota;
                inkaso.push({
                    inkDataZap: moment().format(),
                    inkDataPlat: inst.rrData,
                    inkKwota: data.rrZaplatKwota,
                    rrKwota: data.rrKwota,
                    inkOdsetki: 0,
                    rraty_rrNr: inst.rrNr,
                    inkStatusID: 2,
                    inkFormaPlatID: 1,
                    inkNrTran: "",
                    inkNumerPlatnosciOnline: ""
                })
            }
        } else {
            for (var i = idx; i < instalments.length; i++) {
                instalments[i].rrZaplat = checked;
                instalments[i].rrDataZap = null
                instalments[i].rrZaplatKwotaP = data.rrKwota;
                instalments[i].rrZaplatKwota = 0;
                instalments[i].rrZaplatC = false;
                // eslint-disable-next-line no-loop-func
                inkaso.splice(inkaso.findIndex(ink => ink.rraty_rrNr === instalments[i].rrNr), 1)
            }
        }
        setInkaso([...inkaso]);
        setInstalments([...instalments]);
    }

    const cellEditor = (event) => {
        switch (event.field) {
            case "inkKwota":
                return <InputNumber
                    className="f-w"
                    mode="currency"
                    currency="PLN"
                    value={event.value}
                    onValueChange={(e) => {
                        let sum = 0;
                        for (var i in inkaso) {
                            if (inkaso[i].rraty_rrNr === event.rowData.rraty_rrNr && inkaso[i].inkDataZap !== event.rowData.inkDataZap)
                                sum += inkaso[i].inkKwota;
                        }
                        sum += e.value;
                        if (sum > event.rowData.rrKwota)
                            toast.current.show({ severity: 'warn', summary: 'Błąd', detail: 'Za wysoka wartość!', closable: true })
                        else if (e.value < 0)
                            toast.current.show({ severity: 'warn', summary: 'Błąd', detail: 'Za niska wartość!', closable: true })
                        else
                            event.editorCallback(e.value);
                    }}
                />
            case "rrKwota":
            case "inkOdsetki":
                return <InputNumber
                    className="f-w"
                    mode="currency"
                    currency="PLN"
                    value={event.value}
                    onValueChange={(e) => event.editorCallback(e.value)}
                />
            case "inkDataZap":
            case "rrData":
                return <Calendar
                    className="f-w"
                    dateFormat="dd/mm/yy"
                    value={moment(event.value).toDate()}
                    onChange={(e) => event.editorCallback(moment(e.value).format())}
                    monthNavigator
                    yearNavigator
                    yearRange={"1900:2030"}
                />
            case "inkFormaPlatID":
                return <Dropdown
                    className="f-w"
                    value={event.value}
                    options={paymentForms.filter(f => [1, 2, 3, 5].includes(f.value))}
                    onChange={(e) => { inkaso[event.rowIndex][event.field] = e.value; event.editorCallback(e.value) }}
                    emptyMessage="Brak wyników"
                />
            case "inkStatusID":
                return <Dropdown
                    className="f-w"
                    value={event.value}
                    options={inkasoStatus}
                    onChange={(e) => { inkaso[event.rowIndex][event.field] = e.value; event.editorCallback(e.value) }}
                    emptyMessage="Brak wyników"
                />
            default:
                return <InputText
                    className="f-w"
                    keyfilter={/[^^[\]!@#$%*()&"',:=+{};<>?\\/|]/}
                    value={event.value}
                    onChange={(e) => event.editorCallback(e.target.value)}
                />
        }
    }

    const onCellEditComplete = (event) => {
        event.rowData[event.field] = event.newRowData[event.field];
        let ins;
        let sum;
        switch (event.field) {
            case "inkKwota":
                ins = instalments.find(i => i.rrNr === event.rowData.rraty_rrNr);
                sum = 0;
                for (var i in inkaso) {
                    if (inkaso[i].rraty_rrNr === event.rowData.rraty_rrNr && inkaso[i].inkDataZap !== event.rowData.inkDataZap)
                        sum += inkaso[i].inkKwota;
                }
                sum += event.newValue;
                ins.rrZaplatKwotaP = ins.rrKwota - sum;
                ins.rrZaplatKwota = sum;
                ins.rrZaplatC = sum < ins.rrKwota && ins.rrZaplat;
                break;
            case "rrData":
                for (let ink in inkaso.filter(i => i.rraty_rrNr === event.rowData.rrNr)) {
                    inkaso[ink].inkDataPlat = event.newValue;
                }
                break;
            case "inkDataZap":
                ins = instalments.find(i => i.rrNr === event.rowData.rraty_rrNr);
                ins.rrDataZap = event.newValue;
                break;
            case "rrKwota":
                ins = instalments.find(i => i.rrNr === event.rowData.rrNr);
                sum = 0;
                for (var j in inkaso) {
                    if (inkaso[j].rraty_rrNr === event.rowData.rrNr) {
                        sum += inkaso[j].inkKwota;
                        inkaso[j].rrKwota = event.newValue;
                    }
                }
                ins.rrZaplatKwotaP = ins.rrKwota - sum;
                ins.rrZaplatKwota = sum;
                ins.rrZaplatC = sum < ins.rrKwota && ins.rrZaplat;
                break;
            default:
                break;
        }
        setInkaso([...inkaso]);
        setInstalments([...instalments]);
    }

    const addInkaso = () => {
        let inst = instalments.find(i => i.rrNr === selection.rraty_rrNr);
        let sum = 0;
        for (var i in inkaso) {
            if (inkaso[i].rraty_rrNr === inst.rrNr)
                sum += inkaso[i].inkKwota;
        }
        inst.rrZaplatKwotaP = 0;
        inst.rrZaplatKwota = inst.rrKwota;
        inst.rrZaplatC = false;
        inkaso.push({
            inkDataZap: moment().toDate(),
            inkDataPlat: inst.rrData,
            inkKwota: inst.rrKwota - sum,
            rrKwota: inst.rrKwota,
            inkOdsetki: 0,
            inkWplataObca: 0,
            raty_ratID: 0,
            rraty_rrNr: inst.rrNr,
            inkStatusID: 2,
            inkFormaPlatID: 1,
            inkNrTran: "",
            inkNumerPlatnosciOnline: ""
        })
        setInkaso([...inkaso]);
        setInstalments([...instalments]);
    }

    const deleteInkaso = () => {
        let tmp = inkaso.filter(i => i.inkDataZap !== selection.inkDataZap);
        if (tmp.length) {
            let inst = instalments.find(i => i.rrNr === selection.rraty_rrNr);
            let sum = 0;
            for (var i in tmp) {
                if (tmp[i].rraty_rrNr === inst.rrNr)
                    sum += tmp[i].rrKwota;
            }
            inst.rrZaplatKwotaP = inst.rrKwota - sum;
            inst.rrZaplatKwota = sum;
            inst.rrZaplatC = inst.rrZaplatKwotaP > 0;
            setInkaso([...tmp]);
            setInstalments([...instalments]);
        }

    }

    const savePolicy = async () => {
        let insSum = 0;
        for (let i in instalments)
            insSum += instalments[i].rrKwota;
        let vehicleRequired = false;
        for (let r in risks) {
            vehicleRequired = riskTypes.find(risk => risk.value === risks[r].ryzRyzID).label.toLowerCase().includes("komunikacja");
        }
        if (policy.polUbzID && policy.polSeriaID && policy.polNumerTxt && policy.polAgentID && risks.length && (vehicleRequired ? vehicles.length : true) && inkaso.length > 0) {
            if (insSum.toFixed(2) === contributionSum().toFixed(2)) {
                let result = await policyService.addNew(policy, policyUsers, additionalPrints, instalments, risks, vehicles, otherObjs, inkaso, remarks);
                if (result?.polID) {
                    if (props.withReturn) {
                        props.onReturn(result.polID, result.polNumerTxt);
                    }
                    else {
                        toast.current.show({ severity: 'success', summary: "Pomyślnie", detail: 'dodano polisę!', closable: true })
                        window.location.reload(false);
                    }
                }
                else
                    toast.current.show({ severity: 'error', summary: "Błąd", detail: 'Nie udało się dodać polisy!', closable: true })
            }
            else
                toast.current.show({ severity: 'warn', summary: "Błąd", detail: 'Suma kwot rat jest różna od sumy składek!', closable: true })
        } else {
            let str = 'Brak wymaganych danych: ';
            let c = 0;
            if (typeof policy.polUbzID === 'undefined') {
                str = str.concat("ubezpieczalnia");
                c++;
            }
            if (typeof policy.polAgentID === 'undefined') {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat("agent");
                c++;
            }
            if (typeof policy.polSeriaID === 'undefined') {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('seria');
                c++;
            }
            if (typeof policy.polNumerTxt === 'undefined') {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('numer');
                c++;
            }
            if (risks.length === 0) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('produkt');
            }
            if (vehicleRequired ? vehicles.length === 0 : false) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('pojazd lub obiekt');
            }
            if (inkaso.length === 0) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('inkaso');
            }
            toast.current.show({ severity: 'warn', summary: "Błąd", detail: str, closable: true })
        }
    }

    return (
        <>
            <div className="p-3">
                <Toast ref={toast} />
                <ConfirmDialog />
                <Fieldset
                    legend="Klient / Osoby powiązane"
                >
                    <div className="grid col-12">
                        <div style={{ width: "30px" }} className="m-2">
                            <Button
                                icon="pi pi-fw pi-plus"
                                onClick={() => setAddUserVisible(true)}
                            />
                        </div>
                        <div className="col">
                            <ContextMenu
                                model={[
                                    { label: "Usuń", icon: "pi pi-fw pi-trash", command: () => setPolicyUsers(policyUsers.filter(u => u.klient_kliID !== selection.klient_kliID)) }
                                ]}
                                ref={usersCM}
                            />
                            <DataTable
                                value={policyUsers}
                                dataKey="klient_kliID"
                                responsiveLayout='scroll'
                                emptyMessage="Brak wyników"
                                onContextMenuSelectionChange={e => setSelection(e.value)}
                                onContextMenu={e => usersCM.current.show(e.originalEvent)}
                            >
                                <Column header="Typ" field="pkTypKl" body={(rowData) => { return clientUbzTypes.find(t => t.value === rowData.pkTypKl).label }} />
                                <Column header="Nazwa" field="pkNazwa" />
                                <Column header="Adres" field="pkAdres" />
                                <Column header="Miasto" field="pkMiasto" />
                                <Column header="Telefon" field="pkTelefon" />
                            </DataTable>
                        </div>
                    </div>
                </Fieldset>
                <Fieldset
                    legend="Polisa"
                    className="my-3"
                >
                    <div className="col-12">
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Typ polisy:
                            </div>
                            <div className="col-9">
                                <Dropdown
                                    className="f-w"
                                    options={policyTypes}
                                    value={policy.polTypID}
                                    onChange={(e) => {
                                        policy.polPoprzID = null;
                                        policy.polPopNumerTxt = null;
                                        policy.polPopSeriaTxt = null;
                                        policy.popAnNumerTxt = null;
                                        policy.popAneksGID = null;
                                        policy.popAnSeriaTxt = null;
                                        policy.polKontynuacja = false;
                                        policy.polTypID = e.value;
                                        setPolicy({ ...policy });
                                    }}
                                    emptyMessage="Brak wyników"
                                />
                            </div>
                        </div>
                        {
                            policy.polTypID === 2 ?
                                <div className="grid bg-gray-50 border-round m-2">
                                    <div className="col-3 text-right font-bold line-height-4">
                                        Poprzednia polisa:
                                    </div>
                                    <div className="col-9">
                                        <Button
                                            //className="f-w"
                                            label={policy.polPoprzID ? policy.polPopSeriaTxt + "/" + policy.polPopNumerTxt : "Wybierz"}
                                            onClick={() => setPolicyBrowserVisible(true)}
                                        />
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            policy.polTypID === 4 ?
                                <div className="grid bg-gray-50 border-round m-2">
                                    <div className="col-3 text-right font-bold line-height-4">
                                        Aneks do polisy:
                                    </div>
                                    <div className="col-9">
                                        <Button
                                            //className="f-w"
                                            label={policy.popAnNumerTxt ? policy.popAnSeriaTxt + "/" + policy.popAnNumerTxt : "Wybierz"}
                                            onClick={() => setPolicyBrowserVisible(true)}
                                        />
                                    </div>
                                </div>
                                :
                                null
                        }
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Towarzystwo ubezpieczeń
                            </div>
                            <div className="col-9">
                                <Dropdown
                                    className="f-w"
                                    options={ubzs}
                                    value={policy.polUbzID}
                                    onChange={(e) => {
                                        policy.polUbzID = e.value;
                                        let s = series.filter(s => s.ubz === e.value);
                                        setAdditionalPrint({});
                                        if (s.length === 1) {
                                            policy.polSeriaID = s[0].value;
                                            policy.polSeriaTxt = s[0].label;
                                        }
                                        setPolicy({ ...policy });
                                    }}
                                    filter
                                    emptyMessage="Brak wyników"
                                />
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Seria:
                            </div>
                            <div className="col-9">
                                <Dropdown
                                    className="f-w"
                                    options={series.filter(s => s.ubz === policy.polUbzID)}
                                    value={policy.polSeriaID}
                                    onChange={(e) => {
                                        policy.polSeriaID = e.value;
                                        policy.polSeriaTxt = series.find(s => s.value === e.value).label;
                                        setPolicy({ ...policy });
                                    }}
                                    emptyMessage="Brak wyników"
                                />
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Numer:
                            </div>
                            <div className="col-9">
                                {
                                    policy.polSeriaID && series.find(s => s.value === policy.polSeriaID && s.virtual === 0) ?
                                        <Dropdown
                                            className="f-w"
                                            options={[]}
                                            emptyMessage="Brak wyników"
                                            onShow={(e) => {

                                            }}
                                        />

                                        :
                                        <InputMask
                                            className="f-w"
                                            autoClear={false}
                                            unmask={true}
                                            value={policy.polNumerTxt}
                                            mask={getNumberMask(policy.polSeriaID ? series.find(s => s.value === policy.polSeriaID).mask : 1)}
                                            onChange={(e) => {
                                                policy.polNumerTxt = e.target.value;
                                                setPolicy({ ...policy });
                                            }}
                                            onBlur={(e) => {
                                                if (policy.polNumerTxt.length < series.find(s => s.value === policy.polSeriaID).mask)
                                                    confirmNumber(0)
                                            }}
                                        />
                                }
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Data zawarcia:
                            </div>
                            <div className="col-9">
                                <Calendar
                                    //className="f-w"
                                    showIcon
                                    dateFormat="dd/mm/yy"
                                    value={policy.polDataZaw}
                                    onChange={(e) => {
                                        policy.polDataZaw = moment(e.value).format();
                                        setPolicy({ ...policy });
                                    }}
                                    monthNavigator
                                    yearNavigator
                                    yearRange={"1900:2030"}
                                />
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Data startu:
                            </div>
                            <div className="col-9">
                                <Calendar
                                    //className="f-w"
                                    showIcon
                                    dateFormat="dd/mm/yy"
                                    value={policy.polDataOd}
                                    onChange={(e) => {
                                        if (policy.polTypID !== 3)
                                            policy.polDataDo = moment(e.value).add(1, 'y').add(-1, 'd').format();
                                        policy.polDataOd = moment(e.value).format();
                                        setPolicy({ ...policy });
                                    }}
                                    monthNavigator
                                    yearNavigator
                                    yearRange={"1900:2030"}
                                />
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Data końca
                            </div>
                            <div className="col-9">
                                <Calendar
                                    //className="f-w"
                                    showIcon
                                    dateFormat="dd/mm/yy"
                                    value={moment(policy.polDataDo).toDate()}
                                    onChange={(e) => {
                                        policy.polDataDo = moment(e.value).format();
                                        setPolicy({ ...policy });
                                    }}
                                    monthNavigator
                                    yearNavigator
                                    yearRange={"1900:2030"}
                                />
                                <button
                                    onClick={() =>
                                        setPolicy({ ...policy, polDataDo: moment(policy.polDataOd).add(1, 'M').add(-1, 'd').format() })
                                    }
                                    className="link-like-button m-2"
                                >
                                    {policy.polDataDo === moment(policy.polDataOd).add(1, 'M').add(-1, 'd').format() ? '>' : ''}
                                    1 miesiąc
                                </button>
                                <button
                                    onClick={() =>
                                        setPolicy({ ...policy, polDataDo: moment(policy.polDataOd).add(3, 'M').add(-1, 'd').format() })
                                    }
                                    className="link-like-button m-2"
                                >
                                    {policy.polDataDo === moment(policy.polDataOd).add(3, 'M').add(-1, 'd').format() ? '>' : ''}
                                    3 miesiące
                                </button>
                                <button
                                    onClick={() =>
                                        setPolicy({ ...policy, polDataDo: moment(policy.polDataOd).add(6, 'M').add(-1, 'd').format() })
                                    }
                                    className="link-like-button m-2"
                                >
                                    {policy.polDataDo === moment(policy.polDataOd).add(6, 'M').add(-1, 'd').format() ? '>' : ''}
                                    6 miesięcy
                                </button>
                                <button
                                    onClick={() =>
                                        setPolicy({ ...policy, polDataDo: moment(policy.polDataOd).add(1, 'y').add(-1, 'd').format() })
                                    }
                                    className="link-like-button m-2"
                                >
                                    {policy.polDataDo === moment(policy.polDataOd).add(1, 'y').add(-1, 'd').format() ? '>' : ''}
                                    1 rok
                                </button>
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Waluta:
                            </div>
                            <div className="col-9">
                                <Dropdown
                                    className="f-w"
                                    options={currencies}
                                    value={policy.polWalID}
                                    onChange={(e) => {
                                        policy.polWalID = e.value;
                                        setPolicy({ ...policy });
                                    }}
                                    emptyMessage="Brak wyników"
                                />
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Agent:
                            </div>
                            <div className="col-9">
                                <Dropdown
                                    className="f-w"
                                    disabled
                                    options={agents}
                                    value={policy.polAgentID}
                                    onChange={(e) => {
                                        policy.polAgentID = e.value;
                                        setPolicy({ ...policy });
                                    }}
                                    filter
                                    emptyMessage="Brak wyników"
                                />
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Status:
                            </div>
                            <div className="col-9">
                                <Dropdown
                                    className="f-w"
                                    options={policyStatus}
                                    value={policy.polStatusID}
                                    disabled={true}
                                    onChange={(e) => {
                                        policy.polStatusID = e.value;
                                        setPolicy({ ...policy });
                                    }}
                                    emptyMessage="Brak wyników"
                                />
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">

                            </div>
                            <div className="grid col-9">
                                <div className="col">
                                    <Checkbox /> Inny adres
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polKontynuacja}
                                        disabled={policy.polTypID !== 2}
                                    /> Kontynuacja
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polSprzedana}
                                    // onChange={(e) => {
                                    //     policy.polSprzedana = e.checked;
                                    //     setPolicy({ ...policy });
                                    // }}
                                    /> Sprzedana
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polAnulowana}
                                    // onChange={(e) => {
                                    //     policy.polAnulowana = e.checked;
                                    //     setPolicy({ ...policy });
                                    // }}
                                    /> Anulowana
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polPelnaTresc}
                                        onChange={(e) => {
                                            policy.polPelnaTresc = e.checked;
                                            setPolicy({ ...policy });
                                        }}
                                    /> Pełna teść
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polZatwierdzona}
                                    // onChange={(e) => {
                                    //     policy.polZatwierdzona = e.checked;
                                    //     setPolicy({ ...policy });
                                    // }}
                                    /> Zatwierdzona
                                </div>
                                <div className="col">
                                    <Checkbox
                                        checked={policy.polZerwana}
                                    // onChange={(e) => {
                                    //     policy.polZerwana = e.checked;
                                    //     setPolicy({ ...policy });
                                    // }}
                                    /> Zerwana
                                </div>
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Uwagi
                            </div>
                            <div className="col-9">
                                <TabView activeIndex={remarksActiveTab} onTabChange={(e) => setRemarksActiveTab(e.index)}>
                                    <TabPanel header="Lista">
                                        <DataTable
                                            value={remarks}
                                            dataKey="uwID"
                                            emptyMessage="Brak wyników"
                                        >
                                            <Column field="uwUser" header="Użytkownik" className="col-2" />
                                            <Column field="uwData" header="Data" className="col-2" body={(rowData) => moment(rowData.uwData).format('DD/MM/YYYY')} />
                                            <Column field="uwTekst" header="Tekst" />
                                        </DataTable>
                                    </TabPanel>
                                    <TabPanel header="Nowa uwaga">
                                        <div className="grid justify-content-center pt-3 pb-1">
                                            <InputTextarea
                                                className="col-10"
                                                value={newRemark}
                                                onChange={(e) => setNewRemark(e.target.value)}
                                            />
                                            <Button onClick={() => { setRemarks(arr => [...arr, { uwTekst: newRemark, uwUser: props.user.usImie + ' ' + props.user.usNazw, uwData: moment().toDate(), uwID: remarks.length }]); setNewRemark(""); setRemarksActiveTab(0); }}
                                                icon="pi pi-fw pi-save"
                                                className="h-4rem w-4rem"
                                            />
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                        <div className="grid bg-gray-50 border-round m-2">
                            <div className="col-3 text-right font-bold line-height-4">
                                Druki dodatkowe
                            </div>
                            <div className="col-9">
                                <DataTable
                                    dataKey="druk_drID"
                                    value={additionalPrints}
                                    header={
                                        <div className="grid">
                                            <div className="m-1">
                                                Seria:
                                            </div>
                                            <div className="ml-1 mr-2">
                                                <Dropdown
                                                    className="f-w"
                                                    options={addPrintSeries.filter(s => s.ubz === policy.polUbzID)}
                                                    value={additionalPrint.drukdef_drdID}
                                                    onChange={(e) => {
                                                        additionalPrint.drukdef_drdID = e.value;
                                                        setAdditionalPrint({ ...additionalPrint });
                                                    }}
                                                    emptyMessage="Brak wyników"
                                                />
                                            </div>
                                            <div className="m-1">
                                                Numer:
                                            </div>
                                            <div className="ml-1">
                                                <InputMask
                                                    className="f-w"
                                                    autoClear={false}
                                                    unmask={true}
                                                    disabled={additionalPrint.drukdef_drdID === null}
                                                    mask={getNumberMask(additionalPrint.drukdef_drdID ? addPrintSeries.find(s => s.value === additionalPrint.drukdef_drdID).mask : 1)}
                                                    value={additionalPrint.drNumer}
                                                    onChange={(e) => {
                                                        additionalPrint.drNumer = e.value;
                                                        setAdditionalPrint({ ...additionalPrint });
                                                    }}
                                                    onBlur={(e) => {
                                                        if (additionalPrint.drNumer.length < addPrintSeries.find(s => s.value === additionalPrint.drukdef_drdID).mask)
                                                            confirmNumber(1)
                                                    }}
                                                />
                                            </div>
                                            <Button
                                                icon="pi pi-fw pi-plus"
                                                className=""
                                                onClick={() => {
                                                    if (additionalPrint.drukdef_drdID && additionalPrint.drNumer) {
                                                        if (additionalPrints.find(p => p.drukdef_drdID === additionalPrint.drukdef_drdID && p.drNumer === additionalPrint.drNumer)) {
                                                            toast.current.show({ severity: 'warn', summary: "Błąd", detail: 'Ten dokument znajduję się już na liście!', closable: true })
                                                        } else {
                                                            additionalPrints.push(additionalPrint);
                                                            setAdditionalPrint({});
                                                            setAdditionalPrints([...additionalPrints]);
                                                        }
                                                    } else {
                                                        toast.current.show({ severity: 'warn', summary: 'Błąd', detail: "Najpierw uzupełnij dane!", closable: true })
                                                    }
                                                }}
                                            />
                                        </div>

                                    }
                                >
                                    <Column header="Seria" field="drukdef_drdID" body={(rowData) => { return addPrintSeries.find(s => s.value === rowData.drukdef_drdID).label }} />
                                    <Column header="Numer" field="drNumer" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Fieldset >
                <Fieldset
                    legend="Ryzyka"
                >
                    <div className="grid">
                        <div className="m-2" style={{ width: "30px" }}>
                            <Button
                                icon="pi pi-fw pi-plus"
                                onClick={() => setAddRiskVisible(true)}
                            />
                        </div>
                        <div className="col">
                            <ContextMenu
                                model={[
                                    {
                                        label: "Usuń", icon: "pi pi-fw pi-trash", command: () => setRisks(risks.filter(r => r.id !== selection.id))
                                    }
                                ]}
                                ref={riskCM}
                            />
                            <DataTable
                                value={risks}
                                dataKey="ryzID"
                                responsiveLayout='scroll'
                                emptyMessage="Brak wyników"
                                onContextMenuSelectionChange={e => setSelection(e.value)}
                                onContextMenu={e => riskCM.current.show(e.originalEvent)}
                            >
                                <Column header="Ryzyko" field="ryzRyzID" style={{ width: "15%" }} body={(rowData) => { return riskTypes.find(t => t.value === rowData.ryzRyzID).label }} />
                                <Column header="Ochrona od" field="ryzDataOd" body={(rowData) => { return moment(rowData.ryzDataOd).format("DD/MM/YYYY") }} />
                                <Column header="Ochrona do" field="ryzDataDo" body={(rowData) => { return moment(rowData.ryzDataDo).format("DD/MM/YYYY") }} />
                                <Column header="Składka" field="ryzSklada" body={(rowData) => { return rowData.ryzSkladka.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                                <Column header="Suma ubz" field="ryzSumaUbz" body={(rowData) => { return rowData.ryzSumaUbz ? rowData.ryzSumaUbz.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) : "" }} />
                                <Column header="Ile rat" field="ryzIleRat" />
                                {/* <Column header="B/M" field="ryzBM" body={(rowData) => { return rowData.ryzBM ? bms.find(t => t.value === rowData.ryzBM).label : "" }} /> */}
                                <Column header="Komentarz" field="ryzKometarz" style={{ width: "35%" }} />
                            </DataTable>
                        </div>
                    </div>
                </Fieldset>
                <div className="grid col-12 justify-content-end mt-2 m-0 p-0">
                    {/* <div>
                        <Button label="Zaplanuj raty" onClick={() => planInstalments()} />
                    </div> */}
                    <div className="bg-gray-50 border-round border-1 border-200 p-2">
                        Składka razem: <label className="font-bold text-xl">{contributionSum().toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label })}</label>
                    </div>
                </div>
                <TabView className="my-3">
                    <TabPanel header="Podsumowanie rat">
                        <DataTable
                            value={instalments}
                            dataKey="rratID"
                            responsiveLayout='scroll'
                            emptyMessage="Brak wyników"
                            editMode="cell"
                        >
                            <Column header="Kwota" field="rrKwota" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={(rowData) => { return rowData.rrKwota.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                            <Column header="Termin płatności" field="rrData" body={(rowData) => { return rowData.rrData ? moment(rowData.rrData).format("DD/MM/YYYY") : "Brak" }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                            <Column header="Rata nr" field="rrNr" />
                            <Column header="Inkaso" field="rrZaplat" body={(rowData) => { return <Checkbox checked={rowData.rrZaplat} onChange={(e) => inkasoAction(rowData, e.checked)} /> }} />
                            <Column header="Zapłata cz." field="rrZaplatC" body={(rowData) => { return <Checkbox checked={rowData.rrZaplatC} /> }} />
                            <Column header="Kwota zapł." field="rrZaplatKwota" body={(rowData) => { return rowData.rrZaplatKwota.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                            <Column header="Pozostało" field="rrZaplatKwotaP" body={(rowData) => { return rowData.rrZaplatKwotaP.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} />
                            <Column header="Data inkaso" field="rrDataZap" body={(rowData) => { return rowData.rrDataZap ? moment(rowData.rrDataZap).format("DD/MM/YYYY") : "Brak" }} />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Inkaso">
                        <div className="grid">
                            <div className="col">
                                <ContextMenu
                                    model={[
                                        {
                                            label: "Powiel", icon: "pi pi-fw pi-copy", command: () => addInkaso()
                                        },
                                        {
                                            label: "Usuń", icon: "pi pi-fw pi-trash", command: () => deleteInkaso()
                                        }
                                    ]}
                                    ref={inkasoCM}
                                />
                                <DataTable
                                    value={inkaso}
                                    dataKey="inkID"
                                    responsiveLayout='scroll'
                                    emptyMessage="Brak wyników"
                                    editMode="cell"
                                    onContextMenuSelectionChange={e => setSelection(e.value)}
                                    onContextMenu={e => inkasoCM.current.show(e.originalEvent)}
                                >
                                    <Column header="Kwota zapłacona" field="inkKwota" body={(rowData) => { return rowData.inkKwota.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                    <Column header="Data inkaso" field="inkDataZap" body={(rowData) => { return rowData.inkDataZap ? moment(rowData.inkDataZap).format("DD/MM/YYYY") : "Brak" }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                    <Column header="Termin płatności" field="inkDataPlat" body={(rowData) => { return rowData.inkDataPlat ? moment(rowData.inkDataPlat).format("DD/MM/YYYY") : "Brak" }} />
                                    <Column header="Status" field="inkStatusID" body={(rowData) => { return inkasoStatus.find(s => s.value === rowData.inkStatusID).label }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                    <Column header="Forma płatności" field="inkFormaPlatID" body={(rowData) => { return paymentForms.find(f => f.value === rowData.inkFormaPlatID).label }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                    <Column header="Nr transakcji" field="inkNrTran" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                    <Column header="Nr raty" field="rraty_rrNr" />
                                    <Column header="Odsetki" field="inkOdsetki" body={(rowData) => { return rowData.inkOdsetki.toLocaleString('pl-PL', { style: 'currency', currency: currencies.find(c => c.value === policy.polWalID).label || 'PLN' }) }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                    <Column header="Nr trans. online" field="inkNumerPlatnosciOnline" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                </DataTable>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
                <TabView>
                    <TabPanel header="Pojazdy">
                        <div className="grid">
                            <div className="m-2" style={{ width: "30px" }}>
                                <Button icon="pi pi-fw pi-plus" className="mb-3" onClick={() => setVehicleBrowserVisible(true)} />
                            </div>
                            <div className="col">
                                <ContextMenu
                                    model={[
                                        {
                                            label: "Edytuj", icon: "pi pi-fw pi-pencil", command: () => setVehicleEditVisible(true)
                                        },
                                        {
                                            label: "Usuń", icon: "pi pi-fw pi-trash", command: () => setVehicles(vehicles.filter(r => r.pojID !== selection.pojID))
                                        }
                                    ]}
                                    ref={vehicleCM}
                                />
                                <DataTable
                                    value={vehicles}
                                    dataKey="pojID"
                                    responsiveLayout='scroll'
                                    emptyMessage="Brak wyników"
                                    onContextMenuSelectionChange={e => setSelection(e.value)}
                                    onContextMenu={e => vehicleCM.current.show(e.originalEvent)}
                                >
                                    <Column header="Nr rej." field="pojRejestracja" />
                                    <Column header="Marka" field="pojMarkaID" body={(rowData) => { return rowData.pojMarkaID ? vehicleBrands.find(m => m.value === rowData.pojMarkaID).label : "" }} />
                                    <Column header="Model" field="pojModelID" body={(rowData) => { return rowData.pojModelID ? vehicleModels.find(m => m.value === rowData.pojModelID).label : "" }} />
                                    <Column header="Typ" field="pojTyp" />
                                    <Column header="Rok prod" field="pojRokProd" />
                                    <Column header="Data p. rej." field="pojPierRej" body={(rowData) => { return rowData.pojPierRej ? moment(rowData.pojPierRej).format("DD/MM/YYYY") : "" }} />
                                    <Column header="VIN" field="pojVIN" />
                                    <Column header="Rodzaj" field="pojRodzajID" body={(rowData) => { return rowData.pojRodzajID ? vehicleKinds.find(k => k.value === rowData.pojRodzajID).label : "" }} />
                                    <Column header="Status" field="pojStatusID" body={(rowData) => { return rowData.pojStatusID ? vehicleStatus.find(s => s.value === rowData.pojStatusID).label : "" }} />
                                    <Column header="Pojemność" field="pojPojemnosc" />
                                    <Column header="Il. miejsc" field="pojIloMiejsc" />
                                    <Column header="Data badania" field="pojDataBadania" body={(rowData) => { return rowData.pojDataBadania ? moment(rowData.pojDataBadania).format("DD/MM/YYYY") : "" }} />
                                    <Column header="Przebieg" field="pojPrzebieg" />
                                </DataTable>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Inne obiekty">
                        <div className="grid">
                            <div className="m-2" style={{ width: "30px" }}>
                                <Button icon="pi pi-fw pi-plus" className="mb-3" onClick={() => setObjectsBrowserVisible(true)} />
                            </div>
                            <div className="col">
                                <ContextMenu
                                    model={[
                                        {
                                            label: "Usuń", icon: "pi pi-fw pi-trash", command: () => setOtherObjs(otherObjs.filter(r => r.ouID !== selection.ouID))
                                        }
                                    ]}
                                    ref={objCM}
                                />
                                <DataTable
                                    value={otherObjs}
                                    dataKey="ouID"
                                    responsiveLayout='scroll'
                                    emptyMessage="Brak wyników"
                                    onContextMenuSelectionChange={e => setSelection(e.value)}
                                    onContextMenu={e => objCM.current.show(e.originalEvent)}
                                >
                                    <Column header="Typ" field="ouTypID" body={(rowData) => { return insObjTypes.find(t => t.value === rowData.ouTypID).label }} />
                                    <Column header="Nazwa" field="ouNazwa" />
                                    <Column header="Adres" field="ouAdres" />
                                    <Column header="Nr domu" field="ouNrDomu" />
                                    <Column header="Nr lokalu" field="ouNrLok" />
                                    <Column header="Kod pocz." field="ouKodPocz" />
                                    <Column header="Miasto" field="ouMiasto" />
                                    <Column header="Opis" style={{ width: "35%" }} field="ouOpis" />
                                </DataTable>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
                <div className="grid col-12 justify-content-center mt-3">
                    <Button
                        className="mr-3 h-3rem sm:col-1"
                        label="Zapisz"
                        icon="pi pi-fw pi-save"
                        onClick={savePolicy}
                    />
                    <Button
                        className="sm:col-1"
                        label="Czyść"
                        icon="pi pi-fw pi-trash"
                        onClick={() => window.location.reload(false)}
                    />
                </div>
            </div >


            <Dialog
                visible={addUserVisible}
                resizable
                dismissableMask
                className="col-6"
                onHide={() => {
                    setNewPolicyUser({});
                    setAddUserVisible(false)
                }}
                header="Podmioty"
            >
                <div className="grid justify-content-center">
                    <label className="col-1 pt-3 text-right">Typ:</label>
                    <div className="col-4">
                        <Dropdown
                            className="f-w"
                            options={clientUbzTypes}
                            value={newPolicyUser.pkTypKl}
                            onChange={(e) => {
                                newPolicyUser.pkTypKl = e.value;
                                setNewPolicyUser({ ...newPolicyUser });
                            }}
                            emptyMessage="Brak wyników"
                        />
                    </div>
                    <label className="col-1 pt-3 text-right">Nazwa:</label>
                    <div className="col-4">
                        <Button
                            className="f-w"
                            disabled={typeof newPolicyUser.pkTypKl === "undefined"}
                            label={newPolicyUser.pkNazwa || "Szukaj"}
                            onClick={() => setClientBrowserVisible(true)}
                        />
                    </div>

                    <Button
                        className="col-3 mt-5"
                        label="Dodaj wpis"
                        icon="pi pi-fw pi-plus"
                        onClick={() => {
                            if (newPolicyUser.pkTypKl === 0) {
                                policyUsers.push({ ...newPolicyUser, pkTypKl: 1 });
                                policyUsers.push({ ...newPolicyUser, pkTypKl: 2 });
                            } else
                                policyUsers.push(newPolicyUser);
                            setPolicyUsers([...policyUsers]);
                            setNewPolicyUser({});
                            setAddUserVisible(false);
                        }}
                    />
                </div>
            </Dialog>

            <Dialog
                visible={clientBrowserVisible}
                resizable
                dismissableMask
                className="col-10"
                onHide={() => setClientBrowserVisible(false)}
            >
                <Clients
                    searchMode={true}
                    onChoice={async (id, name) => {
                        newPolicyUser.pkNazwa = name;
                        newPolicyUser.klient_kliID = id;
                        let response = await clientService.getAddress(id);
                        newPolicyUser.pkAdres = response.kaAdres + (response.kaNrDomu ? " " + response.kaNrDomu + (response.kaNrMiesz ? ("/" + response.kaNrMiesz) : "") : "");
                        newPolicyUser.pkMiasto = response.kaMiasto;
                        const { kliMobil, kliTel } = await clientService.getDetails(id);
                        newPolicyUser.pkTelefon = kliMobil || kliTel;
                        setNewPolicyUser({ ...newPolicyUser });
                        setClientBrowserVisible(false);
                    }} />
            </Dialog>
            <Dialog
                visible={addRiskVisible}
                resizable
                dismissableMask
                className="col-8"
                onHide={() => setAddRiskVisible(false)}
                onShow={() => setNewRisk({ ryzDataOd: policy.polDataOd, ryzDataDo: policy.polDataDo, ryzSumaUbz: 0, ryzSkladka: 0 })}
                header="Podmioty"
            >
                <div className="grid justify-content-center">
                    <label className="col-2 pt-3 text-right">Typ:</label>
                    <div className="col-4">
                        <Dropdown
                            className="f-w"
                            options={policy.polUbzID ? riskTypes.filter(t => t.ubz === policy.polUbzID) : riskTypes}
                            value={newRisk.ryzRyzID}
                            onChange={(e) => {
                                newRisk.ryzRyzID = e.value;
                                setNewRisk({ ...newRisk });
                            }}
                            filter
                            filterPlaceholder="Szukaj..."
                            emptyMessage="Brak wyników"
                            emptyFilterMessage="Brak pasujących wyników"
                        />
                    </div>
                    <label className="col-2 pt-3 text-right">
                        Ile rat:
                    </label>
                    <div className="col-4">
                        <Dropdown
                            className="f-w"
                            options={[1, 2, 3, 4, 6, 11, 12]}
                            value={newRisk.ryzIleRat}
                            onChange={(e) => {
                                newRisk.ryzIleRat = e.value;
                                setNewRisk({ ...newRisk });
                            }}
                        />
                    </div>
                    <label className="col-2 pt-3 text-right">
                        Składka:
                    </label>
                    <div className="col-4">
                        <InputNumber
                            className="f-w"
                            mode="currency"
                            currency="PLN"
                            showButtons
                            value={newRisk.ryzSkladka}
                            onValueChange={(e) => {
                                newRisk.ryzSkladka = e.value;
                                setNewRisk({ ...newRisk });
                            }}
                        />
                    </div>
                    <label className="col-2 pt-3 text-right">
                        Suma ubz:
                    </label>
                    <div className="col-4">
                        <InputNumber
                            className="f-w"
                            mode="currency"
                            currency="PLN"
                            showButtons
                            value={newRisk.ryzSumaUbz}
                            onValueChange={(e) => {
                                newRisk.ryzSumaUbz = e.value;
                                setNewRisk({ ...newRisk });
                            }}
                        />
                    </div>
                    <label className="col-2 pt-3 text-right">
                        Ochrona od:
                    </label>
                    <div className="col-4">
                        <Calendar
                            className="f-w"
                            showIcon
                            dateFormat="dd/mm/yy"
                            value={moment(newRisk.ryzDataOd).toDate()}
                            onChange={(e) => {
                                newRisk.ryzDataOd = moment(e.value).format();
                                setNewRisk({ ...newRisk });
                            }}
                            monthNavigator
                            yearNavigator
                            yearRange={"1900:2030"}
                        />
                    </div>
                    <label className="col-2 pt-3 text-right">
                        Ochrona do:
                    </label>
                    <div className="col-4">
                        <Calendar
                            className="f-w"
                            showIcon
                            dateFormat="dd/mm/yy"
                            value={moment(newRisk.ryzDataDo).toDate()}
                            onChange={(e) => {
                                newRisk.ryzDataDo = moment(e.value).format();
                                setNewRisk({ ...newRisk });
                            }}
                            monthNavigator
                            yearNavigator
                            yearRange={"1900:2030"}
                        />
                    </div>
                    {/* <label className="col-2 pt-3 text-right">
                        B/M:
                    </label>
                    <div className="col-4">
                        <Dropdown
                            className="f-w"
                            options={bms}
                            value={newRisk.ryzBM}
                            onChange={(e) => {
                                newRisk.ryzBM = e.value;
                                setNewRisk({ ...newRisk });
                            }}
                            emptyMessage="Brak wyników"
                        />
                    </div> */}
                    <label className="col-2 pt-3 text-right">
                        Komentarz:
                    </label>
                    <div className="col-10">
                        <InputTextarea
                            className="f-w"
                            value={newRisk.ryzKometarz}
                            onChange={(e) => {
                                newRisk.ryzKometarz = e.target.value;
                                setNewRisk({ ...newRisk });
                            }}
                        />
                    </div>

                    <Button
                        className="col-3 mt-5"
                        label="Dodaj wpis"
                        icon="pi pi-fw pi-plus"
                        onClick={() => {
                            if (newRisk.ryzRyzID && newRisk.ryzSkladka && newRisk.ryzIleRat) {
                                newRisk.ryzID = risks.length;
                                risks.push(newRisk);
                                setRisks([...risks]);
                                setAddRiskVisible(false);
                            } else {
                                let str = 'Brak wymaganych danych: ';
                                let c = 0;
                                if (typeof newRisk.ryzRyzID === 'undefined') {
                                    str = str.concat("typ");
                                    c++;
                                }
                                if (typeof newRisk.ryzSkladka === 'undefined') {
                                    if (c > 0)
                                        str = str.concat(', ')
                                    str = str.concat("składka");
                                    c++;
                                }
                                if (typeof newRisk.ryzIleRat === 'undefined') {
                                    if (c > 0)
                                        str = str.concat(', ')
                                    str = str.concat('ilość rat');
                                    c++;
                                }
                                toast.current.show({ severity: 'warn', summary: "Błąd", detail: str, closable: true })
                            }
                        }}
                    />
                </div>
            </Dialog>

            <Dialog
                visible={vehicleBrowserVisible}
                resizable
                dismissableMask
                className="col-8"
                onHide={() => {

                    setVehicleBrowserVisible(false)
                }}
                header="Pojazdy"
            >
                <Vehicles
                    searchMode={true}
                    onChoice={async (id, regNr) => {
                        let vehicle = await vehicleService.getDetails(id);
                        if (vehicles.find(v => v.pojID === vehicle.pojID))
                            toast.current.show({ severity: 'warn', summary: "Błąd", detail: 'Ten pojazd znajduję się już na liście!', closable: true })
                        else {
                            vehicles.push(vehicle);
                            setVehicles([...vehicles]);
                        }
                        setVehicleBrowserVisible(false);
                    }}
                />
            </Dialog>

            <Dialog
                visible={objectsBrowserVisible}
                resizable
                dismissableMask
                className="col-10"
                onHide={() => {

                    setObjectsBrowserVisible(false)
                }}
                header="Obiekty"
            >
                <InsuranceObjects
                    searchMode={true}
                    onChoice={async (id, regNr) => {
                        let otherObj = await objectsService.getDetails(id);
                        if (otherObjs.find(o => o.ouID === otherObj.ouID))
                            toast.current.show({ severity: 'warn', summary: "Błąd", detail: 'Ten obiekt znajduję się już na liście!', closable: true })
                        else {
                            otherObjs.push(otherObj);
                            setOtherObjs([...otherObjs]);
                        }
                        setObjectsBrowserVisible(false);
                    }}
                />
            </Dialog>

            <Dialog
                visible={policyBrowserVisible}
                resizable
                dismissableMask
                className={annexInput ? "col-6" : "col-10"}
                onHide={() => {
                    setAnnexInput(true);
                    setNewAnnex({});
                    setPolicyBrowserVisible(false)
                }}
                header={policy.polTypID === 4 ? "Aneks" : "Polisa"}
            >
                {policy.polTypID === 4 && annexInput ?
                    <div className="grid col-12 h-8rem justify-content-center align-content-center">
                        <div className="col-2 pr-0">
                            <span className="p-float-label">
                                <InputText
                                    id="annexSeriesInput"
                                    className="f-w"
                                    value={newAnnex.popAnSeriaTxt}
                                    onChange={(e) => setNewAnnex({ ...newAnnex, popAnSeriaTxt: e.target.value })}
                                />
                                <label htmlFor="annexSeriesInput">Seria</label>
                            </span>
                        </div>
                        <div className="col-3 pl-0 pr-0">
                            <span className="p-float-label f-w">
                                <InputText
                                    id="annexNumberInput"
                                    className="f-w"
                                    value={newAnnex.popAnNumerTxt}
                                    onChange={(e) => setNewAnnex({ ...newAnnex, popAnNumerTxt: e.target.value })}
                                />
                                <label htmlFor="annexNumberInput">Numer</label>
                            </span>
                        </div>
                        <div className="col-1 pl-0">
                            <Button
                                className="f-w"
                                icon="pi pi-fw pi-chevron-circle-right"
                                onClick={() => {
                                    setPolicy({ ...policy, popAneksGID: newAnnex.popAneksGID, popAnSeriaTxt: newAnnex.popAnSeriaTxt });
                                    setNewAnnex({});
                                    setPolicyBrowserVisible(false);
                                }}
                            />
                        </div>
                        <div className="col-4">
                            <Button
                                className="f-w"
                                label="Wybierz z listy"
                                icon="pi pi-fw pi-search"
                                onClick={() => setAnnexInput(false)}
                            />
                        </div>
                    </div>
                    :
                    < Policies
                        searchMode={true}
                        onChoice={async (id, number) => {
                            let response = await policyService.getDetails(id);
                            if (policy.polTypID === 4) {
                                policy.popAneksGID = id;
                                policy.popAnNumerTxt = number;
                                policy.popAnSeriaTxt = response.polSeriaTxt;
                            } else {
                                let prev = await policyService.getPrevPolicyData(id);
                                policy.polKontynuacja = true;
                                policy.polPoprzID = id;
                                policy.polPopNumerTxt = number;
                                policy.polPopSeriaTxt = response.polSeriaTxt;
                                policy.polWalID = response.polWalID;
                                policy.polUbzID = response.polUbzID;
                                policy.polSeriaID = response.polSeriaID;
                                policy.polPelnaTresc = response.polPelnaTresc;
                                policy.polSeriaID = response.polSeriaID;
                                setAdditionalPrints(prev.additionalPrints);
                                setPolicyUsers(prev.policyUsers);
                                setVehicles(prev.vehicles);
                                setOtherObjs(prev.otherObjs);
                            }
                            setPolicy({ ...policy });
                            setAnnexInput(true);
                            setPolicyBrowserVisible(false);
                        }}
                    />
                }
            </Dialog>

            <Dialog
                visible={vehicleEditVisible}
                header={"Pojazd " + selection.pojRejestracja}
                onHide={() => setVehicleEditVisible(false)}
                dismissableMask
                maximizable
                className='col-8'
            >
                <VehicleDetails
                    id={selection.pojID}
                    close={() => setVehicleEditVisible(false)}
                    success={async () => {
                        toast.current.show({ severity: 'success', summary: 'Pomyślnie zapisano zmiany!', closable: true });
                        let vehicle = await vehicleService.getDetails(selection.pojID);
                        vehicles[vehicles.findIndex(v => v.pojID === selection.pojID)] = vehicle;
                        setVehicles([...vehicles]);
                    }}
                />
            </Dialog>

            {/* <Dialog
                visible={remarksVisible}
                header="Uwagi"
                onHide={() => setRemarksVisible(false)}
                dismissableMask
                maximizable
                className='col-8'
            >
            </Dialog> */}
        </>
    )
}