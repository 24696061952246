/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useRef, useState } from "react"
import dictionaryService from "../services/dictionaryService";
import officeService from "../services/officeService";
import policyService from "../services/policyService";
import PolicyDetails from "./details/PolicyDetails";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import warehouseService from "../services/warehouseService";
import EditPolicy from "./details/EditPolicy";


export default function Sales(props) {

    const [policies, setPolicies] = useState([]);
    const [policyDetails, setPolicyDetails] = useState({ polID: null, polNumerTxt: "" });
    const [ubzs, setUbzs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "drDataUt",
        sortOrder: 1,
        ubz: null
    });
    const [summary, setSummary] = useState({ cashSum: 0, creditCupon: 0, interest: 0, interestCash: 0, inkasoSum: 0, inkasoCash: 0 });
    const [valid, setValid] = useState(true);
    const [office, setOffice] = useState([]);

    const toast = useRef(null);

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        const loadDictionary = async () => {
            let response =
                await dictionaryService.getTUs();
            setUbzs(response);
        }

        loadDictionary();

        if (props.unlinked)
            toast.current.show({ severity: 'warn', summary: 'Brak podpięcia!', detail: 'Wyszukaj polisę ' + props.unlinked + ' i podepnij ją ręcznie.', sticky: true, closable: true });
    }, [])

    const loadLazyData = async () => {
        setValid(true);
        setLoading(true);
        let creditCupon = 0;
        let interest = 0;
        let interestCash = 0;
        let inkasoSum = 0;
        let inkasoCash = 0;
        let result = await officeService.getCurrent();
        setOffice(result);
        let { totalRecords, policies, inkaso } = await policyService.getSalesPackage(JSON.stringify(lazyParams));
        if (policies) {
            async function updatePolicies() {
                for (var p in policies) {
                    policies[p].inkSum = 0;
                    policies[p].inkGot = 0;
                    policies[p].inkOds = 0;
                    policies[p].inkOdsG = 0;
                    policies[p].inkForma = "";
                    if (policies[p].polID !=null && policies[p].polPelnaTresc !== 1)
                        setValid(false);
                    if (policies[p].polSklCalk) {
                        // eslint-disable-next-line no-loop-func
                        var arr = inkaso.filter(i => i.polisa_polID === policies[p].polID);
                        for (var a in arr) {
                            switch (arr[a].inkFormaPlatID) {
                                case 1:
                                    policies[p].inkGot += arr[a].inkKwota;
                                    policies[p].inkOdsG += arr[a].inkOdsetki;
                                    policies[p].inkForma += policies[p].inkForma ? " G" : "G";
                                    if (!arr[a].inkAnulowany) {
                                        interestCash += arr[a].inkOdsetki;
                                        inkasoCash += arr[a].inkKwota;
                                    }
                                    break;
                                case 2:
                                    policies[p].inkForma += policies[p].inkForma ? " KP" : "KP";
                                    break;
                                case 3:
                                    policies[p].inkForma += policies[p].inkForma ? " PT" : "PT";
                                    break;
                                case 5:
                                    policies[p].inkForma += policies[p].inkForma ? " PC" : "PC";
                                    break;
                                default:
                                    policies[p].inkForma += policies[p].inkForma ? " ?" : "?";
                                    break;
                            }
                            policies[p].inkSum += arr[a].inkKwota;
                            policies[p].inkOds += arr[a].inkOdsetki;
                            if (!arr[a].inkAnulowany) {
                                inkasoSum += arr[a].inkKwota;
                                interest += arr[a].inkOdsetki;
                            }
                        }
                    }

                }
            }
            await updatePolicies();
            setTotalRecords(totalRecords);
            setPolicies(policies)
            setSummary({ cashSum: interestCash + inkasoCash, creditCupon, interest, interestCash, inkasoSum, inkasoCash });
        }
        setLoading(false);
    }

    const onPage = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onSort = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const sendToCenter = async () => {
        let counter = await warehouseService.updateCounter(office[0].value);
        if (counter) {
            let magID = await warehouseService.createSaleDoc(counter, office[0].value);
            if (magID) {
                let result = await policyService.changeStatus(policies);
                if (result.status === 0) {
                    result = await warehouseService.addToDoc(policies, magID);
                    if (result.status === 0) {
                        loadLazyData();
                        toast.current.show({ severity: 'success', summary: 'Sukces', detail: 'Pomyślnie ukończono operację.' })
                    } else
                        toast.current.show({ severity: 'error', summary: 'Błąd', detail: 'Nie udało się dodać pozycji do dokumentu magazynowego.' })
                } else
                    toast.current.show({ severity: 'error', summary: 'Błąd', detail: 'Nie udało się zaktualizować statusu druków i polis.' })
            } else
                toast.current.show({ severity: 'error', summary: 'Błąd', detail: 'Nie udało się utworzyć dokumentu magazynowego.' })
        } else
            toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Nie udało się zaktualizować licznika druk SP.' })
    }

    const confirmSend = () => {
        confirmDialog({
            message: `Czy przekazać ${policies.length} druk${policies.length > 1 ? policies.length > 4 ? 'ów' : 'i' : ''} do kierownika oddziału i generować zapisy magazynowe?`,
            header: 'Potwierdzenie',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Tak",
            rejectLabel: "Nie",
            accept: () => sendToCenter(),
            reject: () => { }
        });
    }

    const rowClass = (data) => {
        return {
            'table-row-red': data.drAnulowany === 1,
            'table-row-yellow': data.polPelnaTresc === 0
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="col-12">
                <DataTable
                    value={policies}
                    loading={loading}
                    lazy
                    paginator
                    rows={lazyParams.rows}
                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                    rowClassName={rowClass}
                    dataKey="polID"
                    responsiveLayout='scroll'
                    first={lazyParams.first}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onSort={onSort}
                    emptyMessage="Brak wyników"
                    onRowDoubleClick={(e) => props.searchMode ? props.onChoice(e.data.polID, e.data.polNumerTxt) : setPolicyDetails({ polID: e.data.polID, polNumerTxt: e.data.polNumerTxt })}
                    header={
                        <div className="grid col-12 h-3rem vertical-align-middle">
                            <div className="mr-3">
                                <Button className="btn-transparent" icon="pi pi-refresh" loading={loading} onClick={() => { setLoading(true); window.location.reload(false); }} />
                            </div>
                            <div className="grid mr-3">
                                <div className="align-self-center">Użytkownik:</div>
                                <div className="align-self-center textbox-white min-w-col-3">
                                    {props.user.usImie + " " + props.user.usNazw}
                                </div>
                            </div>
                            <div className="grid mr-3">
                                <div className="align-self-center">Biuro:</div>
                                <div className="align-self-center textbox-white">
                                    {office.length ? office[0].label : "Nieznane"}
                                </div>
                            </div>
                            <div className="grid col">
                                <div className="align-self-center align-self-center">TU:</div>
                                <Dropdown
                                    value={lazyParams.ubz}
                                    options={ubzs}
                                    onChange={(e) => setLazyParams({ ...lazyParams, ubz: e.value })}
                                    className="align-self-center dropdown-white"
                                    showClear
                                    filter
                                />
                            </div>
                            <div className="grid col">
                                <ConfirmDialog />
                                <Button
                                    label="Przekaż do centrali"
                                    icon="pi pi-fw pi-send"
                                    disabled={policies.length === 0}
                                    onClick={() => valid ? confirmSend() : toast.current.show({ severity: 'warn', summary: 'Uwaga!', detail: 'Nie wszystkie polisy mają pełną treść.' })}
                                />
                            </div>
                        </div>
                    }
                    footer={
                        <div className="grid h-3rem col-12 justify-content-evenly">
                            <div className="grid col-2">
                                <div className="align-self-center">Gotówka razem:</div>
                                <div className="textbox-grey">
                                    {summary.cashSum.toFixed(2)}
                                </div>
                            </div>
                            <div className="grid col-2">
                                <div className="align-self-center">Kredyt kupon:</div>
                                <div className="textbox-grey">
                                    {summary.creditCupon.toFixed(2)}
                                </div>
                            </div>
                            <div className="grid col-2">
                                <div className="align-self-center">Odsetki:</div>
                                <div className="textbox-grey">
                                    {summary.interest.toFixed(2)}
                                </div>
                            </div>
                            <div className="grid col-2">
                                <div className="align-self-center">W tym gotówka:</div>
                                <div className="textbox-grey">
                                    {summary.interestCash.toFixed(2)}
                                </div>
                            </div>
                            <div className="grid col-2">
                                <div className="align-self-center">Inkaso:</div>
                                <div className="textbox-grey">
                                    {summary.inkasoSum.toFixed(2)}
                                </div>
                            </div>
                            <div className="grid col-2">
                                <div className="align-self-center">W tym gotówka:</div>
                                <div className="textbox-grey">
                                    {summary.inkasoCash.toFixed(2)}
                                </div>
                            </div>
                        </div>
                    }
                >
                    <Column field="polUbzID" header="TU" body={(rowData) => ubzs.find(u => rowData.drubzID === u.value).label} />
                    <Column field="drSeria" header="Seria Druku" />
                    <Column field="drNumer" header="Numer Druku" />
                    <Column field="polDataZaw" header="Data Zaw" body={(rowData) => moment(rowData.polDataZaw).format('DD/MM/yy')} />
                    <Column field="polDataOd" header="Polisa Od" body={(rowData) => moment(rowData.polDataOd).format('DD/MM/yy')} />
                    <Column field="polDataDo" header="Polisa Do" body={(rowData) => moment(rowData.polDataDo).format('DD/MM/yy')} />
                    <Column field="polSeriaTxt" header="Polisa Seria" />
                    <Column field="polNumerTxt" header="Polisa Numer" />
                    <Column field="polSklCalk" header="Skladka" body={(rowData) => rowData.polSklCalk ? rowData.polSklCalk.toFixed(2) : ""} />
                    <Column field="inkSum" header="Inkaso" body={(rowData) => rowData.inkSum ? rowData.inkSum.toFixed(2) : "0.00"} />
                    <Column field="inkGot" header="Gotówka" body={(rowData) => rowData.inkGot ? rowData.inkGot.toFixed(2) : "0.00"} />
                    <Column field="inkForma" header="Forma" />
                    <Column field="inkOds" header="Odsetki" body={(rowData) => rowData.inkOds ? rowData.inkOds.toFixed(2) : "0.00"} />
                    <Column field="drWirtualny" header="Wirtualny" body={(rowData) => rowData.drWirtualny ? "Tak" : "Nie"} />
                    <Column field="drAnulowany" header="Anulowany" body={(rowData) => rowData.drAnulowany ? "Tak" : "Nie"} />
                    <Column field="drCentralny" header="Centralny" body={(rowData) => rowData.drCentralny ? "Tak" : "Nie"} />
                    <Column field="polPelnaTresc" header="Pełna Treść" body={(rowData) => rowData.polPelnaTresc ? "Tak" : "Nie"} />
                    <Column field="polZatwierdzona" header="Zatwierdzona" body={(rowData) => rowData.polZatwierdzona ? "Tak" : "Nie"} />
                </DataTable>
            </div>

            <Dialog
                visible={policyDetails.polID !== null}
                header={"Polisa " + policyDetails.polNumerTxt}
                onHide={() => {setPolicyDetails({ polID: null, polNumerTxt: "" }); setEditMode(false); loadLazyData()}}
                dismissableMask
                maximizable
                className='col-12'
                icons={
                    editMode ?
                        <Button label="Wróć" icon="pi pi-fw pi-arrow-left" className='mr-3' onClick={() => setEditMode(false)} />
                        :
                        <Button label="Edytuj" icon="pi pi-fw pi-pencil" className='mr-3' onClick={() => setEditMode(true)} />
                }
            >
                {editMode ?
                    <EditPolicy
                        id={policyDetails.polID}
                        editNumber={(number) => setPolicyDetails({...policyDetails,polNumerTxt: number})}
                    />
                    :
                    <PolicyDetails
                        id={policyDetails.polID}
                    />
                }
            </Dialog>
        </>
    )
}