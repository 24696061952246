/* eslint-disable react-hooks/exhaustive-deps */

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Column } from "primereact/column";
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import policyService from '../services/policyService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PolicyDetails from './details/PolicyDetails';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import dictionaryService from '../services/dictionaryService';
import { ContextMenu } from 'primereact/contextmenu';
import EditPolicy from './details/EditPolicy';
import { InputTextarea } from 'primereact/inputtextarea';


export default function Policies(props) {
    const [policies, setPolicies] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            polNumerTxt: "",
            polDataZaw: [],
            polDataOd: [],
            polDataDo: [],
            polUbzID: "",
            pkNazwa: "",
        }
    });
    const [policyDetails, setPolicyDetails] = useState({ polID: null, polNumerTxt: "" });
    //const [types, setTypes] = useState([]);
    const [ubzs, setUbzs] = useState([]);
    const [selection, setSelection] = useState();
    const [sold, setSold] = useState({ polID: null, saleDate: moment().format(), notificationDate: moment().format() });
    const [breaking, setBreaking] = useState({ polID: null, date: moment().format(), reason: "" });


    const [editMode, setEditMode] = useState(false);
    const [soldVisible, setSoldVisible] = useState(false);
    const [breakingVisible, setBreakingVisible] = useState(false);

    const toast = useRef(null);
    const cm = useRef(null);

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        const loadDictionary = async () => {
            //let response = await dictionaryService.getPolicyTypes();
            //setTypes(response);
            let response = await dictionaryService.getTUs();
            setUbzs(response);
        }

        loadDictionary();

        if (props.unlinked)
            toast.current.show({ severity: 'warn', summary: 'Brak podpięcia!', detail: 'Wyszukaj polisę ' + props.unlinked + ' i podepnij ją ręcznie.', sticky: true, closable: true });
    }, [])

    const loadLazyData = async () => {
        setLoading(true);

        let response = await policyService.getPackage(JSON.stringify(
            {
                ...lazyParams,
                filters:
                {
                    ...lazyParams.filters,
                    polDataZaw: [
                        lazyParams.filters.polDataZaw[0] && moment(lazyParams.filters.polDataZaw[0]).format(),
                        lazyParams.filters.polDataZaw[1] && moment(lazyParams.filters.polDataZaw[1]).format()
                    ],
                    polDataOd: [
                        lazyParams.filters.polDataOd[0] && moment(lazyParams.filters.polDataOd[0]).format(),
                        lazyParams.filters.polDataOd[1] && moment(lazyParams.filters.polDataOd[1]).format()
                    ],
                    polDataDo: [
                        lazyParams.filters.polDataDo[0] && moment(lazyParams.filters.polDataDo[0]).format(),
                        lazyParams.filters.polDataDo[1] && moment(lazyParams.filters.polDataDo[1]).format()
                    ]
                }
            }
        ));
        if (response) {
            setTotalRecords(response.totalRecords);
            setPolicies(response.policies)
        }
        setLoading(false);
    }

    const onPage = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onSort = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const filterElement = (field) => {
        return (
            <>
                <InputText
                    id={field + "Filter"}
                    style={{ width: "80%", borderRadius: 0 }}
                    value={lazyParams.filters[field]}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.target.value;
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                />
                <Button
                    style={{ width: "20%", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters[field] === "" ? true : false}
                />
            </>
        )
    }

    const filterCalendar = (field) => {
        return (
            <>
                <Calendar
                    id={field + "Filter"}
                    className="filter-element"
                    value={lazyParams.filters[field]}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.value ?? [];
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                    monthNavigator
                    yearNavigator
                    yearRange={"1900:2030"}
                    dateFormat="dd/mm/yy"
                    showIcon={false}
                    selectionMode='range'
                />
                <Button
                    style={{ width: "20%", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = [];
                        setLazyParams({ ...lazyParams });
                    }
                    }
                    disabled={lazyParams.filters[field]?.length === 0 ? true : false}
                />
            </>
        )
    }

    const filterDropdown = (field) => {
        return (
            <>
                <Dropdown
                    id={field + "Filter"}
                    style={{ width: "80%", borderRadius: 0, display: "inline-flex" }}
                    value={lazyParams.filters[field]}
                    options={/*field === "polTypID" ? types : */ubzs}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.value;
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                />
                <Button
                    style={{ width: "30px", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters[field] === "" ? true : false}
                />
            </>
        )
    }

    const deletePolicy = async () => {
        let response = await policyService.deletePolicy(selection.polID);
        if (response.status === 0) {
            toast.current.show({ severity: 'success', summary: 'Sukces', detail: 'Pomyślnie usunięto polisę!', closable: true })
            loadLazyData();
        } else
            toast.current.show({ severity: 'warn', summary: 'Błąd', detail: 'Nie udało się usunąć polisy!', closable: true })
    }

    const saveSold = async () => {
        let response = await policyService.setSold(sold);
        if (response.status === 0) {
            toast.current.show({ severity: 'success', summary: 'Sukces', detail: 'Pomyślnie dodano informację!', closable: true })
            setSoldVisible(false);
            setSold({ polID: null, saleDate: moment().format(), notificationDate: moment().format() });
            loadLazyData();
        } else
            toast.current.show({ severity: 'warn', summary: 'Błąd', detail: 'Nie udało się dodać informacji!', closable: true })
    }

    const saveBreaking = async () => {
        let response = await policyService.setBreaking(breaking);
        if (response.status === 0) {
            toast.current.show({ severity: 'success', summary: 'Sukces', detail: 'Pomyślnie dodano informację!', closable: true })
            setBreakingVisible(false);
            setBreaking({ polID: null, date: moment().format(), reason: "" });
            loadLazyData();
        } else
            toast.current.show({ severity: 'warn', summary: 'Błąd', detail: 'Nie udało się dodać informacji!', closable: true })
    }

    return (
        <>
            <Toast ref={toast} />

            <div className='grid justify-content-center'>
                <div className='ml-6 mr-6 mt-3 table-header'>
                    Moje polisy
                </div>
                <ContextMenu
                    model={[
                        {
                            label: "Usuń", icon: "pi pi-fw pi-trash", command: () => deletePolicy()
                        }
                    ]}
                    ref={cm}
                />
                <DataTable
                    className='ml-6 mr-6 mb-3 f-w'
                    value={policies}
                    loading={loading}
                    lazy
                    paginator
                    rows={lazyParams.rows}
                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                    dataKey="polID"
                    filterDisplay='row'
                    responsiveLayout='scroll'
                    first={lazyParams.first}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onSort={onSort}
                    emptyMessage="Brak wyników"
                    onRowDoubleClick={(e) => props.searchMode ? props.onChoice(e.data.polID, e.data.polNumerTxt) : setPolicyDetails(e.data)}
                    onContextMenuSelectionChange={e => setSelection(e.value)}
                    onContextMenu={e => cm.current.show(e.originalEvent)}
                >
                    <Column field="polNumerTxt" header="Numer" sortable filter={true} showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} />
                    <Column field="pkNazwa" header="Klient" sortable filter={true} showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} />
                    <Column field="polDataZaw" header="Data zawarcia" sortable filter showFilterMenu={false} filterElement={(e) => filterCalendar(e.field)} body={(rowData) => { return rowData.polDataZaw ? moment(rowData.polDataZaw).format('DD/MM/yy') : "Brak" }} showClearButton={false} />
                    <Column field="polDataOd" header="Data od" sortable filter showFilterMenu={false} filterElement={(e) => filterCalendar(e.field)} body={(rowData) => { return rowData.polDataOd ? moment(rowData.polDataOd).format('DD/MM/yy') : "Brak" }} showClearButton={false} />
                    <Column field="polDataDo" header="Data do" sortable filter showFilterMenu={false} filterElement={(e) => filterCalendar(e.field)} body={(rowData) => { return rowData.polDataDo ? moment(rowData.polDataDo).format('DD/MM/yy') : "Brak" }} showClearButton={false} />
                    <Column style={{ width: "15%" }} field="polUbzID" header="Kod TU" sortable filter showFilterMenu={false} filterElement={(e) => filterDropdown(e.field)} showClearButton={false} body={(rowData) => ubzs.length && rowData.polUbzID ? ubzs.find(u => u.value === rowData.polUbzID).label : "Brak"} />
                    <Column style={{ width: "150px" }} field="polSklCalk" header="Składka" sortable showFilterMenu={false} bodyClassName='text-right' body={(rowData) => rowData.polSklCalk !== null ? rowData.polSklCalk.toFixed(2)+' '+rowData.polWal : 'Brak'} />
                </DataTable>
            </div>

            <Dialog
                visible={soldVisible}
                header="Sprzedaż"
                onHide={() => { setSoldVisible(false); setSold({ polID: null, saleDate: moment().format(), notificationDate: moment().format() }); }}
                dismissableMask
                maximizable
                className='col-4'
                onShow={() => setSold({ ...sold, polID: policyDetails.polID })}
            >
                <div className='grid col-12 justify-content-center'>
                    <div className='col-10 p-3'>
                        <span className="p-float-label">
                            <Calendar
                                disabled={policyDetails.polSprzedana}
                                id="sdDate"
                                className='f-w'
                                value={moment(policyDetails.polSprzedanaData || sold.saleDate).toDate()}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setSold({ ...sold, saleDate: moment(e.value).format() });
                                }}
                                showIcon
                            />
                            <label htmlFor='sdDate'>Data sprzedaży:</label>
                        </span>
                    </div>
                    <div className='col-10 p-3'>
                        <span className="p-float-label">
                            <Calendar
                                disabled={policyDetails.polSprzedana}
                                id="sdNotDate"
                                className='f-w'
                                value={moment(policyDetails.polSprzedanaDaPrz || sold.notificationDate).toDate()}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setSold({ ...sold, notificationDate: moment(e.value).format() });
                                }}
                                showIcon
                            />
                            <label htmlFor='sdNotDate'>Data powiadomienia:</label>
                        </span>
                    </div>
                    {policyDetails.polSprzedana ?
                        null
                        :
                        <Button
                            label='Zapisz'
                            icon='pi pi-fw pi-save'
                            onClick={saveSold}
                        />
                    }
                </div>
            </Dialog>

            <Dialog
                visible={breakingVisible}
                header="Zerwanie"
                onHide={() => { setBreakingVisible(false); setBreaking({ polID: null, date: moment().format(), reason: "" }); }}
                dismissableMask
                maximizable
                className='col-4'
                onShow={() => setBreaking({ ...breaking, polID: policyDetails.polID })}
            >
                <div className='grid col-12 justify-content-center'>
                    <div className='col-10 p-3'>
                        <span className="p-float-label">
                            <Calendar
                                disabled={policyDetails.polZerwana}
                                id='brDate'
                                className='f-w'
                                value={moment(policyDetails.polZerwanaData || breaking.date).toDate()}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setSold({ ...sold, saleDate: moment(e.value).format() });
                                }}
                                showIcon
                            />
                            <label htmlFor='brDate'>Data zerwania:</label>
                        </span>
                    </div>
                    <div className='col-10 p-3'>
                        <span className="p-float-label">
                            <InputTextarea
                                disabled={policyDetails.polZerwana}
                                id='brReason'
                                className='f-w'
                                value={policyDetails.polZerwanaPowod || breaking.reason}
                                onChange={(e) => setBreaking({ ...breaking, reason: e.target.value })}
                            />
                            <label htmlFor='brReason'>Powód:</label>
                        </span>
                    </div>
                    {policyDetails.polZerwana ?
                        null
                        :
                        <Button
                            label='Zapisz'
                            icon='pi pi-fw pi-save'
                            onClick={saveBreaking}
                        />
                    }
                </div>
            </Dialog>

            <Dialog
                visible={policyDetails.polID !== null}
                header={"Polisa " + policyDetails.polNumerTxt}
                onHide={() => { setPolicyDetails({ polID: null, polNumerTxt: "" }); setEditMode(false); loadLazyData() }}
                dismissableMask
                maximizable
                className='col-12'
                icons={<>
                    <Button label="Sprzedaż" icon="pi pi-fw pi-credit-card" className='mr-3' onClick={() => setSoldVisible(true)} />
                    <Button label="Zerwanie" icon="pi pi-fw pi-ban" className='mr-3' onClick={() => setBreakingVisible(true)} />
                    {editMode ?
                        <Button label="Wróć" icon="pi pi-fw pi-arrow-left" className='mr-3' onClick={() => setEditMode(false)} />
                        :
                        <Button label="Edytuj" icon="pi pi-fw pi-pencil" className='mr-3' onClick={() => setEditMode(true)} />
                    }
                </>
                }
            >
                {editMode ?
                    <EditPolicy
                        id={policyDetails.polID}
                        editNumber={(number) => setPolicyDetails({ ...policyDetails, polNumerTxt: number })}
                        user={props.user}
                    />
                    :
                    <PolicyDetails
                        id={policyDetails.polID}
                    />
                }
            </Dialog>
        </>
    )
}