import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react"
import clientService from "../../services/clientService";
import dictionaryService from "../../services/dictionaryService";
import ClientAddress from "../details/ClientAddressDetails";

export default function AddClient(props) {

    const [client, setClient] = useState({})
    const [status, setStatus] = useState([]);
    const [types, setTypes] = useState([]);
    const [idTypes, setIDTypes] = useState([]);
    const [addressTypes, setAddressTypes] = useState([]);
    const [clientAddress, setClientAddress] = useState([]);
    const [addAddressVisible, setAddAddressVisible] = useState(false);
    const [selection, setSelection] = useState({});

    const toast = useRef(null);
    const cm = useRef(null);

    const ynArr = [{ label: "Tak", value: 1 }, { label: "Nie", value: 0 }];

    useEffect(() => {
        const loadDictionary = async () => {
            let response = await dictionaryService.getClientStatus();
            setStatus(response);
            response = await dictionaryService.getClientTypes();
            setTypes(response);
            response = await dictionaryService.getIDTypes();
            setIDTypes(response);
            response = await dictionaryService.getAddressTypes();
            setAddressTypes(response);
        }

        loadDictionary();
    }, [])

    const saveClient = async () => {
        if (client.kliTypID && ((client.kliImie && client.kliNazwisko) || client.kliNazwa) && (client.kliTel || client.kliMobil) && clientAddress.length && client.kliStatusID) {
            let response = await clientService.createClient(client, clientAddress);
            if (response.status === 0)
                props.close(response.kliID, response.kliINN);
            else
                toast.current.show({ severity: 'warn', summary: "Błąd", detail: 'Nie udało się dodać klienta!', closable: true })
        } else {
            let str = 'Brak wymaganych danych: ';
            let c = 0;
            if ((typeof client.kliImie === 'undefined' || typeof client.kliNazwisko === 'undefined') && typeof client.kliNazwa === 'undefined') {
                str = str.concat("imię i nazwisko lub nazwa");
                c++;
            }
            if (typeof client.kliTel === 'undefined' && typeof client.kliMobil === 'undefined') {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat("telefon stacjonarny lub komórkowy");
                c++;
            }
            if (typeof client.kliStatusID === 'undefined') {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('status');
                c++;
            }
            if (typeof client.kliTypID === 'undefined') {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('typ');
                c++;
            }
            if (clientAddress.length === 0) {
                if (c > 0)
                    str = str.concat(', ')
                str = str.concat('adres');
                c++;
            }
            toast.current.show({ severity: 'warn', summary: "Błąd", detail: str, closable: true })
        }
    }

    const extractDate = (pesel) => {
        let day = pesel.substring(4, 6);
        let month = parseInt(pesel.substring(2, 4));
        let monthS = pesel.substring(2, 4)
        let year = parseInt(pesel.substring(0, 2));
        switch (parseInt(monthS.substring(0, 1))) {
            case 2:
            case 3:
                month -= 20;
                year += 2000;
                break;
            case 4:
            case 5:
                month -= 40;
                year += 2100;
                break;
            case 6:
            case 7:
                month -= 60;
                year += 2200;
                break;
            case 8:
            case 9:
                month -= 80;
                year += 1800;
                break;
            default:
                year += 1900;
                break;
        }
        let date = moment(day + "/" + month + "/" + year, 'DD/M/YYYY');
        return date.toDate();
    }

    return (
        <>
            <Toast ref={toast} />
            {typeof client.kliTypID === 'undefined' ?
                <div className="grid justify-content-center">
                    <div className="col-1 text-right text-red">
                        Typ:
                    </div>
                    <div className="col-3 min-h-3rem">
                        <Dropdown
                            value={client.kliTypID}
                            options={types}
                            onChange={(e) => {
                                if (e.value !== 1)
                                    client.kliBezPESEL = true;
                                else
                                    client.kliBezPESEL = false;
                                client.kliTypID = e.value;
                                setClient({ ...client });
                            }}
                            className="f-w"
                        />
                    </div>
                </div>
                :
                <>
                    <div className="grid col-12 m-0 p-0 justify-content-center">
                        <div className="col-3 text-right text-red">
                            Imię:
                        </div>
                        <div className="col-9 min-h-3rem">
                            <InputText
                                keyfilter={/[^^[\]!@#$%*()0-9&"',:=+{};<>?\\/|]/}
                                value={client.kliImie}
                                onChange={(e) => {
                                    client.kliImie = e.target.value;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Nazwisko:
                        </div>
                        <div className="col-9 min-h-3rem">
                            <InputText
                                keyfilter={/[^^[\]!@#$%*()0-9&"',:=+{};<>?\\/|]/}
                                value={client.kliNazwisko}
                                onChange={(e) => {
                                    client.kliNazwisko = e.target.value;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Nazwa:
                        </div>
                        <div className="col-9 min-h-3rem">
                            <InputText
                                keyfilter={/[^^[\]!@#$%*()=+{};<>?\\/|]/}
                                value={client.kliNazwa}
                                onChange={(e) => {
                                    client.kliNazwa = e.target.value;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                    </div>
                    <div className="grid col-12 m-0 p-0 justify-content-center">
                        <div className="grid col-4 m-0">
                            <div className="col-5 text-right text-red">
                                Drugie imię:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <InputText
                                    keyfilter={'alpha'}
                                    value={client.kliImie2}
                                    onChange={(e) => {
                                        client.kliImie2 = e.target.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 text-right text-red">
                                PESEL:
                            </div>
                            <div className="col-2 min-h-3rem">
                                <Checkbox
                                    disabled={client.kliTypID !== 1}
                                    className="p-checkbox-mod f-w"
                                    checked={!client.kliBezPESEL}
                                    onChange={(e) => {
                                        client.kliBezPESEL = !e.checked;
                                        setClient({ ...client });
                                    }}
                                />
                            </div>
                            <div className="col-5 min-h-3rem pl-0 ml-0">
                                <InputMask
                                    disabled={client.kliBezPESEL}
                                    mask="99999999999"
                                    value={client.kliPESEL}
                                    maxLength={11}
                                    onChange={(e) => {
                                        client.kliPESEL = e.value;
                                        if (e.value) {
                                            client.kliDataUr = extractDate(e.value)
                                            client.kliPlec = parseInt(e.value[10]) % 2
                                        }
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 text-right text-red">
                                Status:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <Dropdown
                                    value={client.kliStatusID}
                                    options={status}
                                    onChange={(e) => {
                                        client.kliStatusID = e.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 text-right text-red">
                                Telefon:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <InputMask
                                    disabled={client.kliBrakZgodyTE}
                                    mask="99-999-99-99"
                                    value={client.kliTel}
                                    onChange={(e) => {
                                        client.kliTel = e.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                        </div>
                        <div className="grid col-4 m-0">
                            <div className="col-5 text-right text-red">
                                Data urodzenia:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <Calendar
                                    disabled={client.kliTypID === 2}
                                    value={client.kliDataUr ? moment(client.kliDataUr).toDate() : moment().toDate()}
                                    dateFormat="dd/mm/yy"
                                    onChange={(e) => {
                                        client.kliDataUr = moment(e.value).format();
                                        setClient({ ...client });
                                    }}
                                    monthNavigator
                                    yearNavigator
                                    yearRange={"1900:2030"}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 text-right text-red">
                                Typ:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <Dropdown
                                    value={client.kliTypID}
                                    options={types}
                                    onChange={(e) => {
                                        if (e.value !== 1)
                                            client.kliBezPESEL = true;
                                        else
                                            client.kliBezPESEL = false;
                                        client.kliTypID = e.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 text-right text-red">
                                NIP:
                            </div>
                            <div className="col-2 min-h-3rem mr-0 pr-0">
                                <InputText
                                    disabled={client.kliTypID !== 2}
                                    keyfilter={'alpha'}
                                    value={client.kliNIPKraj}
                                    onChange={(e) => {
                                        client.kliNIPKraj = e.target.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 min-h-3rem ml-0 pl-0">
                                <InputMask
                                    disabled={client.kliTypID !== 2}
                                    mask="9999999999"
                                    value={client.kliNIP}
                                    onChange={(e) => {
                                        client.kliNIP = e.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 text-right text-red">
                                Tel. kom.:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <InputMask
                                    disabled={client.kliBrakZgodyTE}
                                    mask="999-999-999"
                                    value={client.kliMobil}
                                    onChange={(e) => {
                                        client.kliMobil = e.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                        </div>
                        <div className="grid col-4">
                            <div className="col-5 text-right text-red">
                                Płeć:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <Dropdown
                                    value={client.kliPlec}
                                    options={[{ label: "Brak danych", value: null }, { label: "Kobieta", value: 0 }, { label: "Mężczyzna", value: 1 }]}
                                    onChange={(e) => {
                                        client.kliPlec = e.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 text-right text-red">
                                REGON:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <InputMask
                                    mask="999999999"
                                    value={client.kliREGON}
                                    onChange={(e) => {
                                        client.kliREGON = e.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 text-right text-red">
                                Niechciany:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <Dropdown
                                    value={client.kliNiechciany}
                                    options={ynArr}
                                    onChange={(e) => {
                                        client.kliNiechciany = e.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                            <div className="col-5 text-right text-red">
                                Tel. kom. 2:
                            </div>
                            <div className="col-7 min-h-3rem">
                                <InputMask
                                    disabled={client.kliBrakZgodyTE}
                                    mask="999-999-999"
                                    value={client.kliMobil2}
                                    onChange={(e) => {
                                        client.kliMobil2 = e.value;
                                        setClient({ ...client });
                                    }}
                                    className="f-w"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid col-12 m-0 p-0 justify-content-center">
                        <div className="col-3 text-right text-red">
                            E-mail:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <InputText
                                disabled={client.kliBrakZgodyTE}
                                value={client.kliEMail}
                                keyfilter="email"
                                onChange={(e) => {
                                    client.kliEMail = e.target.value;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Brak zgody telefon/e-mail:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <Checkbox
                                checked={client.kliBrakZgodyTE}
                                onChange={(e) => {
                                    client.kliBrakZgodyTE = e.checked;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Typ dowodu:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <Dropdown
                                value={client.kliDowodID}
                                options={idTypes}
                                onChange={(e) => {

                                    client.kliDowodID = e.value;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Nr dowodu:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <InputText
                                keyfilter={/[^^[\]!@#$%*()&"',:=+{};<>?\\/|]/}
                                value={client.kliDowodNr}
                                onChange={(e) => {

                                    client.kliDowodNr = e.target.value;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Zgoda na przetwarzanie:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <Checkbox
                                checked={client.kliZgoda}
                                onChange={(e) => {

                                    client.kliZgoda = e.checked;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Data zgody na przetwarzanie:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <Calendar
                                disabled={!client.kliZgoda}
                                value={client.kliZgData ? moment(client.kliZgData).toDate() : null}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    client.kliZgData = moment(e.value).format();
                                    setClient({ ...client });
                                }}
                                monthNavigator
                                yearNavigator
                                yearRange={"1900:2030"}
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Zgoda RODO:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <Checkbox
                                checked={client.kliZgodaR}
                                onChange={(e) => {

                                    client.kliZgodaR = e.checked;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Data zgody RODO:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <Calendar
                                disabled={!client.kliZgodaR}
                                value={client.kliZgRData ? moment(client.kliZgRData).toDate() : null}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {

                                    client.kliZgRData = moment(e.value).format();
                                    setClient({ ...client });
                                }}
                                monthNavigator
                                yearNavigator
                                yearRange={"1900:2030"}
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Prawo jazdy:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <Checkbox
                                checked={client.kliPJ}
                                onChange={(e) => {
                                    client.kliPJ = e.checked;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Data prawa jazdy:
                        </div>
                        <div className="col-3 min-h-3rem">
                            <Calendar
                                disabled={!client.kliPJ}
                                value={client.kliDataPr ? moment(client.kliDataPr).toDate() : null}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    client.kliDataPr = moment(e.value).format();
                                    setClient({ ...client });
                                }}
                                monthNavigator
                                yearNavigator
                                yearRange={"1900:2030"}
                            />
                        </div>
                        <div className="col-3 text-right text-red">
                            Komentarz:
                        </div>
                        <div className="col-9 min-h-3rem">
                            <InputText
                                value={client.kliKomentarz}
                                onChange={(e) => {

                                    client.kliKomentarz = e.target.value;
                                    setClient({ ...client });
                                }}
                                className="f-w"
                            />
                        </div>
                    </div>
                    <div className="grid col-12">
                        <div style={{ width: "30px" }} className="m-2">
                            <Button
                                icon="pi pi-fw pi-plus"
                                onClick={() => setAddAddressVisible(true)}
                            />
                        </div>
                        <div className="col">
                            <ContextMenu
                                model={[
                                    { label: "Usuń", icon: "pi pi-fw pi-trash", command: () => setClientAddress(clientAddress.filter(u => u.id !== selection.id)) }
                                ]}
                                ref={cm}
                            />
                            <DataTable
                                value={clientAddress}
                                dataKey="id"
                                responsiveLayout='scroll'
                                emptyMessage="Brak wyników"
                                onContextMenuSelectionChange={e => setSelection(e.value)}
                                onContextMenu={e => cm.current.show(e.originalEvent)}
                            >
                                <Column header="Typ" field="kaTypID" body={(rowData) => { return addressTypes.find(t => t.value === rowData.kaTypID).label }} />
                                <Column header="Adres" field="kaAdres" />
                                <Column header="Nr domu" field="kaNrDomu" />
                                <Column header="Nr mieszkania" field="kaNrMiesz" />
                                <Column header="Miasto" field="kaMiasto" />
                                <Column header="Kod pocztowy" field="kaKodPocz" />
                            </DataTable>
                        </div>
                    </div>

                    <div className="grid col-12 mt-3 justify-content-center">
                        <Button
                            className="odf-shine"
                            label="Zapisz"
                            icon="pi pi-fw pi-save"
                            onClick={() => saveClient()}
                        />
                    </div>
                </>
            }

            <Dialog
                visible={addAddressVisible}
                header={"Adres - " + (client.kliNazwa || (client.kliImie + " " + client.kliNazwisko))}
                onHide={() => setAddAddressVisible(false)}
                dismissableMask

                className="col-8"
            >
                <ClientAddress addMode={true} addrCount={clientAddress.length} getNewAddress={(address) => { clientAddress.push(address); setClientAddress([...clientAddress]); setAddAddressVisible(false) }} />
            </Dialog>

        </>
    )
}