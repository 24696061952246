/* eslint-disable react-hooks/exhaustive-deps */

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from "primereact/column";
import { useEffect, useRef, useState } from 'react';
import objectsService from '../services/objectsService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import dictionaryService from '../services/dictionaryService';
import { formatAddress } from '../common/tools';
import { Accordion, AccordionTab } from 'primereact/accordion';
import InsuranceObjectDetails from './details/InsuranceObjectDetails';
import AddInsObj from './adders/AddInsObj';


export default function InsuranceObjects(props) {
    const [objects, setObjects] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            kliImie: "",
            kliNazwisko: "",
            kliNazwa: "",
            ouNazwa: "",
            ouTypID: "",
            ouMiasto: "",
            ouKodPocz: "",
            ouAdres: "",
        }
    });
    const [objectsDetails, setObjectDetails] = useState({ ouID: null, ouNazwa: "" });
    const [types, setTypes] = useState([]);

    const [addInsObjVisible, setAddInsObjVisible] = useState(false)

    const toast = useRef();

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        const loadDictionary = async () => {
            let response = await dictionaryService.getObjectTypes();
            setTypes(response);
        }

        loadDictionary();
    }, [])

    const loadLazyData = async () => {
        setLoading(true);

        let response = await objectsService.getPackage(JSON.stringify({ ...lazyParams, restricted: true}));
        if (response) {
            setTotalRecords(response.totalRecords);
            setObjects(response.objects)
        }
        setLoading(false);
    }

    const onPage = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onSort = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const filterElement = (field) => {
        return (
            <>
                <InputText
                    id={field + "Filter"}
                    style={{ width: "80%", borderRadius: 0 }}
                    value={lazyParams.filters[field]}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.target.value;
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                />
                <Button
                    style={{ width: "20%", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters[field] === "" ? true : false}
                />
            </>
        )
    }

    const filterDropdown = (field) => {
        return (
            <>
                <Dropdown
                    id={field + "Filter"}
                    style={{ width: "80%", borderRadius: 0, display: "inline-flex" }}
                    value={lazyParams.filters[field]}
                    options={types}
                    onChange={(e) => {
                        lazyParams.filters[field] = e.value;
                        lazyParams.first = 0;
                        lazyParams.page = 1;
                        setLazyParams({ ...lazyParams });
                    }}
                />
                <Button
                    style={{ width: "30px", borderRadius: 0 }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters[field] = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters[field] === "" ? true : false}
                />
            </>
        )
    }

    const filterClient = () => {
        return (
            <div className='grid col-12 m-0'>
                <Accordion className='col-10 p-0'>
                    <AccordionTab header="Filtry">
                        <InputText
                            id={"imieFilter"}
                            className="m-1"
                            value={lazyParams.filters.kliImie}
                            onChange={(e) => {
                                lazyParams.filters.kliImie = e.target.value;
                                lazyParams.first = 0;
                                lazyParams.page = 1;
                                setLazyParams({ ...lazyParams });
                            }}
                            placeholder="Imię..."
                        />
                        <InputText
                            id={"nazwiskoFilter"}
                            className="m-1"
                            value={lazyParams.filters.kliNazwisko}
                            onChange={(e) => {
                                lazyParams.filters.kliNazwisko = e.target.value;
                                lazyParams.first = 0;
                                lazyParams.page = 1;
                                setLazyParams({ ...lazyParams });
                            }}
                            placeholder="Nazwisko..."
                        />
                        <InputText
                            id={"nazwaFilter"}
                            className="m-1"
                            value={lazyParams.filters.kliNazwa}
                            onChange={(e) => {
                                lazyParams.filters.kliNazwa = e.target.value;
                                lazyParams.first = 0;
                                lazyParams.page = 1;
                                setLazyParams({ ...lazyParams });
                            }}
                            placeholder="Nazwa..."
                        />
                    </AccordionTab>
                </Accordion>
                <Button
                    className='col-2'
                    style={{ borderRadius: 0, height: "46px" }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters.kliImie = "";
                        lazyParams.filters.kliNazwisko = "";
                        lazyParams.filters.kliNazwa = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters.kliImie === "" && lazyParams.filters.kliNazwisko === "" && lazyParams.filters.kliNazwa === "" ? true : false}
                />
            </div>
        )
    }

    const filterAddress = () => {
        return (
            <div className='grid col-12 m-0'>
                <Accordion className='col-10 p-0'>
                    <AccordionTab header="Filtry">
                        <InputText
                            id={"miastoFilter"}
                            className="m-1"
                            value={lazyParams.filters.ouMiasto}
                            onChange={(e) => {
                                lazyParams.filters.ouMiasto = e.target.value;
                                lazyParams.first = 0;
                                lazyParams.page = 1;
                                setLazyParams({ ...lazyParams });
                            }}
                            placeholder="Miasto..."
                        />
                        <InputText
                            id={"ulicaFilter"}
                            className="m-1"
                            value={lazyParams.filters.ouAdres}
                            onChange={(e) => {
                                lazyParams.filters.ouAdres = e.target.value;
                                lazyParams.first = 0;
                                lazyParams.page = 1;
                                setLazyParams({ ...lazyParams });
                            }}
                            placeholder="Ulica..."
                        />
                        <InputText
                            id={"kodPoczFilter"}
                            className="m-1"
                            value={lazyParams.filters.ouKodPocz}
                            onChange={(e) => {
                                lazyParams.filters.ouKodPocz = e.target.value;
                                lazyParams.first = 0;
                                lazyParams.page = 1;
                                setLazyParams({ ...lazyParams });
                            }}
                            placeholder="Kod pocztowy..."
                        />
                    </AccordionTab>
                </Accordion>
                <Button
                    className='col-2'
                    style={{ borderRadius: 0, height: "46px" }}
                    icon="pi pi-fw pi-filter-slash"
                    onClick={() => {
                        lazyParams.filters.kliImie = "";
                        lazyParams.filters.kliNazwisko = "";
                        lazyParams.filters.kliNazwa = "";
                        setLazyParams({ ...lazyParams });
                    }}
                    disabled={lazyParams.filters.ouMiasto === "" && lazyParams.filters.ouAdres === "" && lazyParams.filters.ouKodPocz === "" ? true : false}
                />
            </div>
        )
    }


    return (
        <>
            <Toast ref={toast} />

            <div className='grid justify-content-center'>
                <div className='flex col text-center ml-6 mr-6 mt-3 table-header justify-content-center'>
                    <div className='ml-6 mr-6'>Obiekty ubezpieczenia</div>
                    <div className='pr-0'>
                        <Button
                            label='Dodaj'
                            icon='pi pi-fw pi-plus'
                            onClick={() => setAddInsObjVisible(true)}
                        />
                    </div>
                </div>
                <DataTable
                    className='ml-6 mr-6 mb-3 f-w'
                    value={objects}
                    loading={loading}
                    lazy
                    paginator
                    rows={lazyParams.rows}
                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                    dataKey="ouID"
                    filterDisplay='row'
                    responsiveLayout='scroll'
                    first={lazyParams.first}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onSort={onSort}
                    emptyMessage="Brak wyników"
                    onRowDoubleClick={(e) => props.searchMode ? props.onChoice(e.data.ouID, e.data.kliINN) : setObjectDetails({ ouID: e.data.ouID, ouNazwa: e.data.ouNazwa })}
                >
                    <Column style={{ width: "30%" }} field="kliINN" header="Klient" sortable filter={true} showFilterMenu={false} filterElement={(e) => filterClient()} showClearButton={false} />
                    <Column field="ouNazwa" header="Nazwa" sortable filter showFilterMenu={false} filterElement={(e) => filterElement(e.field)} showClearButton={false} />
                    <Column field="ouTypID" header="Typ" sortable filter showFilterMenu={false} filterElement={(e) => filterDropdown(e.field)} body={(rowData) => types.length && rowData.ouTypID ? types.find(s => s.value === rowData.ouTypID).label : "Brak"} showClearButton={false} />
                    <Column style={{ width: "25%" }} field="ouPelnyAdres" header="Adres" sortable filter showFilterMenu={false} filterElement={(e) => filterAddress()} body={(rowData) => formatAddress(rowData)} showClearButton={false} />
                </DataTable>
            </div>

            <Dialog
                visible={objectsDetails.ouID !== null}
                header={objectsDetails.ouNazwa}
                onHide={() => setObjectDetails({ ouID: null, ouNazwa: "" })}
                dismissableMask
                maximizable
                className='col-8'
            >
                <InsuranceObjectDetails
                    id={objectsDetails.ouID}
                    close={() => setObjectDetails({ ouID: null, ouNazwa: "" })}
                    success={() => { toast.current.show({ severity: 'success', summary: 'Pomyślnie zapisano zmiany!', closable: true }); loadLazyData() }
                    }
                />
            </Dialog>

            <Dialog
                visible={addInsObjVisible}
                header="Nowy obiekt"
                onHide={() => setAddInsObjVisible(false)}
                dismissableMask
                maximizable
                className='col-10'
            >
                <AddInsObj close={(ouID, ouNazwa) => {
                    if (props.searchMode) {
                        props.onChoice(ouID, ouNazwa);
                    } else {
                        toast.current.show({ severity: 'success', summary: "Sukces", detail: 'Pomyślnie dodano obiekt!', closable: true });
                        loadLazyData();
                        setAddInsObjVisible(false);
                    }
                }} />
            </Dialog>
        </>
    )
}