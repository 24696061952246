import { useEffect, useRef, useState } from "react"
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { InputTextarea } from "primereact/inputtextarea";
import dictionaryService from "../../services/dictionaryService";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import policyService from "../../services/policyService";
import { Dialog } from "primereact/dialog";
import Policies from "../Policies";
import Clients from "../Clients";
import vehicleService from "../../services/vehicleService";
import officeService from "../../services/officeService";

export default function AddAdditionalPrint(props) {

    const [print, setPrint] = useState({});
    const [docKinds, setDocKinds] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [policiesVisible, setPoliciesVisible] = useState(false);
    const [clientsVisible, setClientsVisible] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        const getDictionary = async () => {
            const kinds = await dictionaryService.getDocKinds();
            setDocKinds(kinds);
            const statusL = await dictionaryService.getAddPrintStatus();
            setStatusList(statusL);
        };

        const getDetails = async () => {
            let response = await policyService.getDocDetails(props.id);
            let office = await officeService.getByID(response.ddBiuroID);
            setPrint({ ...response, nieDotyczyPolisy: response.polisa_polID === null, biurNazwa: office.biurNazwa });
        }

        getDictionary();

        if (props.id) {
            getDetails();
        } else
            setPrint({
                nieDotyczyPolisy: false,
                polisa_polID: props.policy.polID,
                ddSeriaTxt: props.policy.polSeriaTxt,
                ddSeriaID: props.policy.polSeriaID,
                ddNumerTxt: props.policy.polNumerTxt,
                ddImNaz: props.policy.polKlient,
                klient_kliID: props.policy.klient_kliID,
                ubz_ubzID: props.policy.polUbzID,
                ubzNazwa: '',
                ddAgentID: null,
                ddRejestracja: props.vehicles[0]?.pojRejestracja ?? "",
                pojazd_pojID: props.vehicles[0]?.pojID ?? null,
                ddNazwaOU: props.otherObjs[0]?.ouNazwa ?? "",
                objektubz_ouID: props.otherObjs[0]?.ouID ?? null,
                ddData: moment().format(),
                srd_rdID: null,
                ddDataSp: null,
                ddRejestracjaN: '',
                ddOpis: '',
                ddStatusID: 1,
                ddStatusT: "Wprowadzony",
                ddBiuroID: props.user.biurID,
            });

    }, [props])


    const validate = async () => {
        let toastQueue = []
        if (!print.nieDotyczyPolisy) {
            if (print.ddNumerTxt === '')
                toastQueue.push({ severity: 'warn', summary: 'Wymagane dane', detail: 'Brak numeru polisy!', life: 3500 });
            if (print.ddSeriaTxt === '')
                toastQueue.push({ severity: 'warn', summary: 'Wymagane dane', detail: 'Brak serii polisy!', life: 3500 });
        }
        if (print.ddNazwaOU === '' && print.ddRejestracja === '')
            toastQueue.push({ severity: 'warn', summary: 'Wymagane dane', detail: 'Należy podać numer rejestracyjny pojazdu lub nazwę innego przedmiotu ubezpieczenia!', life: 3500 });
        if (print.ddImNaz === '')
            toastQueue.push({ severity: 'warn', summary: 'Wymagane dane', detail: 'Brak klienta!', life: 3500 });
        if (print.ubz_ubzID === null)
            toastQueue.push({ severity: 'warn', summary: 'Wymagane dane', detail: 'Brak ubezpieczyciela!', life: 3500 });
        if (print.srd_rdID === null)
            toastQueue.push({ severity: 'warn', summary: 'Wymagane dane', detail: 'Brak rodzaju druku!', life: 3500 });
        if (print.ddOpis === '')
            toastQueue.push({ severity: 'warn', summary: 'Wymagane dane', detail: 'Brak opisu!', life: 3500 });

        switch (print.srd_rdID) {
            case 4:
            case 5:
                if (print.ddDataSp === null)
                    toastQueue.push({ severity: 'warn', summary: 'Wymagane dane', detail: 'Brak daty sprzedaży!', life: 3500 });
                break;
            case 11:
                if (print.ddRejestracjaN === '')
                    toastQueue.push({ severity: 'warn', summary: 'Wymagane dane', detail: 'Brak nowego numeru rejestracyjnego!', life: 3500 });
                break;
            default:
                break;
        }

        if (toastQueue.length) {
            toast.current.show(toastQueue);
            setTimeout(() => toast.current.clear(), 3000)
            return;
        }

        if (print.polisa_polID > 0) {
            switch (print.srd_rdID) {
                case 1:
                case 2:
                case 3:
                    confirmDialog({
                        message: 'Kontynuować?',
                        header: 'Wypowiedzenie lub wyrejerestrowanie prowadzi do zerwania polisy!',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        acceptLabel: "Tak",
                        acceptIcon: "pi pi-fw pi-check-circle",
                        rejectLabel: "Nie",
                        rejectIcon: "pi pi-fw pi-times-circle",
                        accept: save,
                        reject: () => { return }
                    });
                    break;
                case 5:
                    confirmDialog({
                        message: 'Kontynuować?',
                        header: 'Sprzedaż prowadzi do zerwania polisy!',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        acceptLabel: "Tak",
                        acceptIcon: "pi pi-fw pi-check-circle",
                        rejectLabel: "Nie",
                        rejectIcon: "pi pi-fw pi-times-circle",
                        accept: save,
                        reject: () => { return }
                    });
                    break;
                case 6:
                    confirmDialog({
                        message: 'Kontynuować?',
                        header: 'Wniosek o zwrot składki prowadzi do zerwania polisy!',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        acceptLabel: "Tak",
                        acceptIcon: "pi pi-fw pi-check-circle",
                        rejectLabel: "Nie",
                        rejectIcon: "pi pi-fw pi-times-circle",
                        accept: save,
                        reject: () => { return }
                    });
                    break;
                case 10:
                    confirmDialog({
                        message: 'Kontynuować?',
                        header: 'Odstąpienie prowadzi do zerwania polisy!',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        acceptLabel: "Tak",
                        acceptIcon: "pi pi-fw pi-check-circle",
                        rejectLabel: "Nie",
                        rejectIcon: "pi pi-fw pi-times-circle",
                        accept: save,
                        reject: () => { return }
                    });
                    break;
                case 12:
                    confirmDialog({
                        message: 'Kontynuować?',
                        header: 'Zaświadczenie o demontażu prowadzi do zerwania polisy!',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        acceptLabel: "Tak",
                        acceptIcon: "pi pi-fw pi-check-circle",
                        rejectLabel: "Nie",
                        rejectIcon: "pi pi-fw pi-times-circle",
                        accept: save,
                        reject: () => { return }
                    });
                    break;
                default:
                    save();
                    break;
            }
        }
    }

    const save = async () => {
        let ddTekst = "";
        switch (print.srd_rdID) {
            case 1: // Wypowiedzenie
                ddTekst = "Wypowiedzenie, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            case 2: // Wypowiedzenie nabywcy
                ddTekst = "Wypowiedzenie nabywcy, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            case 3: // Wyrejestrowanie pojazdu
                ddTekst = "Wyrejestrowanie pojazdu, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            case 4: // Kupno: umowa kupna-sprzedaży/faktura
                ddTekst = "Kupno: umowa kupna-sprzedaży/faktura, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm") + " data kupna: " + moment(print.ddDataSp).format("YYYY-MM-DD hh:mm");
                break;
            case 5: // Sprzedaż: umowa kupna-sprzedaży/faktura
                ddTekst = "Sprzedaż: umowa kupna-sprzedaży/faktura, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm") + " data sprzedaży: " + moment(print.ddDataSp).format("YYYY-MM-DD hh:mm");
                break;
            case 6: // Wniosek o zwrot składki
                ddTekst = "Wniosek o zwrot składki, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            case 7: // Zaświadczenie o zniżkach
                ddTekst = "Zaświadczenie o zniżkach, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            case 8: // Aneks
                ddTekst = "Aneks, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            case 9: // inne
                ddTekst = "Inny dokument, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            case 10: // Odstąpienie
                ddTekst = "Odstąpienie, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            case 11: // Zmiana numeru rejestracyjnego
                ddTekst = "Zmiana numeru rejestracyjnego, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            case 12: // Zaświadczenie o demontażu
                ddTekst = "Zaświadczenie o demontażu, data wpływu: " + moment(print.ddData).format("YYYY-MM-DD hh:mm");
                break;
            default:
                break;
        }

        let response = await policyService.addDoc({ ...print, ddTekst })
        if (response) {
            switch (print.srd_rdID) {
                case 1:
                case 2:
                case 3:
                case 6:
                case 10:
                case 12:
                    await policyService.setBreaking({ polID: print.polisa_polID, reason: print.ddTekst, date: print.ddData });
                    break;
                case 5:
                    await policyService.setSold({ polID: print.polisa_polID, notificationDate: print.ddData, saleDate: print.ddDataSp });
                    break;
                case 11:
                    await vehicleService.changeRegistration({ pojID: print.pojazd_pojID, pojRejestracja: print.ddRejestracjaN })
                    break;
                default:
                    break;
            }
            props.onCreate();
        } else
            toast.current.show({ severity: 'error', summary: 'Błąd', detail: 'Nie udało się dodać druku!' })
    }

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="grid">
                <div className="col-2 text-lg text-right">
                    <div className="div-label">Dotyczy polisy:</div>
                    <div className="div-label">Klient:</div>
                    <div className="div-label">TU:</div>
                    <div className="div-label">Agent:</div>
                    <div className="div-label">Rejestracja pojazdu:</div>
                    <div className="div-label">Data przyjęcia:</div>
                    <div className="div-label">Rodzaj dokumentu:</div>
                    {print.srd_rdID === 11 ?
                        <div className="div-label">Nowy nr rejestracyjny:</div>
                        :
                        null
                    }
                    <div className="div-label">Opis:</div>
                </div>
                <div className="grid col-10">
                    <div className="col-8 div-label">
                        <InputText
                            className="w-8rem"
                            value={print.ddSeriaTxt}
                            onChange={() => { }}
                        />
                        <InputText
                            className="w-16rem mx-3"
                            value={print.ddNumerTxt}
                            onChange={() => { }}
                        />
                        <Button
                            label="Szukaj"
                            icon='pi pi-fw pi-search'
                            onClick={() => setPoliciesVisible(true)}
                            disabled={print.nieDotyczyPolisy || props.id !== null}
                        />
                    </div>
                    <div className="col align-self-center div-label">
                        <Checkbox
                            checked={print.nieDotyczyPolisy}
                            onChange={(e) => props.id === null ? e.checked === true ? setPrint({ ...print, nieDotyczyPolisy: e.checked, polisa_polID: 0, ddSeriaID: null, ddNumerTxt: '', ddSeriaTxt: '', ddImNaz: '', klient_kliID: null }) : setPrint({ ...print, nieDotyczyPolisy: e.checked }) : null}
                        />
                        <text className="ml-2">nie dotyczy polisy</text>
                    </div>
                    <div className="col-8 div-label">
                        <InputText
                            className="w-25rem mr-3"
                            value={print.ddImNaz}
                            onChange={(e) => { }}
                        />
                        <Button
                            label="Szukaj"
                            icon='pi pi-fw pi-search'
                            onClick={() => setClientsVisible(true)}
                            disabled={!print.nieDotyczyPolisy || props.id !== null}
                        />
                    </div>
                    <div className="col-12 div-label">
                        <Dropdown
                            value={print.ubz_ubzID}
                            options={props.tuList}
                            className="w-15rem"
                            onChange={(e) => props.id === null ? setPrint({ ...print, ubz_ubzID: e.value, ubzNazwa: props.tuList.find(u => u.value === e.value).label }) : null}
                        />
                    </div>
                    <div className="col-12 div-label">
                        <Dropdown
                            value={print.ddAgentID}
                            options={props.agents}
                            className="w-24rem"
                            onChange={(e) => props.id === null ? setPrint({ ...print, ddAgentID: e.value }) : null}
                        />
                    </div>
                    <div className="col-12 div-label flex">
                        <div className="w-31rem">
                            <InputText
                                keyfilter="alphanum"
                                value={print.ddRejestracja}
                                onChange={(e) => props.id === null ? setPrint({ ...print, ddRejestracja: e.target.value }) : null}
                            />
                            <text className="ml-4">lub nazwa innego obiektu ubezpieczenia: </text>
                        </div>
                        <div style={{ flex: 1, width: "100%" }}>
                            <InputText
                                value={print.ddNazwaOU}
                                onChange={(e) => props.id === null ? setPrint({ ...print, ddNazwaOU: e.target.value }) : null}
                                className="ml-1 f-w"
                            />
                        </div>
                    </div>
                    <div className="col-12 div-label">
                        <Calendar
                            value={moment(print.ddData).toDate()}
                            onChange={(e) => setPrint({ ...print, ddData: moment(e.value).format() })}
                            showIcon
                            className="w-10rem"
                            disabled={props.id !== null}
                        />
                    </div>
                    <div className="col-12 div-label">
                        <Dropdown
                            value={print.srd_rdID}
                            options={docKinds}
                            onChange={(e) => props.id === null ? setPrint({ ...print, srd_rdID: e.value }) : null}
                            className="w-20rem"
                        />
                        {
                            print.srd_rdID === 4 || print.srd_rdID === 5 ?
                                <>
                                    <text className="ml-4">Data sprzedaży: </text>
                                    <Calendar
                                        value={moment(print.ddDataSp).toDate()}
                                        onChange={(e) => props.id === null ? setPrint({ ...print, ddDataSp: moment(e.value).format() }) : null}
                                        showIcon
                                        className="w-10rem"
                                    />
                                </>
                                : null
                        }
                    </div>
                    {
                        print.srd_rdID === 11 ?
                            <div className="col-12 div-label">
                                <InputText
                                    value={print.ddRejestracjaN}
                                    onChange={(e) => props.id === null ? setPrint({ ...print, ddRejestracjaN: e.target.value }) : null}
                                    keyfilter="alphanum"
                                    className="w-15rem"
                                />
                            </div>
                            :
                            null
                    }
                    <div className="col-12">
                        <InputTextarea
                            value={print.ddOpis}
                            onChange={(e) => props.id === null ? setPrint({ ...print, ddOpis: e.target.value }) : null}
                            className="w-full min-h-8rem"
                        />
                    </div>
                    <div className="grid col-12 justify-content-between">
                        <div>
                            Status:
                            <InputText
                                value={statusList.length ? statusList.find(s => s.value === print.ddStatusID)?.label : null}
                                onChange={() => { }}
                                className="ml-2"
                            />
                        </div>
                        <div>
                            Biuro:
                            <InputText
                                value={props.id ? print.biurNazwa : props.user.biurNazwa}
                                onChange={() => { }}
                                className="ml-2"
                            />
                        </div>
                    </div>
                    {
                        print.ddUtworzyl ?
                            <div className="col">
                                <div>
                                    Utworzył: <text className="font-italic">{print.ddUtworzylT + " " + moment(print.ddDataUt).format('DD/MM/YYYY hh:mm')}</text>
                                </div>
                                {
                                    print.ddModyfik ?
                                        <div>
                                            Modyfikował: <text className="font-italic">{print.ddModyfikT + " " + moment(print.ddDataMod).format('DD/MM/YYYY hh:mm')}</text>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            :
                            null
                    }
                </div>
                {props.id ?
                    null
                    :
                    <div className="grid col justify-content-center">
                        <Button
                            label="Zapisz"
                            icon="pi pi-fw pi-save"
                            onClick={validate}
                            className="text-xl"
                        />
                    </div>
                }
            </div>

            <Dialog
                visible={policiesVisible}
                onHide={() => setPoliciesVisible(false)}
                closable
                header="Wybierz polisę"
            >
                <Policies searchMode={true} onChoice={async (polID, polNumerTxt) => {
                    let policy = await policyService.getDetails(polID);
                    let linkedData = await policyService.getPolicyAdditionalData(polID);
                    if (policy) {
                        setPrint({
                            ...print,
                            polisa_polID: polID,
                            ddSeriaTxt: policy.polSeriaTxt,
                            ddSeriaID: policy.polSeriaID,
                            ddNumerTxt: policy.polNumerTxt,
                            ddImNaz: policy.polKlient ?? "",
                            klient_kliID: policy.klient_kliID ?? null,
                            ubz_ubzID: policy.polUbzID,
                            ddRejestracja: linkedData.vehicles[0]?.pojRejestracja ?? "",
                            pojazd_pojID: linkedData.vehicles[0]?.pojID ?? null,
                            ddNazwaOU: linkedData.otherObjs[0]?.ouNazwa ?? "",
                            objektubz_ouID: linkedData.otherObjs[0]?.ouID ?? null,
                            ddData: moment().format(),
                        })
                    }
                    setPoliciesVisible(false);
                }}
                />
            </Dialog>

            <Dialog
                visible={clientsVisible}
                onHide={() => setClientsVisible(false)}
                closable
                header="Wybierz klienta"
            >
                <Clients searchMode={true} onChoice={(kliID, kliINN) => {
                    setPrint({ ...print, klient_kliID: kliID, ddImNaz: kliINN });
                    setClientsVisible(false);
                }} />

            </Dialog>
        </div>
    )

}