import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

import error from '../assets/error.png';

export default function NotFound() {
    let navigate = useNavigate();

    return (
        <div className="outer">
            <div className="grid col-12 justify-content-center">
                <div className="blue-box col-10 sm:col-8 md:col-6 lg:col-6 justify-content-center p-3">
                    <div className="grid">
                        <div className="col-2">
                            <img src={error} alt="error.png" height="80px" width="80px" />
                        </div>
                        <div className="col-8">
                            <div className="col-12 error-font-1">
                                Błąd
                            </div>
                            <div className="col-12 error-font-2">
                                Nie znaleziono strony.
                            </div>
                        </div>
                    </div>
                    <div className="grid col-12 mt-3 justify-content-center">
                        <Button
                            className="odf-shine"
                            label="Wróć"
                            icon="pi pi-fw pi-arrow-circle-left "
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            className="odf-shine ml-3"
                            label="Strona główna"
                            icon="pi pi-fw pi-home"
                            onClick={() => navigate("/")}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}