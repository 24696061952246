/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/policy";

export async function getAll() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAll");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPackage(params) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPackage", {
      params,
    });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getDetails(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getDetails/" + id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function edit(policy) {
  try {
    const promise = await httpService.post(apiEndpoint + "/edit", { params: { policy } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getClientPolicies(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getClientPolicies/" + id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addNew(policy, policyUsers, additionalPrints, instalments, risks, vehicles, insuranceObj, inkaso, remarks) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addNew", { params: { policy, policyUsers, additionalPrints, instalments, risks, vehicles, insuranceObj, inkaso, remarks } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPolicyAdditionalData(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPolicyAdditionalData/" + id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPrevPolicyData(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPrevPolicyData/" + id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deletePolicy(id) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deletePolicy", { params: { id } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addPrints(additionalPrints, policy) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addPrints", { params: { additionalPrints, policy } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deletePrints(additionalPrints) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deletePrints", { params: { additionalPrints } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addPolicyUsers(policyUsers) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addPolicyUsers", { params: { policyUsers } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deletePolicyUsers(policyUsers) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deletePolicyUsers", { params: { policyUsers } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addRisks(risks) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addRisks", { params: { risks } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function editRisk(risk) {
  try {
    const promise = await httpService.post(apiEndpoint + "/editRisk", { params: { risk } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deleteRisks(risks) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deleteRisks", { params: { risks } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addInstalments(instalments) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addInstalments", { params: { instalments } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deleteInstalments(instalments) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deleteInstalments", { params: { instalments } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function editInstalments(instalments) {
  try {
    const promise = await httpService.post(apiEndpoint + "/editInstalments", { params: { instalments } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addInkaso(inkaso) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addInkaso", { params: { inkaso } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deleteInkaso(inkaso) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deleteInkaso", { params: { inkaso } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function editInkaso(inkaso) {
  try {
    const promise = await httpService.post(apiEndpoint + "/editInkaso", { params: { inkaso } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addVehicles(vehicles) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addVehicles", { params: { vehicles } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deleteVehicles(vehicles) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deleteVehicles", { params: { vehicles } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addOtherObjs(otherObjs) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addOtherObjs", { params: { otherObjs } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deleteOtherObjs(otherObjs) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deleteOtherObjs", { params: { otherObjs } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addPolicyRemark(remark, id) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addPolicyRemark", { params: { remark, id } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getSalesPackage(params) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getSalesPackage", {
      params,
    });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function changeStatus(prints) {
  try {
    const promise = await httpService.post(apiEndpoint + "/changeStatus", { params: { prints } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function setBreaking(breaking) {
  try {
    const promise = await httpService.post(apiEndpoint + "/setBreaking", { params: { breaking } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function setSold(sold) {
  try {
    const promise = await httpService.post(apiEndpoint + "/setSold", { params: { sold } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPolicyDocs(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPolicyDocs/" + id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getDocDetails(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getDocDetails/"+id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function addDoc(print) {
  try {
    const promise = await httpService.post(apiEndpoint + "/addDoc", { params: print });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function updateDoc(print) {
  try {
    const promise = await httpService.post(apiEndpoint + "/updateDoc", { params: print });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export default {
  getAll,
  getPackage,
  getDetails,
  edit,
  getClientPolicies,
  addNew,
  getPolicyAdditionalData,
  getPrevPolicyData,
  deletePolicy,
  addPrints,
  deletePrints,
  addPolicyUsers,
  deletePolicyUsers,
  addRisks,
  editRisk,
  deleteRisks,
  addInstalments,
  deleteInstalments,
  editInstalments,
  addInkaso,
  deleteInkaso,
  editInkaso,
  addVehicles,
  deleteVehicles,
  addOtherObjs,
  deleteOtherObjs,
  addPolicyRemark,
  getSalesPackage,
  changeStatus,
  setSold,
  setBreaking,
  getPolicyDocs,
  getDocDetails,
  addDoc,
  updateDoc,
};
