import { useEffect, useRef, useState } from "react"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//import Editor from '@ckeditor/ckeditor5-editor-custom';
import newsService from "../../services/newsService";
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";
import { Calendar } from "primereact/calendar";
import { Image } from "primereact/image";
import { FileUpload } from 'primereact/fileupload';
import { Button } from "primereact/button";
import moment from "moment/moment";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";


export default function AddNews(props) {

    const [article, setArticle] = useState({ visible: true, visibleFrom: moment().format(), body: "", title: "" });
    const [mainImage, setMainImage] = useState({ loading: false });
    const [images, setImages] = useState([]);

    const toast = useRef(null);

    useEffect(() => {

        // return () => {
        //     console.log('del',images)
        //     if(!saved && images.length)
        //         newsService.deleteImages(images)
        // }
    }, [])

    const changeHandler = (event, editor) => {
        const data = editor.getData();
        setArticle({ ...article, body: data });
    }

    function uploadPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const uploadAdapter = (loader) => {
        return {
            upload: () => {
                return loader.file.then(async (file) => {
                    const data = new FormData();
                    data.append("file", file);
                    data.append("mainImg", false);
                    data.append("apiEndpoint", newsService.apiEndpoint);
                    let response = await newsService.uploadImage(data);
                    setImages([...images, response.id]);
                    return response;
                }
                )
            }
        };
    }

    const customBase64Uploader = async (event) => {
        setMainImage({ loading: true });
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob());
        reader.readAsDataURL(blob);
        reader.onloadend = async function () {
            const base64data = reader.result;
            const data = new FormData();
            data.append("file", file);
            data.append("mainImg", true);
            data.append("apiEndpoint", newsService.apiEndpoint);
            let response = await newsService.uploadImage(data);
            setMainImage({ name: file.name, type: file.type, size: file.size, base64: base64data, akzID: response.id, loading: false })
            //setImages([...images, response.id]);
        }
    }

    const headerTemplate = (options) => {
        const { className, chooseButton } = options;

        return (
            <div className={className} style={{ backgroundColor: 'transparent', alignItems: 'center' }}>
                {chooseButton}
            </div>
        );
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{ height: '60px' }}>
                <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="my-3">Przeciągnij i upuść</span>
            </div>
        )
    }

    const addNews = async () => {
        let response = await newsService.createArticle({ ...article, mainImageID: mainImage.akzID }, images);
        if (response.ok) {
            props.success();
        } else {
            toast.current.show({ severity: 'warn', summary: 'Błąd', detail: 'Nie udało się zapisać artykułu!', closable: true });
        }
    }

    return (
        <div className="grid justify-content-center pt-5" style={{ maxWidth: '100vw' }}>
            <Toast ref={toast} />
            <div className="col-3">
                {
                    mainImage.base64 ?
                        <Image
                            alt="Thumbnail"
                            width="200"
                            height="100"
                            preview
                            src={mainImage.base64}
                        />
                        :
                        <div className="fu-component">
                            {
                                mainImage.loading &&
                                <div className="spinner-overlay"><ProgressSpinner /></div>
                            }
                            <FileUpload
                                className="p-fileupload"
                                accept="image/*"
                                name="demo[]"
                                chooseLabel="Wybierz"
                                auto
                                customUpload
                                uploadHandler={customBase64Uploader}
                                headerTemplate={headerTemplate}
                                emptyTemplate={emptyTemplate}
                                chooseOptions={{ icon: 'pi pi-fw pi-images', className: 'custom-choose-btn p-button-rounded f-w' }}
                            />
                        </div>
                }
            </div>
            {
                mainImage ?

                    <Button
                        icon='pi pi-fw pi-trash'
                        className="col-1"
                        style={{ height: '106px', marginTop: '8px' }}
                        onClick={() => setMainImage({loading: false})}
                    />
                    :
                    null
            }
            <div className={mainImage ? "col-8" : "col-9"}>
                <div className="grid col-12">
                    <span className="p-float-label f-w">
                        <InputText
                            id="title"
                            className="f-w"
                            value={article.title}
                            onChange={(e) => setArticle({ ...article, title: e.target.value })}
                        />
                        <label htmlFor="title">Tytuł:</label>
                    </span>
                </div>
                <div className="grid col-12 mt-3 justify-content-between">
                    <div className="grid col">
                        <div className="mr-3">
                            <ToggleButton
                                onLabel="Widoczny"
                                onIcon="pi pi-fw pi-eye"
                                offLabel="Ukryty"
                                offIcon="pi pi-fw pi-eye-slash"
                                checked={article.visible}
                                onChange={(e) => setArticle({ ...article, visible: e.value, visibleFrom: e.value ? moment().format() : null })}
                            />
                        </div>
                        <div>
                            <span className="p-float-label">
                                <Calendar
                                    disabled={article.visible}
                                    id="visibleCal"
                                    dateFormat="dd/mm/yy"
                                    showIcon
                                    minDate={moment().toDate()}
                                    value={article.visibleFrom ? moment(article.visibleFrom).toDate() : null}
                                    onChange={(e) => setArticle({ ...article, visibleFrom: moment(e.value).format() })}
                                />
                                <label htmlFor="visibleCal">Widoczny od:</label>
                            </span>
                        </div>
                    </div>
                    <div className="col-2">
                        <Button
                            label="Zapisz"
                            icon="pi pi-fw pi-save"
                            className="f-w h-3rem"
                            onClick={addNews}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        language: 'pl',
                        extraPlugins: [uploadPlugin],
                    }}
                    data={article.body}
                    onChange={changeHandler}
                />
            </div>
        </div>
    )
}