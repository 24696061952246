import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import AddPolicy from "./adders/AddPolicy";
import ApkPanel from "./ApkPanel";


export default function NewPolicySurvey(props) {

    const [choice, setChoice] = useState(0);
    const [apkInfo, setApkInfo] = useState(null);
    useEffect(() => {

    }, []);

    return (
        <>
            {
                choice === 1 ?
                    <ApkPanel
                        user={props.user}
                        selectionMode={true}
                        onChoice={(apkID, apkNumer) => {
                            setApkInfo({ apkID, apkNumer });
                            setChoice(2);
                        }}
                    />
                    :
                    choice === 2 ?
                        <AddPolicy
                            user={props.user}
                            apk={apkInfo}
                        />
                        :
                        <div className="flex flex-column absolute justify-content-center align-items-center w-full" style={{ height: '90%'}}>
                            <div className="">
                                <Button
                                    label="Stwórz polisę do formularza APK"
                                    onClick={() => setChoice(1)}
                                    className="w-17rem h-4rem"
                                />
                            </div>
                            <div className="m-5">
                                <Button
                                    label="Stwórz polisę bez APK"
                                    onClick={() => setChoice(2)}
                                    className="w-17rem h-4rem"
                                />
                            </div>
                        </div>
            }
        </>
    )
}