/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import jwtDecode from "jwt-decode";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/user";

export function getCurrentID() {
  try {
    const jwt = localStorage.getItem("JWT");
    let decoded = jwtDecode(jwt);
    return decoded.id;
  } catch (error) {
    return false;
  }
}

export async function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("JWT");
    if (jwt) {
      httpService.setJwt(jwt);
      let id = jwtDecode(jwt).id;
      const promise = await httpService.post(apiEndpoint + "/getCurrent", {
        params: { id },
      });
      const { data: sqlObj } = promise;
      return sqlObj;
    }
  } catch (error) {
    return null;
  }
}

export async function getByID(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getByID", {params: {id}});
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getList() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getList");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getAgents() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAgents");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export default {
  getCurrentID,
  getCurrentUser,
  getByID,
  getList,
  getAgents,
};
