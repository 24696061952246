/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import moment from "moment";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";

import { Toast } from "primereact/toast";
import userService from "../../services/userService";
import { Button } from "primereact/button";
import dictionaryService from "../../services/dictionaryService";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import objectsService from "../../services/objectsService";
import { SplitButton } from "primereact/splitbutton";
import { Dialog } from "primereact/dialog";
import ClientDetails from "./ClientDetails";
import Clients from "../Clients";


export default function InsuranceObjectDetails(props) {

    const [details, setDetails] = useState({});
    const [creator, setCreator] = useState();
    const [modifier, setModifier] = useState();
    const [changes, setChanges] = useState(false);
    const [clientChanges, setClientChanges] = useState(false);
    const [types, setTypes] = useState([]);
    const [streetTypes, setStreetTypes] = useState([]);
    const [clientDetailsVisible, setClientDetailsVisible] = useState(false);
    const [clientListVisible, setClientListVisible] = useState(false);


    const toast = useRef();

    useEffect(() => {
        const fetchData = async () => {
            let response = await objectsService.getDetails(props.id);
            setDetails(response);
            let creatorRes = await userService.getByID(response.ouUtworzyl);
            setCreator(creatorRes);
            if (response.ouModyfik) {
                response = await userService.getByID(response.ouModyfik);
                setModifier(response);
            }
        };

        const loadDictionary = async () => {
            let response = await dictionaryService.getObjectTypes();
            setTypes(response);
            response = await dictionaryService.getStreetTypes();
            response.find(r => r.label === " ").label = "-";
            setStreetTypes(response);
        }

        loadDictionary();
        fetchData();
    }, [clientDetailsVisible])

    const saveChanges = async () => {
        let response = await objectsService.edit(details,clientChanges);
        if (response) {
            props.success()
            props.close();
        } else {
            toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Nie udało się zapisać zmian.', life: 5000, closable: true });
        }
    }

    return (
        <>
            <Toast ref={toast} />

            <div className="grid col-12 m-0 p-0 justify-content-center">
                <div className="col-3 text-right text-red">
                    Klient:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.kliINN || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            {details.kliID ?
                                <SplitButton
                                    label={details.kliINN ? details.kliINN.substring(0,20)+"..." : ""}
                                    onClick={() => setClientDetailsVisible(true)}
                                    icon="pi pi-fw pi-search mr-1"
                                    className="f-w"
                                    buttonClassName="pl-1 pr-6"
                                    model={[
                                        {
                                            label: "Usuń",
                                            icon: "pi pi-times",
                                            command: () => {
                                                setChanges(true);
                                                setClientChanges(true);
                                                details.kliID = null;
                                                details.kliINN = null;
                                                setDetails({ ...details });
                                            }
                                        }
                                    ]}
                                />
                                :
                                <Button
                                    icon="pi pi-fw pi-link mr-1"
                                    label="Podepnij"
                                    className="f-w"
                                    onClick={() => setClientListVisible(true)}
                                />
                            }
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Typ:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {types.length && details.ouTypID ? types.find(t => t.value === details.ouTypID).label : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.ouTypID}
                                options={types}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouTypID = e.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Nazwa:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.ouNazwa || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[A-z0-9.,&-]/}
                                value={details.ouNazwa}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouNazwa = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Miasto:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.ouMiasto || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[A-z.-]/}
                                value={details.ouMiasto}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouMiasto = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Typ adresu:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {streetTypes.length && details.ouAdrTyp ? streetTypes.find(s => s.value === details.ouAdrTyp).label : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={details.ouAdrTyp}
                                options={streetTypes}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouAdrTyp = e.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w h-full"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Ulica:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.ouAdres || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[A-z0-9.,&-]/}
                                value={details.ouAdres}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouAdres = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Nr domu:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.ouNrDomu || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[A-z0-9]/}
                                value={details.ouNrDomu}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouNrDomu = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Nr lokalu:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.ouNrLok || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[A-z0-9]/}
                                value={details.ouNrLok}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouNazwa = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Kod pocztowy:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.ouKodPocz || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputMask
                                mask="99-999"
                                value={details.ouKodPocz}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouKodPocz = e.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Poczta:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.ouPoczta || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[A-z.-]/}
                                value={details.ouPoczta}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouPoczta = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Opis:
                </div>
                <div className="col-9 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {details.ouOpis || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputTextarea
                                keyfilter={/[A-z0-9.,&-]/}
                                value={details.ouOpis}
                                onChange={(e) => {
                                    setChanges(true);
                                    details.ouOpis = e.target.value;
                                    setDetails({ ...details });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>

                <div className="col-3 text-right text-red">
                    Utworzył:
                </div>
                <div className="col-3 h-5rem">
                    {creator ? creator.usNazw + " " + creator.usImie : ""}
                    <br />
                    {moment(details.ouDataUt).format("DD/MM/yy HH:mm")}
                </div>
                {modifier ?
                    <>
                        <div className="col-3 text-right text-red">
                            Modyfikował:
                        </div>
                        <div className="col-3">
                            {modifier.usNazw + " " + modifier.usImie}
                            <br />
                            {moment(details.ouDataMod).format("DD/MM/yy HH:mm")}
                        </div>
                    </>
                    :
                    <div className="col-3 h-5rem">

                    </div>
                }
            </div>

            {
                changes ?
                    <div className="grid col-12 mt-3 justify-content-center">
                        <Button
                            className="odf-shine"
                            label="Zapisz"
                            icon="pi pi-fw pi-save"
                            onClick={() => saveChanges()}
                        />
                    </div>
                    :
                    null
            }

            <Dialog
                visible={clientDetailsVisible}
                header={details.kliINN ? details.kliINN.substring(0,20)+"..." : ""}
                onHide={() => setClientDetailsVisible(false)}
                dismissableMask
                maximizable
                className='col-8'
            >
                <ClientDetails
                    id={details.kliID}
                    close={() => setClientDetailsVisible(false)}
                    success={() => {toast.current.show({ severity: 'success', summary: 'Pomyślnie zapisano zmiany!', closable: true });}
                    }
                />
            </Dialog>

            <Dialog
                visible={clientListVisible}
                header={"Podepnij klienta"}
                onHide={() => setClientListVisible(false)}
                dismissableMask
                maximizable
                className='col-8'
            >
                <Clients
                    searchMode={true}
                    onChoice={(kliID,kliINN) => {
                        setClientListVisible(false);
                        setChanges(true);
                        setClientChanges(true);
                        details.kliID = kliID;
                        details.kliINN = kliINN;
                        setDetails({ ...details });
                    }}
                />
            </Dialog>
        </>
    )
}