/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/client";

export async function getAll() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAll");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPackage(params) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPackage", {
      params,
    });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getDetails(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getDetails/"+id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getAddress(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAddress/"+id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function edit(details) {
  try {
    const promise = await httpService.post(apiEndpoint + "/edit", { params : {details}});
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function editAddress(address) {
  try {
    const promise = await httpService.post(apiEndpoint + "/editAddress", { params : {address}});
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function createClient(client,clientAddress) {
  try {
    const promise = await httpService.post(apiEndpoint + "/createClient", { params : {client,clientAddress}});
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function createAddress(kliID,address) {
  try {
    const promise = await httpService.post(apiEndpoint + "/createAddress", { params : {kliID,address}});
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export default {
  getAll,
  getPackage,
  getDetails,
  edit,
  getAddress,
  editAddress,
  createClient,
  createAddress,
};
