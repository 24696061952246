/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/news";

export async function getAll() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAll");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPackage(first, limit = 10, carousel = false) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPackage", { params: { first, limit, carousel } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getTotalRecords() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getTotalRecords");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getDetails(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getDetails/" + id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function createArticle(article, images) {
  try {
    const promise = await httpService.post(apiEndpoint + "/createArticle", { params: { article, images } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function editArticle(article, mainImage, images) {
  try {
    const promise = await httpService.post(apiEndpoint + "/editArticle", { params: { article, mainImage,images } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function uploadImage(file) {
  try {
    const promise = await httpService.post(apiEndpoint + "/uploadImage", file);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deleteImages(ids) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deleteImages", { params: { ids } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function toggleVisible(visible, akID) {
  try {
    const promise = await httpService.post(apiEndpoint + "/toggleVisible", { params: { visible, akID } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function deleteArticle(akID) {
  try {
    const promise = await httpService.post(apiEndpoint + "/deleteArticle", { params: { akID } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export default {
  getAll,
  getPackage,
  getTotalRecords,
  getDetails,
  editArticle,
  createArticle,
  uploadImage,
  deleteImages,
  toggleVisible,
  deleteArticle,
  apiEndpoint
};