import { useEffect, useState } from "react";


export default function SelectButton(props) {

    const [options, setOptions] = useState([{ label: "On", value: true }, { label: "Off", value: false }]);
    const [selection, setSelection] = useState(false);

    useEffect(() => {
        if (props.options) {
            setOptions(props.options);
        }
        if (props.value !== null && typeof props.value !== 'undefined') {
            setSelection(props.value);
        }
    }, [props])

    const getClassName = (option, idx) => {
        let cn = 'custom-sb' + (option.value === selection ? ' sb-on' : ' sb-off');
        if (idx === 0)
            cn += ' sb-l';
        else if (idx === options.length - 1)
            cn += ' sb-r';
        else
            cn += ' sb-m';
        return cn;
    }

    return (
        <div className="grid m-0">
            {
                props.label ?
                    <div className="mr-2">
                        {props.label}
                    </div>
                    :
                    null
            }
            <div className="grid m-0" style={props.divStyle ?? {width: "100%"}}>
                {
                    options.map((o, idx) =>
                        <div className='col p-0' key={idx}>
                            <button
                                className={getClassName(o, idx)}
                                style={props.btnStyle}
                                onClick={() => props.locked ? null : props.onChange(o)}
                            >
                                {o.label}
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )

}