/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import { useState } from "react"
import clientService from "../../services/clientService";
import dictionaryService from "../../services/dictionaryService";
import userService from "../../services/userService";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";

export default function ClientAddress(props) {

    const [address, setAddress] = useState({});
    const [streetTypes, setStreetTypes] = useState([]);
    const [addressTypes, setAddressTypes] = useState([]);
    const [changes, setChanges] = useState(false);
    const [creator, setCreator] = useState();
    const [modifier, setModifier] = useState();

    const toast = useRef();

    useEffect(() => {
        const fetchData = async () => {
            let response;
            if (props.addMode !== true) {
                response = await clientService.getAddress(props.clientID);
                setAddress(response);
                let creatorRes = await userService.getByID(response.kaUtworzyl);
                setCreator(creatorRes);
                if (response.kaModyfik) {
                    response = await userService.getByID(response.kaModyfik);
                    setModifier(response);
                }
            }
            response = await dictionaryService.getStreetTypes();
            response.find(r => r.label === " ").label = "-";
            setStreetTypes(response);
            response = await dictionaryService.getAddressTypes();
            setAddressTypes(response);
        }

        fetchData();
    }, [])

    const createAddress = () => {
        props.getNewAddress({ ...address, id: props.addrCount });
    }

    const saveChanges = async () => {
        let response = await clientService.editAddress(address);
        if (response) {
            props.showSuccessToast()
            props.close();
        } else {
            toast.current.show({ severity: 'error', summary: 'Błąd!', detail: 'Nie udało się zapisać zmian.', life: 5000, closable: true });
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="grid col-12 justify-content-center">
                <div className="col-3 text-right text-red">
                    Rodzaj adresu:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {addressTypes.length && address.kaTypID ? addressTypes.find(t => t.value === address.kaTypID).label : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={address.kaTypID}
                                options={addressTypes}
                                onChange={(e) => {
                                    setChanges(true);
                                    address.kaTypID = e.value;
                                    setAddress({ ...address });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Miasto:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {address.kaMiasto || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[^^[\]!@#$%*()0-9&"',:=+{};<>?\\/|]/}
                                value={address.kaMiasto}
                                onChange={(e) => {
                                    setChanges(true);
                                    address.kaMiasto = e.target.value;
                                    setAddress({ ...address });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Kod pocztowy:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {address.kaKodPocz || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputMask
                                className="f-w"
                                value={address.kaKodPocz}
                                mask="99-999"
                                onChange={(e) => { address.kaKodPocz = e.value; setAddress({ ...address }); }}
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Poczta:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {address.kaPoczta || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[^^[\]!@#$%*()0-9&"',:=+{};<>?\\/|]/}
                                value={address.kaPoczta}
                                onChange={(e) => {
                                    setChanges(true);
                                    address.kaPoczta = e.target.value;
                                    setAddress({ ...address });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Typ adresu:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {streetTypes.length && address.kaAdrTypID ? streetTypes.find(t => t.value === address.kaAdrTypID).label : "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={address.kaAdrTypID}
                                options={streetTypes}
                                onChange={(e) => {
                                    setChanges(true);
                                    address.kaAdrTypID = e.value;
                                    setAddress({ ...address });
                                }}
                                className="f-w h-full"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Ulica:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {address.kaAdres || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[A-z0-9 .,]/}
                                value={address.kaAdres}
                                onChange={(e) => {
                                    setChanges(true);
                                    address.kaAdres = e.target.value;
                                    setAddress({ ...address });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Nr domu:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {address.kaNrDomu || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter={/[^^[\]!@#$%*()&"',:=+{};<>?\\/|]/}
                                value={address.kaNrDomu}
                                onChange={(e) => {
                                    setChanges(true);
                                    address.kaNrDomu = e.target.value;
                                    setAddress({ ...address });

                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Nr mieszkania:
                </div>
                <div className="col-3 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {address.kaNrMiesz || "Brak"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <InputText
                                keyfilter="num"
                                value={address.kaNrMiesz}
                                onChange={(e) => {
                                    setChanges(true);
                                    address.kaNrMiesz = e.target.value;
                                    setAddress({ ...address });
                                }}
                                className="f-w"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="col-3 text-right text-red">
                    Aktualny:
                </div>
                <div className="col-9 min-h-3rem">
                    <Inplace>
                        <InplaceDisplay>
                            {address.kaAktual ? "Tak" : "Nie"}
                        </InplaceDisplay>
                        <InplaceContent>
                            <Dropdown
                                value={address.kaAktual}
                                options={[{ label: "Tak", value: 1 }, { label: "Nie", value: 0 }]}
                                onChange={(e) => {
                                    setChanges(true);
                                    address.kaAktual = e.value;
                                    setAddress({ ...address });
                                }}
                                className="f-w h-full"
                            />
                        </InplaceContent>
                    </Inplace>
                </div>
                {
                    props.addMode ?
                        <div className="grid col-12 mt-3 justify-content-center">
                            <Button
                                className="odf-shine"
                                label="Zapisz"
                                icon="pi pi-fw pi-save"
                                onClick={() => createAddress()}
                            />
                        </div>
                        :
                        <>
                            <div className="col-3 text-right text-red">
                                Utworzył:
                            </div>
                            <div className="col-3 h-5rem">
                                {creator ? creator.usNazw + " " + creator.usImie : ""}
                                <br />
                                {moment(address.kaDataUt).format("DD/MM/yy HH:mm")}
                            </div>
                            {
                                modifier ?
                                    <>
                                        <div className="col-3 text-right text-red">
                                            Modyfikował:
                                        </div>
                                        <div className="col-3">
                                            {modifier.usNazw + " " + modifier.usImie}
                                            <br />
                                            {moment(address.kaDataMod).format("DD/MM/yy HH:mm")}
                                        </div>
                                    </>
                                    :
                                    <div className="col-6 h-5rem"></div>
                            }

                            {changes ?
                                <div className="grid col-12 mt-3 justify-content-center">
                                    <Button
                                        className="odf-shine"
                                        label="Zapisz"
                                        icon="pi pi-fw pi-save"
                                        onClick={() => saveChanges()}
                                    />
                                </div>
                                :
                                null
                            }
                        </>
                }
            </div>
        </>
    )
}