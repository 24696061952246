/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/warehouse";


export async function updateCounter(office) {
    try {
        const promise = await httpService.post(apiEndpoint + "/updateCounter", { params: { office } });
        const { data: response } = promise;
        return response;
    } catch (error) {
        return null;
    }
}

export async function createSaleDoc(number, office) {
    try {
        const promise = await httpService.post(apiEndpoint + "/createSaleDoc", { params: { number, office } });
        const { data: response } = promise;
        return response;
    } catch (error) {
        return null;
    }
}

export async function addToDoc(prints, magID) {
    try {
        const promise = await httpService.post(apiEndpoint + "/addToDoc", { params: { prints, magID } });
        const { data: response } = promise;
        return response;
    } catch (error) {
        return null;
    }
}

export default {
    updateCounter,
    createSaleDoc,
    addToDoc
};
