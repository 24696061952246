/* eslint-disable import/no-anonymous-default-export */
import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/vehicle";

export async function getAll() {
  try {
    const promise = await httpService.get(apiEndpoint + "/getAll");
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getPackage(params) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getPackage", {
      params,
    });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function getDetails(id) {
  try {
    const promise = await httpService.get(apiEndpoint + "/getDetails/" + id);
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function edit(details) {
  try {
    const promise = await httpService.post(apiEndpoint + "/edit", { params: { details } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function create(details) {
  try {
    const promise = await httpService.post(apiEndpoint + "/create", { params: { details } });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}

export async function changeRegistration(details) {
  try {
    const promise = await httpService.post(apiEndpoint + "/changeRegistration", { params: details });
    const { data: response } = promise;
    return response;
  } catch (error) {
    return null;
  }
}


export default {
  getAll,
  getPackage,
  getDetails,
  edit,
  create,
  changeRegistration
};
