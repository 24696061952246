export function Footer() {
    return(
        <div className="footer">
        <span style={{ marginRight: "5px" }}>&copy; 2022</span>
        <i className="pi pi-copyright" />
        <span style={{ marginLeft: "5px" }}>
          CeNTe, wersja 0.4.6 2024-02-08
        </span>
      </div>
    )
}